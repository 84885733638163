class Transfert {
    constructor(date, heure, depart, arrivee,vehicule, chauffeur, guide, pax, prix_unitaire, montant) {

        this.date = date;
        this.heure = heure;
        this.lieuDepart = depart;
        this.lieuArrivee = arrivee;
        this.vehicule = vehicule;
        this.chauffeur = chauffeur;  
        this.guide = guide;
        this.pax = pax;
        this.prixUnitaire = prix_unitaire;
        this.montant = montant;
    }
}


export default Transfert;