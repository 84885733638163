





import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortByProp } from "../../settings/themeSettings";
import { COMPAGNIE_VOL_URL } from "../../settings/APISetting";



export const fetchCompagnieVol = createAsyncThunk('fetch-programme', async (page = 1) => {


    const response = await fetch(`${COMPAGNIE_VOL_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});


export const createCompagnieVol = (compagnie, err, cb) => {
    fetch(COMPAGNIE_VOL_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(compagnie)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}





export const updateCompagnieVol = (compagnie, err, cb) => {
    fetch(`${COMPAGNIE_VOL_URL}/${compagnie.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(compagnie)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteCompagnieVol = (id, err, cb) => {
    fetch(`${COMPAGNIE_VOL_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}
const compagnieVolSlice = createSlice({

    name: 'compagnieVols',
    initialState: {compagnieVols: [], compagnieVolFetchStatus: '' , compagnieVolsName : []},

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCompagnieVol.fulfilled, (state, action) => {

            state.compagnieVols =[...action.payload].sort((a,b) => sortByProp(a,b, 'nom'));
            state.compagnieVolsName = [...[...action.payload].sort((a,b) => sortByProp(a,b, 'nom'))].map((i) => i.nom);
            state.compagnieVolFetchStatus = 'success';
        }).addCase(fetchCompagnieVol.pending, (state, action) => {
            state.compagnieVolFetchStatus = 'loading';

        }).addCase(fetchCompagnieVol.rejected, (state, action) => {

            state.compagnieVolFetchStatus = 'error';

        });
    }


});
export default compagnieVolSlice;