class User {
    constructor(nomComplet, code, username, profil) {

        this.nomComplet = nomComplet;
        this.code = code;
        this.username = username;
        this.profil = profil;


    }
}
export class UserCreation {
    constructor(nomComplet, code, username, password) {

        this.nomComplet = nomComplet;
        this.code = code;
        this.username = username;
        this.password = password;
        this.profil = "ADMIN"

    }
}




export default User;