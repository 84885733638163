import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BClientFacturesUrl, BFactureUrl, CANCEL_TICKETS_URL, FACTURATION_URL, FACTURE_EDIT_URL, MAKE_DEVIS_URL, UPDATE_TICKETS_URL } from "../../settings/APISetting";

export const fetchFactures = createAsyncThunk('fetch-factures', async (clientId) => {


    const response = await fetch(`${BClientFacturesUrl + clientId}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});


export const updateFacture = createAsyncThunk('update-facture', async (facture) => {


    const response = await fetch(FACTURE_EDIT_URL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(facture)
    });


    return response.json()
});



export const calculateTotalFrais = (frais) => {
    if (!frais || (frais.length < 0)) return 0;

    const totalFrais = frais.reduce((total, frai) => {

        return { montant: total.montant + frai.montant };
    }, { montant: 0 });
    console.log({ totalFrais });
    return totalFrais.montant;
}

export const createFacture = (facture, err, cb) => {
    fetch(FACTURATION_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(facture)
    }).then((resp) => {
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}

export const createDevis = (devis, err, cb) => {
    fetch(MAKE_DEVIS_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(devis)
    }).then((resp) => {
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}


export const cancelTickets = (facture, err, cb) => {
    fetch(CANCEL_TICKETS_URL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(facture)
    }).then((resp) => {
        console.log(resp);
        if (resp.status > 201) {
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json), err)
        .catch(err);
}

export const fetchFacture = async (id) => {
    return await fetch(`${BFactureUrl + id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


}


export const updateTickets = (facture, err, cb) => {
    fetch(UPDATE_TICKETS_URL, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(facture)
    }).then((resp) => resp.json())
        .then(async (json) => cb(json), err)
        .catch(err);
}



const factureSlice = createSlice({

    name: 'factures',
    initialState: { factures: [], fetchStatus: '', selectedFacture: {} , updateStatus: ''},
    reducers: {


            initialize: (state, action) => {
                state.updateStatus = '';
                state.fetchStatus = '';
            }



    },
    extraReducers: (builder) => {
        builder.addCase(fetchFactures.fulfilled, (state, action) => {

            let placeholder = JSON.parse(action.payload);

            placeholder = placeholder.map((facture) => {
                if (!(facture.frais.length > 0) && facture.factureReferente) {
                    facture.type_facture = "Annulation de billet";
                } else if (facture.factureReferente && (facture.frais.length > 0)) {
                    facture.type_facture = "UPDATE";

                } else if (!facture.factureReferente) {
                    facture.type_facture = "Vente de billet";

                }
                facture.date = new Date(facture.createdAt).toLocaleDateString("fr-FR");
                return facture;
            });



            console.log(placeholder);

            state.factures = placeholder.reverse();
            state.fetchStatus = 'success';
        }).addCase(fetchFactures.pending, (state, action) => {
            // state.Factures[]
            state.fetchStatus = 'loading';
            state.updateStatus = 'loading';

        }).addCase(fetchFactures.rejected, (state, action) => {
            // state.Factures[]
            console.log(action);

            state.fetchStatus = 'error';

        }).addCase(updateFacture.pending, (state, action) => {
            state.updateStatus = 'loading';
           
        }).addCase(updateFacture.rejected, (state, action) => {
            state.updateStatus = 'error';
           
        })
        .addCase(updateFacture.fulfilled, (state, action) => {
            state.updateStatus = 'success';

            let facture = action.payload;
            state.factures = state.factures.map((fac) => {
                if(facture.id == fac.id){
                    fac = facture;
                }
                return fac;
            });
            console.log({update : facture});


        })
        
        ;


    }


});


export default factureSlice;