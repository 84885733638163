


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PROGRAMMES_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchProgrammes = createAsyncThunk('fetch-programme', async (page = 1) => {


    const response = await fetch(`${PROGRAMMES_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});


export const createProgram = (program, err, cb) => {
    fetch(PROGRAMMES_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(program)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}





export const updateProgram = (program, err, cb) => {
    fetch(`${PROGRAMMES_URL}/${program.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(program)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteProgram = (id, err, cb) => {
    fetch(`${PROGRAMMES_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}
const programmeSlice = createSlice({

    name: 'programmes',
    initialState: {programmes: [], programmesFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProgrammes.fulfilled, (state, action) => {

            state.programmes =[...action.payload].sort((a,b) => sortByProp(a,b, 'libelle'));
            state.programmesFetchStatus = 'success';
        }).addCase(fetchProgrammes.pending, (state, action) => {
            state.programmesFetchStatus = 'loading';

        }).addCase(fetchProgrammes.rejected, (state, action) => {

            state.programmesFetchStatus = 'error';

        });
    }


});
export default programmeSlice;