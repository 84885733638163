
import './dashboard.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillets } from '../../data/slices/billetsSlice';
import _ from 'lodash';
import { Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { COLORS, priceFormatterV2 } from '../../settings/themeSettings';
import DashboardTable from '../../components/DashboardTable/DashboardTable';
import { DASHBOARD_URL } from '../../settings/APISetting';
import { PDFViewer, Page, Document } from '@react-pdf/renderer';
import DashboardTablePDF from '../../components/DashboardTable/DashboardTablePDF';



const Dashboard = (props) => {
    const { billets } = useSelector((state) => state.billets);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [syntheseData, setSyntheseData] = useState([]);
    const [margeByCompanyData, setMargeByCompanyData] = useState([]);
    const [caByCompanyData, setCaByCompanyData] = useState([]);
    const [deboursByCompanyData, setDeboursByCompanyData] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [tabIdx, setTabIdx] = useState(0);
    const [tab, setTab] = useState(0);
    const [subTab, setSubTab] = useState(0);
    const [currentYear, setCurrentYear] = useState((new Date()).toISOString().split('-')[0]);
    const [cloture, setCloture] = useState(0);

    const arrayCleaner = (arr) => {
        if (!Array.isArray(arr)) return arr;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] == null) {
                arr[i] = 0;
            } else {
                arr[i] = Number(arr[i]);
            }

        }

        return arr;
    }

    const sumArrays = (a, b) => {

        let c = [];
        if (Array.isArray(a) && Array.isArray(b) && (a.length == b.length)) {

            for (let i = 0; i < a.length; i++) {
                c[i] = Number(a[i]) + Number(b[i]);
            }
        }



        return c.map(priceFormatterV2);


    }


    const getDashBoard = async ({ startDate, endDate, cloture } = {}) => {

        let response = {};
        if (!!startDate && !!endDate && cloture) {
            response = await fetch(`${DASHBOARD_URL}?startDate=${startDate}&endDate=${endDate}&cloture=${cloture}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        } else if (!!startDate && !!endDate) {
            response = await fetch(`${DASHBOARD_URL}?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } else if (cloture) {
            response = await fetch(`${DASHBOARD_URL}?cloture=${cloture}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        } else {
            response = await fetch(`${DASHBOARD_URL}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        }




        return response.json();
    }

    const billeterieSwitch = (index) => {
        switch (index) {
            case 0: return billeterieByMonth();
            case 1: return billeterieBySemester();
            case 2: return billeterieByTrimester();
        }
    }

    const titleSwitch = (index) => {
        if (tab == 1) {
            switch (index) {
                case 0: return ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août",
                    "Septembre", "Octobre", "Novembre", "Décembre"];
                case 1: return ["Janvier - Juin", "Juillet - Décembre"];
                case 2: return ["Janvier - Mars", "Avril - Juin", "Juillet - Septembre", "Octobre - Décembre"];
            }
        } else {
            return null;
        }

    }


    const tourismeSwitch = (index, paiement, frais) => {

        console.log({ paiement });
        console.log({ frais });


        switch (index) {
            case 0: return {
                ca: arrayCleaner(paiement),
                debours: arrayCleaner(frais),
                get marge() {
                    return this.ca?.map((item, idx) => {
                        return item - this.debours[idx];
                    })
                }
            };
            case 1: return {
                ca: [_.sum(arrayCleaner(paiement?.slice(0, 5))), _.sum(arrayCleaner(paiement?.slice(5, 12)))],
                debours: [_.sum(arrayCleaner(frais?.slice(0, 5))), _.sum(arrayCleaner(frais?.slice(5, 12)))],
                get marge() {
                    return this.ca.map((item, idx) => {
                        return item - this.debours[idx];
                    })
                }
            };

            case 2: return {
                ca: [_.sum(arrayCleaner(paiement?.slice(0, 3))), _.sum(arrayCleaner(paiement?.slice(3, 6))), _.sum(arrayCleaner(paiement?.slice(6, 9))), _.sum(arrayCleaner(paiement?.slice(9, 12)))],
                debours: [_.sum(arrayCleaner(frais?.slice(0, 3))), _.sum(arrayCleaner(frais?.slice(3, 6))), _.sum(arrayCleaner(frais?.slice(6, 9))), _.sum(arrayCleaner(frais?.slice(9, 12)))],
                get marge() {
                    return this.ca.map((item, idx) => {
                        return item - this.debours[idx];
                    });
                }

            };
        }
    }
    const billeterieBySemester = () => {
        const firstSemester = new Date(`${currentYear}-06-30`);
        return {

            "ca": [_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) <= firstSemester) {
                    return b.tarif;
                }
                return 0;
            }), _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) > firstSemester) {
                    return b.tarif;
                }
                return 0;
            })

            ],
            "debours": [_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) <= firstSemester) {
                    return b.montantAchat;
                }
                return 0;
            }
            ), _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) > firstSemester) {
                    return b.montantAchat;
                }
                return 0;
            })],
            "marge": [_.sumBy(
                
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) <= firstSemester) {
                    return b.marge;
                }
                return 0;
            }), _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) > firstSemester) {
                    return b.marge;
                }
                return 0;
            })]

        };
    }

    const billeterieByTrimester = () => {
        const ca = [0, 0, 0, 0], debours = [0, 0, 0, 0], marge = [0, 0, 0, 0];
        const months = [
            [`${currentYear}-01-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-12-31`]

        ];

        months.map((month, idx) => {
            ca[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.tarif;
                }
                return 0;
            });
            debours[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.montantAchat;
                }
                return 0;
            });

            marge[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.marge;
                }
                return 0;
            });

        })


        return {

            ca,
            debours,
            marge

        };
    }

    const billeterieByMonth = () => {
        const ca = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const debours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const marge = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const months = [
            [`${currentYear}-01-01`, `${currentYear}-01-31`],
            [`${currentYear}-02-01`, `${currentYear}-02-28`],
            [`${currentYear}-03-01`, `${currentYear}-03-31`],
            [`${currentYear}-04-01`, `${currentYear}-04-30`],
            [`${currentYear}-05-01`, `${currentYear}-05-31`],
            [`${currentYear}-06-01`, `${currentYear}-06-30`],
            [`${currentYear}-07-01`, `${currentYear}-07-31`],
            [`${currentYear}-08-01`, `${currentYear}-08-31`],
            [`${currentYear}-09-01`, `${currentYear}-09-30`],
            [`${currentYear}-10-01`, `${currentYear}-10-31`],
            [`${currentYear}-11-01`, `${currentYear}-11-30`],
            [`${currentYear}-12-01`, `${currentYear}-12-31`]

        ];

        months.map((month, idx) => {
            ca[idx] = _.sumBy(
                   _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  
                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.tarif;
                }
                return 0;
            });

            debours[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.montantAchat;
                }
                return 0;
            });


            marge[idx] = _.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , (b) => {
                if ((new Date(b.date)) >= (new Date(month[0])) && (new Date(b.date)) <= (new Date(month[1]))) {
                    return b.marge;
                }
                return 0;
            });

        })

        return {

            ca,
            debours,
            marge

        };
    }





    // useEffect(() => {
    //     //console.log(billets);
    //     //  dispatch(fetchBillets());


    //     getDashBoard().then((data) => {
    //         console.info(data);
    //         setDashboardData(data);
    //     })
    //         .catch((err) => console.log(err));

    // }, []);

    useEffect(() => {

        setSyntheseData([{
            title: "Chiffre d'affaires",
            value: tab == 0 ? priceFormatterV2(_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , 'tarif')) : billeterieSwitch(subTab).ca?.map(priceFormatterV2)
        },
        {
            title: "Débours billets d'avions",
            value: tab == 0 ? priceFormatterV2(_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                , 'montantAchat')) : billeterieSwitch(subTab).debours?.map(priceFormatterV2)
        }, {
            title: "Marge",
            value: tab == 0 ? priceFormatterV2(_.sumBy(
                _.filter(billets, (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

                
                , (b) => b.marge > 0 ? b.marge : 0)) : billeterieSwitch(subTab).marge?.map(priceFormatterV2)
        }]);


        const totalMarge = _.sumBy(
           _.filter(
                billets
            , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

            , (b) => b.marge > 0 ? b.marge : 0);
        const totalCA = _.sumBy(
            _.filter(
                billets
               , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

            , 'tarif');
        const totalDebours = _.sumBy(
            _.filter(
                billets
                
              , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

            , 'montantAchat');

        let _margeByCompanyData = [];
        let _deboursByCompanyData = [];
        let _caByCompanyData = [];

        //marge par compagnie 
        let groupedByCompany = _.groupBy(
            
            _.filter(
                billets
                
              , (b) => (cloture == 1) ? (b.etat == 'FLOWN') : true)  

            , 'compagnie');
        Object.keys(groupedByCompany).map((key) => {

            _margeByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], (b) => b.marge > 0 ? b.marge : 0)) + " / " + Math.round((_.sumBy(groupedByCompany[key], (b) => b.marge > 0 ? b.marge : 0) / totalMarge) * 100) + ' %').split("/")
            });

            _deboursByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], 'montantAchat')) + " / " + ( Math.round((_.sumBy(groupedByCompany[key], 'montantAchat') / totalDebours) * 100)) + ' %').split("/")
            });
            _caByCompanyData.push({
                title: key,
                value: (priceFormatterV2(_.sumBy(groupedByCompany[key], 'tarif')) + " / " + (Math.round((_.sumBy(groupedByCompany[key], 'tarif') / totalCA)) * 100) + ' %').split("/")
            });
        });


        setMargeByCompanyData(_margeByCompanyData);
        setDeboursByCompanyData(_deboursByCompanyData);
        setCaByCompanyData(_caByCompanyData);



    }, [billets, tab, subTab, cloture]);

    useEffect(() => {

        if (!!startDate && !!endDate && (cloture != null)) {
            dispatch(fetchBillets({ startDate, endDate }));

            getDashBoard({ startDate, endDate, cloture }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        } else if (!!startDate && !!endDate) {
            dispatch(fetchBillets({ startDate, endDate }));

            getDashBoard({ startDate, endDate }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        } else {
            dispatch(fetchBillets());

            getDashBoard({ cloture }).then((data) => {
                console.info(data);
                setDashboardData(data);
            })
                .catch((err) => console.log(err));
        }

        console.log({ cloture })


    }, [startDate, endDate, cloture]);

    useEffect(() => {
        setCurrentYear((new Date()).toISOString().split('-')[0]);
        if (tab == 1) {
            dispatch(fetchBillets({ startDate: `${currentYear}-01-01`, endDate: `${currentYear}-12-31` }));

        } else {
            dispatch(fetchBillets());

        }
        getDashBoard({ cloture }).then((data) => {
            console.info(data);
            setDashboardData(data);
        })
            .catch((err) => console.log(err));

        console.log(tab);
    }, [tab]);



    return <div className="dashboard">
        <div className="header">
            <div className="title">
                Dashboard
            </div>

        </div>

        <div className="filter section">
            <span className={cloture == 0 ? "title active" : "title"} onClick={() => setCloture(0)}>
                Montant prévisionnel
            </span>
            <span className={cloture == 1 ? "title active" : "title"} onClick={() =>{ 
                setCloture(1);
         
                }}>
                Montant réel
            </span>


        </div>

        <div className="filter section">
            <span className={tabIdx == 0 ? "title active" : "title"} onClick={() => setTabIdx(0)}>
                Synthèse des activités
            </span>
            <span className={tabIdx == 1 ? "title active" : "title"} onClick={() => setTabIdx(1)}>
                Billetteries
            </span>
            <span className={tabIdx == 2 ? "title active" : "title"} onClick={() => setTabIdx(2)}  >
                Tourisme
            </span>

            <span className={tabIdx == 3 ? "title active" : "title"} onClick={() => setTabIdx(3)} >
                Chiffre d'affaire et marge
            </span>

            <span className={tabIdx == 4 ? "title active" : "title"} onClick={() => setTabIdx(4)} >
                TO/Client
            </span>

        </div>


        <div className="tabs">
            <div className={tab == 0 ? "tab active" : "tab"} onClick={() => setTab(0)}>
                Période
            </div>
            <div className={tab == 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>
                Echelonnement
            </div>
        </div>
        {tab == 0 && <div className="filter">
            <span className="title">
                Choisir Période
            </span>
            <input type="date" value={startDate} onChange={(e) => {
                setStartDate(e.target.value);
            }} />
            <input type="date" value={endDate} onChange={(e) => {
                console.log(endDate);
                setEndDate(e.target.value);
            }} />

            <div className="btn" onClick={() => {
                setEndDate(null);
                setStartDate(null);
                dispatch(fetchBillets());

            }}>
                <Refresh style={{ fill: COLORS.secondary }} />
                <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
            </div>

        </div>
        }
        {
            tab == 1 && <div className="filter section">
                <span className={subTab == 0 ? "title active" : "title"} onClick={() => setSubTab(0)} >
                    Mois
                </span>
                <span className={subTab == 2 ? "title active" : "title"} onClick={() => setSubTab(2)} >
                    Trimestre
                </span>
                <span className={subTab == 1 ? "title active" : "title"} onClick={() => setSubTab(1)} >
                    Semestre
                </span>

            </div>
        }

        <div className="dashboard-content">
            {tabIdx == 0 && <div className="chart-item" >
                <div className="chart-item-title">
                    Analyse globale par activité
                </div>

                <div className="table-container">


                    <div>
                        <DashboardTable caption="Billetterie"
                            data={syntheseData}
                            title={titleSwitch(subTab)}

                        />

                        <DashboardTable
                            title={titleSwitch(subTab)}

                            caption="Tourisme" data={[
                                {
                                    title: "Chiffre d'affaire",

                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV2),
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).debours?.map(priceFormatterV2),
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, (dashboardData?.totalPaiementTourismeByMonth), (dashboardData?.totalFraisTourismeByMonth)).marge?.map(priceFormatterV2)

                                }


                            ]} />

                    </div>





                </div>


            </div>}
            {tabIdx == 1 && <div className="chart-item">
                <div className="chart-item-title">
                    Billetteries
                </div>
                {/* <div className="chart-item-content">
                    <RadarChart identifier="radarchartdiv" data={groupedByCompany} />
                </div> */}
                <div className="table-container">
                    <div>
                        <DashboardTable title={titleSwitch(subTab)}
                            caption="Billetterie" data={syntheseData} />

                        <DashboardTable caption="CA par Compagnie" data={caByCompanyData} />
                        <DashboardTable caption="Debours par Compagnie" data={deboursByCompanyData} />
                        <DashboardTable caption="Marge par Compagnie" data={margeByCompanyData} />
                    </div>

                </div>
            </div>}
            {tabIdx == 2 && <div className="chart-item">
                <div className="chart-item-title">
                    Analyse tourisme par prestation
                </div>

                <div className="table-container">
                    <div>




                        <DashboardTable
                            title={titleSwitch(subTab)}

                            caption="Tourisme" data={[
                                {
                                    title: "Chiffre d'affaire",

                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV2),
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? priceFormatterV2(dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours?.map(priceFormatterV2),
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge?.map(priceFormatterV2)

                                }


                            ]} />
                        <DashboardTable caption="Circuit"
                            title={titleSwitch(subTab)}


                            data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).ca?.map(priceFormatterV2)
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).debours?.map(priceFormatterV2)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).marge?.map(priceFormatterV2)
                                }


                            ]} />

                        <DashboardTable caption="Sejour" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).ca?.map(priceFormatterV2)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).debours?.map(priceFormatterV2)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).marge?.map(priceFormatterV2)
                            }


                        ]} />


                        <DashboardTable

                            title={titleSwitch(subTab)}

                            caption="Excursion" data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).ca?.map(priceFormatterV2)
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).debours?.map(priceFormatterV2)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).marge?.map(priceFormatterV2)
                                }


                            ]} />



                        <DashboardTable
                            title={titleSwitch(subTab)}

                            caption="Transfert" data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                        dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).ca?.map(priceFormatterV2)
                                },
                                {
                                    title: "Total Débours",
                                    value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                        dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                    ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).debours?.map(priceFormatterV2)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).marge?.map(priceFormatterV2)
                                }


                            ]} />
                    </div>




                </div>


            </div>}
            {tabIdx == 3 && <div className="chart-item">
                <div className="chart-item-title">
                    Analyse Chiffre d'affaires et marges par activité

                </div>

                <div className="table-container">

                    <div>
                        <DashboardTable caption="Pourcentages CA" data={[
                            {
                                title: "Chiffre d'affaire Billetterie",
                                value: (priceFormatterV2(_.sumBy(billets, 'tarif') || 0) + " / " + Math.round((Number(_.sumBy(billets, 'tarif') || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")
                            },
                            {
                                title: "Chiffre d'affaire Tourisme",
                                value: (priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalPaiementTourisme || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")

                            },



                        ]} />


                        <DashboardTable caption="Pourcentages Débours" data={[
                            {
                                title: "Débours Billetterie",
                                value: (priceFormatterV2(_.sumBy(billets, 'montantAchat')) + " / " + Math.round((Number(_.sumBy(billets, 'montantAchat')) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")
                            },
                            {
                                title: "Débours Tourisme",
                                value: (priceFormatterV2(dashboardData?.totalFraisTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalFraisTourisme || 0) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")

                            }

                        ]} />

                        <DashboardTable caption="Pourcentages Marge" data={[
                            {

                                title: "Marge Billetterie",
                                value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                    (priceFormatterV2(_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                        + " / " +
                                        (Math.round(((_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%')).split("/")
                                    : '100%'
                            }
                            ,
                            {


                                title: "Marge Tourisme",
                                value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                    (((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) + " / " +
                                        Math.round((((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%').split("/")
                                    : '0%'



                            }


                        ]} />


                        <DashboardTable

                            title={titleSwitch(subTab)}

                            caption="Totaux" data={[
                                {
                                    title: "Chiffre d'affaire",
                                    value: tab == 0 ? priceFormatterV2(Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0)) :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca, billeterieSwitch(subTab).ca)
                                },
                                {
                                    title: "Débours",
                                    value: tab == 0 ?
                                        priceFormatterV2(Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat'))) :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours, billeterieSwitch(subTab).debours)
                                },
                                {
                                    title: "Marge",
                                    value: tab == 0 ? (() => {
                                        priceFormatterV2((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                            _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)
                                            : _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                    })() :
                                        sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge, billeterieSwitch(subTab).marge)


                                }


                            ]} />

                    </div>





                </div>



            </div>}

            {
                tabIdx == 4 && <div className="chart-item">
                    <div className="chart-item-title">
                        TO/Client
                    </div>
                    <div className="table-container">
                        <div>
                            {tab == 0 && <DashboardTable
                                title={['Débours', 'CA', 'Marge', '% Marge']}

                                caption="Tourisme par TO/Client" data={
                                    (!!dashboardData.fraisByClient) ?
                                        Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                            .map((title) => ({
                                                title,
                                                value: [...(() => {
                                                    let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                                    return [_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0),
                                                    _.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0),
                                                    (_.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0) || 0) - (_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0) || 0),
                                                    (Math.round(((_.sumBy(r, (i) => {

                                                        return i.paiement ? Number(i.paiement) : 0;

                                                    }) || 0) - (_.sumBy(r, (i) => {
                                                        return i.frais ? Number(i.frais) : 0;


                                                    }) || 0)) /

                                                        ((dashboardData.totalPaiementTourisme || 0) - (dashboardData.totalFraisTourisme || 0))
                                                    ) * 100) + '%'

                                                    ]
                                                })()


                                                ].map((item) => {
                                                    return isNaN(item) ? item : priceFormatterV2(item);
                                                })
                                            }))

                                        : []
                                } />}

                            {tab == 0 && ["circuit", "sejour", "transfert", "excursion"].map((dtype, key) => <DashboardTable key={key}
                                title={['CA', '% CA / CA TOTAL']}

                                caption={"Tourisme par TO/Client pour " + dtype} data={
                                    (!!dashboardData.fraisByClient) ?
                                        Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                            .map((title) => ({
                                                title,
                                                value: [...(() => {
                                                    let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                                    return [

                                                        _.sumBy(r, (i) => {

                                                            if (i.type == dtype) {
                                                                return i.paiement ? Number(i.paiement) : 0;
                                                            } return 0;
                                                        }),


                                                        (Math.round(((_.sumBy(r, (i) => {

                                                            if (i.type == dtype) {
                                                                return i.paiement ? Number(i.paiement) : 0;
                                                            } return 0;
                                                        }) || 0)) /

                                                            ((dashboardData.totalPaiementTourisme || 0))
                                                        ) * 100) + '%'
                                                    ].map((item) => {
                                                        return !(new RegExp("%")).test(item) ? priceFormatterV2(item) : item;
                                                    })
                                                })()


                                                ]
                                            }))

                                        : []
                                } />)}

                        </div>
                    </div>
                </div>
            }
        </div>


        <PDFViewer
            style={{
                width: "100%",
                height: "800px"
            }}
        >
            <Document>
                <Page size="A4" style={{
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    paddingVertical: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    paddingHorizontal: 10,
                    gap: 10
                }}>



                    <DashboardTablePDF
                        title={titleSwitch(subTab)}

                        caption="Tourisme" data={[
                            {
                                title: "Chiffre d'affaire",

                                value: tab == 0 ? priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca?.map(priceFormatterV2),
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? priceFormatterV2(dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours?.map(priceFormatterV2),
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge?.map(priceFormatterV2)

                            }


                        ]} />

                    <DashboardTablePDF caption="Billetterie"
                        data={syntheseData}
                        title={titleSwitch(subTab)}

                    />

                    <DashboardTablePDF caption="CA par Compagnie" data={caByCompanyData} />
                    <DashboardTablePDF caption="Debours par Compagnie" data={deboursByCompanyData} />
                    <DashboardTablePDF caption="Marge par Compagnie" data={margeByCompanyData} />




                    <DashboardTablePDF caption="Circuit"
                        title={titleSwitch(subTab)}


                        data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementCircuit / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).ca?.map(priceFormatterV2)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisCircuit || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisCircuit / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).debours?.map(priceFormatterV2)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementCircuit || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementCircuitByMonth, dashboardData?.totalFraisCircuitByMonth).marge?.map(priceFormatterV2)
                            }


                        ]} />

                    <DashboardTablePDF caption="Sejour" data={[
                        {
                            title: "Chiffre d'affaire",
                            value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementSejour || 0) + " / " + Math.round(
                                dashboardData?.totalPaiementSejour / dashboardData?.totalPaiementTourisme * 100
                            ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).ca?.map(priceFormatterV2)
                        },
                        {
                            title: "Total Débours",
                            value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisSejour || 0) + " / " + Math.round(
                                dashboardData?.totalFraisSejour / dashboardData?.totalFraisTourisme * 100
                            ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).debours?.map(priceFormatterV2)
                        },
                        {
                            title: "Marge",
                            value: tab == 0 ? (dashboardData?.totalPaiementSejour || 0) - (dashboardData?.totalFraisCircuit || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementSejourByMonth, dashboardData?.totalFraisSejourByMonth).marge?.map(priceFormatterV2)
                        }


                    ]} />


                    <DashboardTablePDF

                        title={titleSwitch(subTab)}

                        caption="Excursion" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementExcursion / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).ca?.map(priceFormatterV2)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisExcursion || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisExcursion / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).debours?.map(priceFormatterV2)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementExcursion || 0) - (dashboardData?.totalFraisExcursion || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementExcursionByMonth, dashboardData?.totalFraisExcursionByMonth).marge?.map(priceFormatterV2)
                            }


                        ]} />



                    <DashboardTablePDF
                        title={titleSwitch(subTab)}

                        caption="Transfert" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalPaiementTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalPaiementTransfert / dashboardData?.totalPaiementTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).ca?.map(priceFormatterV2)
                            },
                            {
                                title: "Total Débours",
                                value: tab == 0 ? (priceFormatterV2(dashboardData?.totalFraisTransfert || 0) + " / " + Math.round(
                                    dashboardData?.totalFraisTransfert / dashboardData?.totalFraisTourisme * 100
                                ) + "%").split("/") : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).debours?.map(priceFormatterV2)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (dashboardData?.totalPaiementTransfert || 0) - (dashboardData?.totalFraisTransfert || 0) : tourismeSwitch(subTab, dashboardData?.totalPaiementTransfertByMonth, dashboardData?.totalFraisTransfertByMonth).marge?.map(priceFormatterV2)
                            }


                        ]} />

                    <DashboardTablePDF caption="Pourcentages CA" data={[
                        {
                            title: "Chiffre d'affaire Billetterie",
                            value: (priceFormatterV2(_.sumBy(billets, 'tarif') || 0) + " / " + Math.round((Number(_.sumBy(billets, 'tarif') || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")
                        },
                        {
                            title: "Chiffre d'affaire Tourisme",
                            value: (priceFormatterV2(dashboardData?.totalPaiementTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalPaiementTourisme || 0) / (Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0))) * 100) + '%').split("/")

                        },



                    ]} />


                    <DashboardTablePDF caption="Pourcentages Débours" data={[
                        {
                            title: "Débours Billetterie",
                            value: (priceFormatterV2(_.sumBy(billets, 'montantAchat')) + " / " + Math.round((Number(_.sumBy(billets, 'montantAchat')) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")
                        },
                        {
                            title: "Débours Tourisme",
                            value: (priceFormatterV2(dashboardData?.totalFraisTourisme || 0) + " / " + Math.round((Number(dashboardData?.totalFraisTourisme || 0) / (Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat')))) * 100) + '%').split("/")

                        }

                    ]} />

                    <DashboardTablePDF caption="Pourcentages Marge" data={[
                        {

                            title: "Marge Billetterie",
                            value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (priceFormatterV2(_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                    + " / " +
                                    (Math.round(((_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%')).split("/")
                                : '100%'
                        }
                        ,
                        {


                            title: "Marge Tourisme",
                            value: (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                (((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) + " / " +
                                    Math.round((((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)) / (_.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0))) * 100) + '%').split("/")
                                : '0%'



                        }


                    ]} />


                    <DashboardTablePDF

                        title={titleSwitch(subTab)}

                        caption="Totaux" data={[
                            {
                                title: "Chiffre d'affaire",
                                value: tab == 0 ? priceFormatterV2(Number(dashboardData?.totalPaiementTourisme || 0) + Number(_.sumBy(billets, 'tarif') || 0)) :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).ca, billeterieSwitch(subTab).ca)
                            },
                            {
                                title: "Débours",
                                value: tab == 0 ?
                                    priceFormatterV2(Number(dashboardData?.totalFraisTourisme || 0) + Number(_.sumBy(billets, 'montantAchat'))) :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).debours, billeterieSwitch(subTab).debours)
                            },
                            {
                                title: "Marge",
                                value: tab == 0 ? (() => {
                                    priceFormatterV2((dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0) > 0 ?
                                        _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0) + (dashboardData?.totalPaiementTourisme || 0) - (dashboardData?.totalFraisTourisme || 0)
                                        : _.sumBy(billets, (b) => b.marge > 0 ? b.marge : 0))
                                })() :
                                    sumArrays(tourismeSwitch(subTab, dashboardData?.totalPaiementTourismeByMonth, dashboardData?.totalFraisTourismeByMonth).marge, billeterieSwitch(subTab).marge)


                            }


                        ]} />

                    <DashboardTablePDF
                        title={['Débours', 'CA', 'Marge', '% Marge']}

                        caption="Tourisme par TO/Client" data={
                            (!!dashboardData.fraisByClient) ?
                                Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                    .map((title) => ({
                                        title,
                                        value: [...(() => {
                                            let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                            return [_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0),
                                            _.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0),
                                            (_.sumBy(r, (i) => i.paiement ? Number(i.paiement) : 0) || 0) - (_.sumBy(r, (i) => i.frais ? Number(i.frais) : 0) || 0),
                                            (Math.round(((_.sumBy(r, (i) => {

                                                return i.paiement ? Number(i.paiement) : 0;

                                            }) || 0) - (_.sumBy(r, (i) => {
                                                return i.frais ? Number(i.frais) : 0;


                                            }) || 0)) /

                                                ((dashboardData.totalPaiementTourisme || 0) - (dashboardData.totalFraisTourisme || 0))
                                            ) * 100) + '%'

                                            ]
                                        })()


                                        ].map((item) => {
                                            return isNaN(item) ? item : priceFormatterV2(item);
                                        })
                                    }))

                                : []
                        } />

                    {["circuit", "sejour", "transfert", "excursion"].map((dtype, key) => <DashboardTablePDF key={key}
                        title={['CA', '% CA / CA TOTAL']}

                        caption={"Tourisme par TO/Client pour " + dtype} data={
                            (!!dashboardData.fraisByClient) ?
                                Object.keys(_.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle'))
                                    .map((title) => ({
                                        title,
                                        value: [...(() => {
                                            let r = _.groupBy(dashboardData.fraisByClient.concat(dashboardData.paiementByClient), 'libelle')[title];

                                            return [

                                                _.sumBy(r, (i) => {

                                                    if (i.type == dtype) {
                                                        return i.paiement ? Number(i.paiement) : 0;
                                                    } return 0;
                                                }),


                                                (Math.round(((_.sumBy(r, (i) => {

                                                    if (i.type == dtype) {
                                                        return i.paiement ? Number(i.paiement) : 0;
                                                    } return 0;
                                                }) || 0)) /

                                                    ((dashboardData.totalPaiementTourisme || 0))
                                                ) * 100) + '%'
                                            ].map((item) => {
                                                return !(new RegExp("%")).test(item) ? priceFormatterV2(item) : item;
                                            })
                                        })()


                                        ]
                                    }))

                                : []
                        } />)
                    }
                </Page>
            </Document>
        </PDFViewer>

    </div>

}


export default Dashboard;