


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AGENCE_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchAgences = createAsyncThunk('fetch-agences', async (page = 1) => {


    const response = await fetch(`${AGENCE_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});
export const createAgence = (agence, err, cb) => {
    fetch(AGENCE_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(agence)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}



export const updateAgence = (agence, err, cb) => {
    fetch(`${AGENCE_URL}/${agence.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(agence)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteAgence = (id, err, cb) => {
    fetch(`${AGENCE_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}


const agenceSlice = createSlice({

    name: 'agences',
    initialState: {agences: [], agencesFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAgences.fulfilled, (state, action) => {

            state.agences = [...action.payload].sort((a,b) => sortByProp(a,b, 'nom'));
            state.agencesFetchStatus = 'success';
        }).addCase(fetchAgences.pending, (state, action) => {
            state.agencesFetchStatus = 'loading';

        }).addCase(fetchAgences.rejected, (state, action) => {

            state.agencesFetchStatus = 'error';

        });
    }


});
export default agenceSlice;