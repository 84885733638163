import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, dateFormatter, priceFormatter, toEuro, toFrench } from '../../settings/themeSettings';
import signature from "../../assets/signature.png";
import FactureFooter from '../FactureFooter';
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingVertical: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 12,
        color: 'black',
        paddingLeft: '35px'
    },
    title: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleRight: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        // height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2
    },
    titleSpan: {
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: 2
    },
    newtitleStyle: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        alignSelf: 'flex-start',
        marginVertical: 2,
        paddingHorizontal: 5,
        paddingVertical: 2,
        backgroundColor: COLORS.gray,
        borderRadius: 2



    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginVertical: 2,
        backgroundColor: COLORS.gray,
        paddingHorizontal: 5,
        paddingVertical: 2
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        borderBottom: '0.5px solid black',
        marginVertical: 2
    },
    tableRowTotal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        border: '0.5px solid black',
        marginVertical: 5,
        gap: '10px',
        backgroundColor: COLORS.gray
    }

});





const DevisPDF = (props) => {
    const [total, setTotal] = useState(0);
    useEffect(() => {
        if (props.devis) {
            let montant = props.devis.billets?.reduce((sum, current) => {
                return { tarif: sum.tarif + (current.tarif || 0) }
            }, { tarif: 0 }).tarif;

            setTotal(montant);
        }


    }, []);


    return <Document>
   { props.devis &&     <Page size="A4" style={styles.page}>
            <View>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    minHeight: 80,
                    padding: 5
                }}>
                    <Image
                        style={{
                            height: 60,
                            width: 160
                        }}
                        source={logo} />
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        minHeight: 80,
                        paddingHorizontal: '25px',
                        marginTop: 20
                    }}

                >

                    <View

                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: .3,
                            alignSelf: 'flex-end'


                        }}


                    >
                        <View style={{
                            height: 30,
                            width: '100%',
                            backgroundColor: COLORS.gray,
                            marginBottom: 5,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        > <Text style={{
                            fontSize: 18,
                            color: 'black',
                            fontWeight: 'bold'
                        }}>Devis</Text></View>
                        <View style={styles.title}><Text style={{ ...styles.titleSpan }}> Numéro :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {props?.devis?.numeroDevis}  </Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> date :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{props.devis && dateFormatter(props.devis?.createdAt)} </Text></View>


                    </View>

                    <View
                        style={{
                            //width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            flex: .4
                        }}

                    >
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Code :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{props.devis && props.devis.client?.code} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Client :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{props.devis && props.devis.client?.structure ? props.devis.client?.nom : (props.devis.client?.prenom + ' ' + props.devis.client?.nom)} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Adresse :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {props.devis && props.devis.client?.adresse} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Ville :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {props.devis && props.devis.client?.ville} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Pays :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {props.devis && props.devis.client?.pays} </Text></View>
                        <Text style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: 'black',
                            alignSelf: 'flex-start',
                            marginVertical: 2

                        }}> Contact</Text>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Tél :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {props.devis && props.devis.client?.telephone} </Text></View>
                        <View style={styles.titleRight}><Text style={styles.titleSpan}> Email :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{props.devis && props.devis.client?.email} </Text></View>

                    </View>


                </View>


                {props.devis && props.devis.billets?.length > 0 && <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Billets</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '40%', fontSize: 10 }}>Désignation</Text>
                        <Text style={{ width: '40%', fontSize: 10 }}>Prix unitaire (CFA)</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Prix unitaire (€)</Text>


                    </View>


                    {
                        props.devis.billets?.map((e, idx) => <View key={idx} style={styles.tableRow}>

                            <Text style={{ width: '40%', fontSize: 10 }}> {`${e.nom} ${e.trajet}`} </Text>
                            <Text style={{ width: '40%', fontSize: 10 }}>{e.tarif}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{toEuro(e.tarif)
                            } </Text>

                        </View>)
                    }

                    <View style={styles.tableRowTotal}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '60%', fontSize: 10 }}>

                            {priceFormatter(total) + ' / ' + toEuro(total)}

                        </Text>

                    </View>

                </View>}




         
            </View>


{        //    <FactureFooter />
}



        </Page>}
    </Document>
}


export default DevisPDF;