import React, { useEffect, useReducer, useState } from "react";
import "./FactureForm.scss";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { AirplaneTicket, CreditCard, Delete, PaymentsTwoTone } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { COLORS, priceFormatter, theme, toEuro } from "../../settings/themeSettings";
import AddPaymentDialog from "../AddPaymentDialog";
import AddBillDialog from "../AddBillDialog";
import AddTicketDialog from "../AddTicketDialog";
import WestIcon from '@mui/icons-material/West';
import { useNavigate, useParams } from "react-router";
import { createDevis, createFacture } from "../../data/slices/factureSlice";
import { notify } from "../../settings/toastSetting";


const styles = {
    container: {
        // border: '1px solid blue',
        //  minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}










const DevisForm = (props) => {

    const [open, setOpen] = useState(false);

    const [billets, setBillets] = useState([]);

    const navigateTo = useNavigate();
    const {clientId} = useParams();
    const [totalFrais, setTotalFrais] = useState(0);
    const [totalPaiements, setTotalPaiements] = useState(0);

    const [loading, setLoading] = useState(false);





    const handleClose = (ticketInfos) => {
        setOpen(false);
        console.log(ticketInfos);
        if (ticketInfos) {
            setBillets([...billets, ticketInfos]);
        }
    }



    const removeTicket = (id) => {
        let tickets = billets.map((billet, idx) => {
            if (idx != id) {
                return billet
            } 
        }).filter((ticket) => !!ticket);
        setBillets(tickets);
    }



    const openDialog = () => {
        setOpen(true);
    }

  

    const addFacture = () => {
        setLoading(true);
        const facture = {
            billets : billets,
            client: clientId
        };

        console.log(facture);
        createDevis(facture, (err) => {
           console.log(err);
           notify('Une erreur s\'est produite, actualisez puis réessayez!', 'error', 'BOTTOM_LEFT');
            setLoading(false);
        }, (facture) => {
            setLoading(false);

           if(facture.error){

            notify(facture.error, 'error', 'TOP_RIGHT');

            return;


           }
           if(!facture.id){
            notify("Une erreur s'est produite!", 'error', 'TOP_RIGHT');
            return;
           }



            navigateTo(`/billeterie/devis/${clientId}/details/${facture.id}`);

        });
    }


   



    return <Box
        sx={
            styles.container
        }>



        <Stack
            direction="row"
            justifyContent="space-between"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '1100px' }}>Nouveau devis</Typography>

        </Stack>
      

    <Stack
            direction="row"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }
            sx={{ minHeight: '250px' }}


        >


            <Box xs={{ ...styles.path }} width={'100%'}
            >


                <div className="spacer"></div>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Liste des billets
                </Typography>


                <TableContainer component={Paper} sx={{ mt: '15px' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nom sur le billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Trajet </TableCell>

                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Aller</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Retour</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant TTC </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant FA </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                billets.map((billet, idx) => {
                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                        <TableCell > {billet.numero} </TableCell>
                                        <TableCell > {billet.nom} </TableCell>
                                        <TableCell > {billet.trajet} </TableCell>

                                        <TableCell > {billet.numeroVol} </TableCell>
                                        <TableCell> {billet.dateVol  } </TableCell>
                                        <TableCell > {billet.compagnie} </TableCell>

                                        <TableCell > {billet.numeroVolRetour || '----------------'} </TableCell>
                                        <TableCell> {billet.dateVolRetour  || '----------------' } </TableCell>
                                        <TableCell > {billet.compagnieRetour  || '----------------'} </TableCell>
                                        <TableCell > {priceFormatter(billet.tarif)} </TableCell>
                                        <TableCell > {priceFormatter(billet.montantAchat)} </TableCell>



                                        <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removeTicket(idx)} /> </TableCell>

                                    </TableRow>

                                })

                            }

                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: '10px', width: '100%' }}
                    startIcon={<AirplaneTicket sx={{ fill: COLORS.secondary }} />}
                    onClick={openDialog}
                >
                    Ajouter un billet
                </Button>

            </Box>

        </Stack>
        
        <Box
            sx={{
                width: '100%',
                minHeight: '300px'
            }}
        >






            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

            >


                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    onClick={() => navigateTo(`/billeterie/factures/${clientId}`)}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addFacture}
                    disabled={loading}
                >
                 {loading? 'Patientez..' :  'Valider'}
                </Button>

            </Stack>


        </Box>


        <AddTicketDialog open={open} onClose={handleClose} />
     
    </Box>
}



export default DevisForm;