

import './DashboardTable.scss';


const DashboardTable = ({ caption, data, title }) => {



    return <table class='dashboard-table'>
        <caption> {caption} </caption>
        {title && <thead>
            <th></th>
            {title.map((tl) => <th>{tl}</th>)}
        </thead>}
        <tbody>
            {
                data.map((item, idx) => <tr key={idx}>
                    <td>{item.title}</td>
                    {Array.isArray(item.value) ? item.value.map((v, key) => <td key={key} >{v}</td>) : <td>{item.value}</td>}
                </tr>

                )
            }
        </tbody>

    </table>;
}



export default DashboardTable;