class Vehicule {
    constructor(modele, marque, matricule) {

        this.modele = modele;
        this.marque = marque;
        this.matricule = matricule;

    }
}


export default Vehicule;