import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Autocomplete, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { COLORS, dateFormatter, priceFormatter, toEuro } from "../../settings/themeSettings";
import '../../index.scss';



import { Stack } from '@mui/material';

import WestIcon from '@mui/icons-material/West';
import { CreditCard, Delete, DocumentScanner, PaymentsTwoTone, Print, Update } from '@mui/icons-material';
import { DateField, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createFactureDossier, fetchFactureDossier } from "../../data/slices/facturesDossierSlice";
import { PDFViewer } from "@react-pdf/renderer";
import FactureDossierPDF from "../../components/FactureDossierPDF/FactureDossierPDF";



const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}






const DetailsFactureDossier = (props) => {
    const { id, type, dossier } = useParams();

    const [loading, setLoading] = useState(false);
    const [facture, setFacture] = useState(null);

    const navigateTo = useNavigate();

    const [tab, setTab] = useState(0);




    useEffect(() => {

        setLoading(true);

        fetchFactureDossier(id, (err) => {
            console.log(err);
            setLoading(false);

        }, (resp) => {
            console.log(resp);
            setFacture(resp);
            setLoading(false);

        });





    }, []);

    return <>

        {loading ?
            <div className="loader-container">
                <span className="loader"></span>
            </div>

            :



            <div className='details-circuit-container' >

                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                >


                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        gap="5"
                        alignItems="flex-start"

                    >
                        <div onClick={() => navigateTo(`/dossiers/${type}/${dossier}/factures`)}
                            style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                            <WestIcon style={{ fill: 'white', fontSize: 28 }} />
                        </div>
                        <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '100%', ml: 2 }}>Facture </Typography>

                    </Stack>


                    <Stack

                        direction="row"
                        justifyContent="space-between"
                        gap="2"

                        divider={
                            <Box
                                component="hr"
                                sx={{
                                    border: `1px solid ${COLORS.tertiary}`
                                }}
                            />
                        }


                    >




                        <Stack sx={{ width: '30%', mb: 5 }}>
                            <Typography
                                variant="p"
                                sx={styles.pathtitle}
                            >
                                Détails facture
                            </Typography>
                            {


                                !!facture && <Stack sx={{ padding: '0 10px' }}>

                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{facture.numero}  </span>
                                    </Typography>



                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> Date :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{dateFormatter(facture.date)}  </span>
                                    </Typography>

                                    {(type != 'sejour') && <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> Etat :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{facture.etat} </span>

                                    </Typography>}

                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> Total Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{priceFormatter(facture?.frais.reduce((sum, current) => ({ montant: sum.montant + (current.prixUnitaire * current.quantite) }), { montant: 0 }).montant) + ' / ' + toEuro(facture?.frais.reduce((sum, current) => ({ montant: sum.montant + (current.prixUnitaire * current.quantite) }), { montant: 0 }).montant)}  </span>
                                    </Typography>
                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> Total Réglement :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{priceFormatter(facture?.paiements.reduce((sum, current) => ({ montant: sum.montant + current.montant }), { montant: 0 }).montant) + " / " + toEuro(facture?.paiements.reduce((sum, current) => ({ montant: sum.montant + current.montant }), { montant: 0 }).montant)}  </span>
                                    </Typography>

                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                        <span> SOLDE :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{priceFormatter(facture?.frais.reduce((sum, current) => ({ montant: sum.montant + (current.prixUnitaire * current.quantite) }), { montant: 0 }).montant - facture?.paiements.reduce((sum, current) => ({ montant: sum.montant + current.montant }), { montant: 0 }).montant)}  </span>
                                    </Typography>
                                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Commentaire: </span>
                                        <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                            {facture?.commentaire}
                                        </span>
                                    </Typography>
                                </Stack>}





                        </Stack>




                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{}}

                        >


                            <Box sx={
                                {
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }
                            }>


                                <TableContainer component={Paper} >
                                    <Table sx={{ minWidth: 450 }} aria-label="simple table" >
                                        <TableHead >
                                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>


                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Designation</TableCell>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Quantité</TableCell>

                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Prix unitaire en CFA</TableCell>


                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Prix unitaire   en EURO</TableCell>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>


                                            {
                                                !!facture && facture.frais.map((bill, idx) => {
                                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                                        <TableCell > {bill.designation} </TableCell>
                                                        <TableCell > {bill.quantite} </TableCell>
                                                        <TableCell > {priceFormatter(bill.prixUnitaire)} </TableCell>

                                                        <TableCell > {bill.prixUnitaire ? (toEuro(bill.prixUnitaire)) : ''} </TableCell>

                                                        <TableCell > {priceFormatter(bill.quantite * bill.prixUnitaire)} </TableCell>

                                                    </TableRow>
                                                })
                                            }


                                        </TableBody>
                                    </Table>
                                </TableContainer>





                            </Box>
                            <Box
                                sx={
                                    {
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "flex-start",
                                        gap: 10,
                                    }
                                }
                            >



                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Mode de paiement</TableCell>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (FCFA) </TableCell>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (EURO) </TableCell>

                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date </TableCell>
                                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Commentaire </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                facture?.paiements.map((payment, idx) => {
                                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                                        <TableCell> {payment.methode} </TableCell>
                                                        <TableCell> {priceFormatter(payment.montant)} </TableCell>
                                                        <TableCell> {toEuro(payment.montant)} </TableCell>
                                                        <TableCell> {dateFormatter(payment.date)} </TableCell>
                                                        <TableCell> {payment.commentaire} </TableCell>



                                                    </TableRow>

                                                })
                                            }


                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Box>

                            <Stack
                                direction="row"
                                justifyContent="flex-end"

                                sx={{
                                    width: '100%',
                                    gap: "10px"
                                }}
                            >


                                <Button
                                    onClick={() => navigateTo(`/dossiers/${type}/${dossier}/factures/${id}/edit`)} color="primary" variant="outlined" startIcon={<Update />}>
                                    Modifier
                                </Button>


                                {/* <Button
                                    onClick={() => navigateTo(`/dossiers/${type}/edit/${id}`)}
                                    color="error"
                                    variant="outlined"
                                    startIcon={
                                        <Delete style={{ fill: '#D33131' }} />
                                    }>
                                    Supprimer
                                </Button> */}

                            </Stack>





                        </Stack>





                    </Stack>










                </LocalizationProvider>

            </div>
        }

        <div className="tabs">
            <div className={tab == 0 ? "tab active" : "tab"} onClick={() => setTab(0)}>
                Euro
            </div>
            <div className={tab == 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>
                FCFA
            </div>
        </div>


        {tab == 0 && <PDFViewer style={{ width: '100%', height: '100vh' }} >
            <FactureDossierPDF facture={facture} euro={true} />


        </PDFViewer>}
        {tab == 1 && <PDFViewer style={{ width: '100%', height: '100vh' }} >
            <FactureDossierPDF facture={facture} />


        </PDFViewer>}
    </>;

}


export default DetailsFactureDossier;