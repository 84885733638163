import { useDispatch, useSelector } from "react-redux";
import { createToClient, deleteToClient, fetchToClient, updateToClient } from "../../data/slices/toClientSlice";
import { notify } from "../../settings/toastSetting";
import ToClient from "../../data/schemas/toClient";
import { useEffect, useState } from "react";
import StyledTable from "../../components/table-component/StyledTable";
import Transform from "../../components/TransForm/Transform";
import { Button, Dialog } from "@mui/material";
import { sortByProp } from "../../settings/themeSettings";



const ToClientPage = (props)=>{

    const { toClients } = useSelector((state) => state.toClients);
    const dispatch = useDispatch();

    const [toClient, setToClient] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addToClient = (data, cb)=> {
        handleClose();
        console.log(data);


        if(data.id){

            updateToClient(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
            notify("le TO/Client a été modifié avec succés!");
            dispatch(fetchToClient());
    
    
            });
    


            return;
        }

        createToClient(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();
            notify("Le TO/Client a  été ajouté avec succés!");
            dispatch(fetchToClient());
        });
    }

    const onToClientSelected = (id) =>{ 
        setToClient(toClients.find((t) => t.id === id));
        setOpen(true);
    
    }

    const onDelete = (id) => {
        handleClose();
        deleteToClient(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setToClient(null);

        notify("Le TO/Client a bien été supprimé!");
        dispatch(fetchToClient());

        });
    }


    useEffect(() => {
        dispatch(fetchToClient());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
        <div className="header">
                <div className="title">
                    Liste des TO/Clients
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
                    <StyledTable  
                    rows={[...toClients].sort((a,b) => sortByProp(a, b, 'libelle'))} 
                    head={Object.keys(new ToClient())} 
                    onOptionClicked={onToClientSelected}
                    
                    
                    />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

        <Transform
        onSubmit={addToClient} 
        mandatories={["libelle", "code"]} 
        entity={new ToClient()}

        toUpdate={toClient} 
        onDelete={onDelete}
        onCancel={()=> handleClose()}
        
        
        />
                </Dialog>

    </div>
}


export default ToClientPage;