class Excursion {
    constructor(date, heure, excursion, observation, chauffeur, guide, pax, prix_unitaire, montant) {

        this.date = date;
        this.heure = heure;
        this.excursion = excursion;
        this.observation = observation;
        this.chauffeur = chauffeur;
        this.guide = guide;
        this.pax = pax;

        this.prixUnitaire = prix_unitaire;
        this.montant = montant;
    }
}


export default Excursion;