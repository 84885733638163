import _ from "lodash";
import EditableTable from "../../components/editableTable/EditableTable";
import UpdatedTable from "../../components/updated-table/StyledTable";
import { useEffect, useState } from "react";
import { COLORS, priceFormatter, toEuro } from "../../settings/themeSettings";
import { Stack } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import { BlobProvider } from "@react-pdf/renderer";
import NJournalPDF from "../../components/JournalPDF/NJournalPDF";



import './calculator.scss';


const HotelsTable = ({ hotels = [], onHotelChanged = () => { } }) => {

    return <div>
        <div className="_title">Charge Individuelle</div>
        <EditableTable
            data={hotels}
            reference={{ pax: '', supSgle: '', prestation: '', tarif: '', regime: '', total: '' }}
            onUpdate={onHotelChanged}
        //  operations={{ total: { labels: ['pax', 'tarif'], type: '*' } }}

        />
    </div>
}


const FraisTable = ({ frais = [], onFraisChanged = () => { } }) => {

    return <div>
                <div className="_title">Charge Collective</div>

        <EditableTable
            data={frais}
            reference={{ frais: '', '2-3Pax': '', '4-8Pax': '', '9-20Pax': '', '21-30Pax': '', '31-50Pax': '' }}
            onUpdate={onFraisChanged} />
    </div>
}


const Calculator = (props) => {

    const [outputs, setOutputs] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [frais, setFrais] = useState([]);

    useEffect(() => {

        setOutputs(_.range(1, 51).map((i) => ({ pax: i })));

    }, []);

    const onHotelsChanged = (update) => setHotels(update);
    const onFraisChanged = (update) => setFrais(update);
    const totalheb = (pax) => {

        return hotels.reduce((sum, current) => {

            if (pax == current.pax || current.pax == 1) {
                return ({ tarif: sum.tarif + Number(current.tarif) })
            } else {
                return sum;
            }



        }, { tarif: 0 }).tarif;
    }


    useEffect(() => {

        console.log(hotels);
        hotels.length > 0 && setOutputs(outputs.map((output, idx) => {
            return { ...output, hebergement: totalheb(output.pax), totalCFA: totalheb(output.pax) + (output.transport || 0), totalEuro: toEuro(totalheb(output.pax) + (output.transport || 0)), '15%': toEuro((totalheb(output.pax) + (output.transport || 0)) / 0.85) , '20%': toEuro((totalheb(output.pax) + (output.transport || 0)) / 0.85)}
        }));



    }, [hotels]);
    useEffect(() => {

        frais.length > 0 && setOutputs(outputs.map((output, idx) => {


            if (output.pax >= 1 && output.pax <= 3) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['2-3Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)),  '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }


            if (output.pax >= 4 && output.pax <= 8) {
                const transport = Math.round(_.sumBy(frais,(f) => Number(f['4-8Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: Number(transport) + Number(output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)) ,  '20%': toEuro((transport + (output.hebergement || 0) / 0.80))};
            }

            if (output.pax >= 9 && output.pax <= 20) {
                const transport = Math.round(_.sumBy(frais, (f) => Number(f['9-20Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)),  '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }
            if (output.pax >= 21 && output.pax <= 30) {
                const transport = Math.round(_.sumBy(frais,  (f) => Number(f['21-30Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)),  '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }


            if (output.pax >= 31 && output.pax <= 50) {
                const transport = Math.round(_.sumBy(frais,  (f) => Number(f['31-50Pax'])) / output.pax);
                return { ...output, transport: transport, totalCFA: transport + (output.hebergement || 0), totalEuro: toEuro(transport + (output.hebergement || 0)), '15%': toEuro((transport + (output.hebergement || 0) / 0.85)) ,  '20%': toEuro((transport + (output.hebergement || 0) / 0.80)) };
            }
            return { ...output, transport: 0 };


        }));

        console.log(frais);
    }, [frais]);



    return <>

        <Stack
            direction="row"
            justifyContent="flex-start"
            p="5"
        >

            <div className="btn"
                style={{
                    margin: '10px 0'
                }}
                onClick={() => {
                    document.getElementById('journalv')?.click();
                }}
            >
                <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                <BlobProvider document={
                    <NJournalPDF datas={[hotels, frais, outputs ]} heads={[
                        Object.keys({ pax: '', supSgle: '', prestation: '', tarif: '', regime: '', total: '' }),
                        Object.keys({ frais: '', '2-3Pax': '', '4-8Pax': '', '9-20Pax': '', '21-30Pax': '', '31-50Pax': '' }),
                        ['pax', 'hebergement', 'transport', 'totalCFA', 'totalEuro', '15%']
                    ]}
                    title="Programme"
                    />
                }>
                    {({ blob, url }) => (
                        <a href={url}
                            id=''
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                fontSize: '1em',
                                color: COLORS.secondary,
                                fontWeight: 'bold'
                            }}
                            target="_blank"
                        >PDF</a>
                    )}
                </BlobProvider>
            </div>

        </Stack>

        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '50px',
            width: '100%'
        }}>


            <div
                style={{
                    width: '800px'
                }}
            >

                <div className="_title">Cotation</div>
                <UpdatedTable 
                head={['pax', 'hebergement', 'transport', 'totalCFA', 'totalEuro', '15%', '20%']} 
                rows={outputs}
                
                
                />

            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
                width: '50%'
            }}>
                <HotelsTable hotels={hotels} onHotelChanged={onHotelsChanged} />
                <FraisTable frais={frais} onFraisChanged={onFraisChanged} />
            </div>

        </div>
    </>
        ;
}


export default Calculator;