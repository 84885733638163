import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchBillets } from '../../data/slices/billetsSlice';
import UpdatedTable from '../../components/updated-table/StyledTable';
import SearchIcon from '@mui/icons-material/Search';
import { AccountBalance, AccountBoxOutlined, AccountBoxSharp, Business, BusinessCenter, Delete, Edit, FileDownloadOutlined, FilterAlt, MonetizationOn, PaymentTwoTone, PaymentsTwoTone, PersonPinCircle, Refresh, SwipeLeftAlt, SwipeRightAlt, TramSharp } from "@mui/icons-material";
import { COLORS, jsonToXlsx } from '../../settings/themeSettings';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { Typography } from '@mui/material';
import paiementsSlice, { fetchPaiments } from '../../data/slices/paiementsSlice';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import JournalPDF from '../../components/JournalPDF/JournalPDF';



const PaymentPage = (props) => {

    const { paiements, fetchPaiementStatus } = useSelector((state) => state.paiements);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const {filterPayments } = paiementsSlice.actions;


    useEffect(() => {
        dispatch(fetchPaiments());
    }, []);

    useEffect(() => {

        if (!!startDate && !!endDate) {
            dispatch(fetchPaiments({ startDate, endDate }));
        }


    }, [startDate, endDate]);


    return (fetchPaiementStatus == 'loading') ?
        <div className="loader-container">
            <span className="loader"></span>
        </div>
        : <>



            <div className="header">
                <div className="title">
                    Journal de paiements
                </div>

            </div>

            <div className="table-header">

                <div className="tools">
                    <div className="btn" onClick={() => {
                        setEndDate(null);
                        setStartDate(null);
                        dispatch(fetchPaiments());

                    }}>
                        <Refresh style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                    </div>

                    {/* <div className="btn">
                        <FilterAlt style={{ fill: COLORS.secondary }} />
                        <select>
                            <option value="">Tout</option>
                            <option value="">N° Facture</option>
                            <option value="">N° Billet</option>
                        </select>
                    </div> */}
                    <div className="btn">
                        <span>De</span>
                        <input type="date" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                        <span>à</span>

                        <input type="date" value={endDate} onChange={(e) => {
                            console.log(endDate);
                            setEndDate(e.target.value);
                        }} />
                    </div>
                    <div className="btn" onClick={
                        () => {

                            if (paiements && paiements.length > 0) {
                                jsonToXlsx(paiements, "Journal de paiement");
                            }
                        }
                    }>
                        <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Excel</Typography>
                    </div>

                    <div className="btn"
                              onClick={() => {
                                document.getElementById('journalp')?.click();
                            }}
                    >
                        <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <BlobProvider document={ <JournalPDF data={[...paiements].reverse()} heads={['facture', 'methode', 'montant', 'date']} title="Journal de Paiement" />}>
                        {({ blob, url }) => (
                        <a href={url}
                        id='journalp'
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontSize: '1em',
                            color: COLORS.secondary,
                            fontWeight: 'bold'
                        }}
                        target="_blank"
                        >PDF</a>
                        )}
                        </BlobProvider>

                    </div>
                </div>
             <div className="searchbar">


                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon style={{ fill: COLORS.secondary }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Recherche"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') {
                                     dispatch(filterPayments({nail : e.target.value}));
                                      
                                        
                                    }
                                  }
                            }
                        />
                    </Search>
                </div> 
            </div>

          <UpdatedTable rows={[...paiements].reverse()} head={['facture', 'methode', 'montant', 'date', 'commentaire']} ></UpdatedTable>
        
            
            
              </>;







}


export default PaymentPage;