import React, { useEffect, useReducer, useState } from "react";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { AirplaneTicket, CreditCard, Delete, PaymentsTwoTone } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { COLORS, theme } from "../../settings/themeSettings";
import AddTicketDialog from "../../components/AddTicketDialog";
import AddBillDialog from "../../components/AddBillDialog";
import AddPaymentDialog from "../../components/AddPaymentDialog";
import WestIcon from '@mui/icons-material/West';
import { useNavigate, useParams } from "react-router";
import { calculateTotalFrais, cancelTickets, fetchFacture } from "../../data/slices/factureSlice";
import Facture from "../../data/schemas/facture";
import { notify } from "../../settings/toastSetting";


const styles = {
    container: {
        // border: '1px solid blue',
        //    minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}










const CancelTicket = (props) => {



    const navigateTo = useNavigate();
    const { id, clientId } = useParams();
    const [facture, setFacture] = useState({});
    const [cancelFacture, setCancelFacture] = useState(new Facture());
    const [loading, setLoading] = useState(false);

    const validateFacture = () => {
        const newFacture = { ...cancelFacture, billets: facture.billets };
        setCancelFacture(newFacture);
        let selectedTickets = facture.billets.filter((b) => b.checked);
        console.log(selectedTickets);

        if (selectedTickets.length < 1) {
            notify("Veuillez choisir les billets à annuler SVP!", 'error', 'TOP_LEFT');
            return;
        }

        setLoading(true);

        cancelTickets({ ...cancelFacture, billets: selectedTickets }, (err) => {
            console.log(err);
            notify('Une erreur s\'est produite, actualisez puis réessayez!', 'error', 'TOP_LEFT');
            setLoading(false);

        }, (facture) => {
            setLoading(false);

            try {
                if(!facture.id){
                    notify('Une erreur s\'est produite, actualisez puis réessayez!', 'error', 'TOP_LEFT');


                    return;
                }

                if(facture.error){

                    notify(facture.error, 'error', 'TOP_RIGHT');

                    return;
                }




                navigateTo(`/billeterie/factures/${clientId}/details/${facture.id}`);





            } catch (e) {
                console.log(e);
            }



        });
    }


    const checkBillet = (id, value) => {
        console.log({ id, value });
        const newBillets = facture.billets.map((f) => {

            if (f.id === id) {
                f.checked = true;
            }

            return f;
        });
        setFacture({ ...facture, billets: newBillets });
    }



    useEffect(() => {

        fetchFacture(id).then((response) => response.json())
            .then(async (facture) => {
                // console.log(resp);
                setCancelFacture({ ...cancelFacture, numeroDossier: facture.numeroDossier, factureReferente: facture.id, client: clientId });

                setFacture(facture);
                calculateTotalFrais(facture.frais);
            })
            .catch(err => {
                console.log(err);
            })
            ;

    }, []);









    return <Box
        sx={
            styles.container
        }>



        <Stack
            direction="row"
            justifyContent="space-between"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}/details/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '1100px' }}>Annulation de billet de billet </Typography>

        </Stack>
        <Stack

            direction="row"
            justifyContent="space-between"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }


        >




            <Stack sx={{ width: '30%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture
                </Typography>


                <Stack sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >  ***-****-**-***</span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                        <span> N° Dossier:</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{facture.numeroDossier} </span>

                    </Typography>


                </Stack>



            </Stack>
            <Stack sx={{ width: '30%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture Référente
                </Typography>


                <Stack sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} > {facture.numeroFacture} </span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> Total Frais :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} > {calculateTotalFrais(facture.frais)}  FCFA</span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                        <span> Total Réglement :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} > {calculateTotalFrais(facture.paiements)} FCFA</span>

                    </Typography>


                </Stack>



            </Stack>

            <Stack
                sx={{ width: '30%' }}
            >
                <TextField
                    margin="normal"
                    fullWidth
                    name="remboursement"
                    label="remboursement"
                    InputProps={{ style: { height: '45px' } }}
                    color="tertiary"
                    focused
                    placeholder="Montant remboursement"
                    onChange={(e) => setCancelFacture({ ...cancelFacture, montantRemboursement: e.target.value })}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    name="Commentaire"
                    label="Commentaire"
                    InputProps={{ style: { height: '145px' } }}
                    color="tertiary"
                    focused
                    multiline={true}
                    onChange={(e) => setCancelFacture({ ...cancelFacture, commentaire: e.target.value })}

                />

            </Stack>

        </Stack>

        <Stack
            direction="row"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }
            sx={{ minHeight: '250px' }}


        >


            <Box xs={{ ...styles.path }} width={'100%'}
            >


                <div className="spacer"></div>
                <Typography
                    variant="p"
                    sx={{ ...styles.pathtitle, fontSize: '1.5em' }}
                >
                    Choix des billets
                </Typography>


                <TableContainer component={Paper} sx={{ mt: '15px' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nom sur le billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Trajet </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Tarif </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant remboursable (FCFA) </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Options</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                facture?.billets && facture.billets.map((billet, idx) => {
                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                        <TableCell > {billet.numero} </TableCell>
                                        <TableCell > {billet.nom} </TableCell>
                                        <TableCell > {billet.dateVol} </TableCell>
                                        <TableCell> {billet.dateVolRetour} </TableCell>
                                        <TableCell > {billet.trajet} </TableCell>
                                        <TableCell > {billet.tarif} </TableCell>

                                        <TableCell > ------------- </TableCell>
                                        <TableCell >{ (billet.etat !== "OPEN") ? billet.etat : <input type="checkbox" onChange={(e) => checkBillet(billet.id, e.target.checked)} /> }</TableCell>

                                    </TableRow>

                                })

                            }

                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </Stack>


        <Box
            sx={{
                width: '100%',
                minHeight: '300px'
            }}
        >





            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

            >


                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    onClick={() => navigateTo(`/billeterie/factures/${clientId}/details/${id}`)}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={validateFacture}
                    disabled={loading }
                >
                  {loading? 'Patientez..' : 'Valider'}
                </Button>

            </Stack>


        </Box>







    </Box>
}



export default CancelTicket;