import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PAYMENT_HISTORY } from "../../settings/APISetting";
import { arrayFilter } from "../../settings/themeSettings";



export const fetchPaiments = createAsyncThunk('fetch-paiements', async (params) => {


if(!!params){
    const {startDate, endDate} = params;

    var response = await fetch(`${PAYMENT_HISTORY}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

}else{
    
    var response = await fetch(`${PAYMENT_HISTORY}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


}


    return response.json()
});



const paiementsSlice = createSlice({


    name: 'paiements',
    initialState: { paiements: [], fetchPaiementStatus: ''},
    reducers: {

        filterPayments: (state, action) => {
            state.paiements = arrayFilter(new RegExp(action.payload.nail), state.paiements)
        }
     },
    extraReducers: (builder) => {
        builder.addCase(fetchPaiments.fulfilled, (state, action) => {

            let placeholder = action.payload;

            placeholder.map((paie) => {

                paie.facture = paie.facture.numeroFacture;
                return paie;

            })
            state.paiements = placeholder;

            state.fetchStatus = 'success';
        }).addCase(fetchPaiments.pending, (state, action) => {
            state.fetchStatus = 'loading';

        }).addCase(fetchPaiments.rejected, (state, action) => {
            console.log(action);

            state.fetchStatus = 'error';

        });
    }

});

export default paiementsSlice;