import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import { createHotel, deleteHotel, fetchHotels, updateHotel } from '../../data/slices/hotelSlice';
import Hotel from '../../data/schemas/hotel';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const HotelPage = (props) => {

    const { hotels } = useSelector((state) => state.hotels);
    const dispatch = useDispatch();
    const [hotel, setHotel] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }



    const addHotel = (data, cb) => {
        console.log(data);
        handleClose();
        if (data.id) {


            updateHotel(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
                notify("L'hotel a été modifié avec succés!");
                dispatch(fetchHotels());


            });




            return;
        }
        createHotel(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();
            notify("L'hotel a  été ajouté avec succés!");
            dispatch(fetchHotels());
        })
    }

    const onHotelSelected = (id) => {
        setHotel(hotels.find((h) => h.id === id));
        setOpen(true);
    }

    const onDelete = (id) => {
        handleClose();

        deleteHotel(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setHotel(null);

            notify("L'hotel a bien été supprimé!");
            dispatch(fetchHotels());

        });
    }

    useEffect(() => {
        dispatch(fetchHotels());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des hotels
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...hotels].sort((a,b) => sortByProp(a, b, 'nom'))}
                head={Object.keys(new Hotel())}
                onOptionClicked={onHotelSelected}


            />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

            <Transform
                onSubmit={addHotel}
                mandatories={["nom", "telephone", "code", "courriel", "adresse"]}
                entity={new Hotel()}

                toUpdate={hotel}
                onDelete={onDelete}
                onCancel={() => handleClose()}

            />
        </Dialog>

    </div>

}


export default HotelPage;