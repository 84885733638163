import {  Route, Routes } from "react-router";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import BFacturation from "./pages/BFacturation/BFacturation";
import FactureForm from "./components/facture-form/FactureForm";
import PortefeuilClient from "./pages/PortefeuilClient/PortefeuilClient";
import AjoutClient from "./pages/AjoutClient/AjoutClient";
import RecuViewer from "./pages/RecuViewer/RecuViewer";
import FactureViewer from "./pages/FactureViewer/FactureViewer";
import DetailsFacture from "./pages/DetailsFacture/DetailsFacture";
import UpdateTicket from "./pages/UpdateTicket/UpdateTicket";
import CancelTicket from "./pages/CancelTicket/CancelTicket";
import Journal from "./pages/journal/Journal";
import CircuitPage from "./pages/Circuits/CircuitPage";
import AjoutCircuitPage from "./pages/AjoutCircuitPage/AjoutCircuitPage";
import DetailsCircuit from "./pages/DetailsCircuit/DetailsCircuit";
import ChauffeurPage from "./pages/parametres/ChauffeurPage";
import VehiculePage from "./pages/parametres/VehiculePage";
import GuidePage from "./pages/parametres/GuidePage";
import AgencePage from "./pages/parametres/AgencePage";
import HotelPage from "./pages/parametres/HotelPage";
import TypeFraisPage from "./pages/parametres/TypeFraisPage";
import TypeExcursionPage from "./pages/parametres/TypeExcursionPage";
import ProgrammePage from "./pages/parametres/ProgrammePage";
import ToClientPage from "./pages/parametres/ToClientPage";
import FacturationDossier from "./pages/FacturationDossier/FacturationDossier";
import FacturesDossierPage from "./pages/FacturesDossierPage/FacturesDossierPage";
import DetailsFactureDossier from "./pages/DetailsFactureDossier/DetailsFactureDossier";
import DossierPageBase from "./pages/DossierPageBase";
import ProtectedRoute from "./auth/ProtectedRoute";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import UserPage from "./pages/parametres/UserPage";
import EditFacture from "./pages/EditFacture/EditFacture";
import Dashboard from "./pages/dashboard/Dashboard";
import Vouchers from "./pages/vouchers/Vouchers";
import DossierViewer from "./pages/DossierViewer/DossierViewer";
import TransfertCalendar from "./pages/TransfertCalendar/TransfertCalendar";
import Calculator from "./pages/calculator/Calculator";
import Caisse from "./pages/journal/Caisse";
import DevisForm from "./components/facture-form/DevisForm";
import DetailsDevis from "./pages/DetailsFacture/DetailsDevis";
import DevisViewer from "./pages/FactureViewer/DevisViewer";
import CompagnieVolPage from "./pages/parametres/CompagnieVolPage";




const AppRoutes = (props) => {


    return (
        <Routes>
            <Route path='/login' Component={LoginPage} />


                <Route path='/' element={
                    <ProtectedRoute>
                        <HomePage />
                    </ProtectedRoute>


                } >
                 <Route path='' Component={Journal} />

                    <Route path='chauffeurs' Component={ChauffeurPage} />
                    <Route path='dashboard' Component={Dashboard} />
                    <Route path='calendars' Component={TransfertCalendar} />
                    <Route path='compagnieVols' Component={CompagnieVolPage} />
                    <Route path='users' Component={UserPage} />
                    <Route path='vehicules' Component={VehiculePage} />
                    <Route path='guides' Component={GuidePage} />
                    <Route path='agences' Component={AgencePage} />
                    <Route path='hotels' Component={HotelPage} />
                    <Route path='type-frais' Component={TypeFraisPage} />
                    <Route path='type-excursions' Component={TypeExcursionPage} />
                    <Route path='programmes' Component={ProgrammePage} />
                    <Route path='toclients' Component={ToClientPage} />
                    <Route path='calculator' Component={Calculator} />

                    <Route path="dossiers" Component={DossierPageBase}>
                        <Route path=":type" Component={CircuitPage} />
                        <Route path=":type/:id" Component={DetailsCircuit} />
                        <Route path=":type/:id/voucher/:vid" Component={Vouchers} />
                        <Route path=":type/:id/pdf" Component={DossierViewer} />
                        <Route path=":type/:id/factures" Component={FacturesDossierPage} />
                        <Route path=":type/:dossier/factures/:id" Component={DetailsFactureDossier} />
                        <Route path=":type/:id/factures/:fid/edit" Component={FacturationDossier} />

                        <Route path=":type/:id/facturation" Component={FacturationDossier} />
                    </Route>

                    <Route path="dossiers/:type/edit/:id" Component={AjoutCircuitPage} />

                    <Route path="ajout/dossier/:type" Component={AjoutCircuitPage} />
                    <Route path='billeterie/journal' Component={Journal} />
                    <Route path='billeterie/caisse' Component={Caisse} />
                    <Route path='billeterie/paiements' Component={PaymentPage} />
                    <Route path='billeterie/portefeuil' Component={PortefeuilClient} />
                    <Route path='billeterie/portefeuil/ajout' Component={AjoutClient} />
                    <Route path='billeterie/portefeuil/:id' Component={AjoutClient} />
                    <Route path='billeterie/ticket/:clientId/update/:id' Component={UpdateTicket} />
                    <Route path='billeterie/facture/:clientId/update/:id' Component={EditFacture} />
                    <Route path='billeterie/ticket/:clientId/cancel/:id' Component={CancelTicket} />
                    <Route path='billeterie/factures/:clientId' Component={BFacturation} />
                    <Route path='billeterie/factures/:clientId/details/:id' Component={DetailsFacture} />
                    <Route path='billeterie/devis/:clientId/details/:id' Component={DetailsDevis} />
                    <Route path='billeterie/factures/nouveau/:clientId' Component={FactureForm} />
                    <Route path='billeterie/devis/nouveau/:clientId' Component={DevisForm} />
                    <Route path="billeterie/recu/:clientId/:id/pdf" Component={RecuViewer}></Route>
                    <Route path="billeterie/facture/:clientId/:id/pdf" Component={FactureViewer}></Route>
                    <Route path="billeterie/devis/:clientId/:id/pdf" Component={DevisViewer}></Route>

                </Route>

        </Routes>
    );


}

export default AppRoutes;