import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VOUCHER_URL } from "../../settings/APISetting";



export const fetchVoucher = createAsyncThunk('fetch-vouchers', async (id) => {


    const response = await fetch(`${VOUCHER_URL}/${id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});



const voucherSlice = createSlice({

    name: 'vouchers',
    initialState: {voucher: {}, voucherFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVoucher.fulfilled, (state, action) => {

            state.voucher =   action.payload;
   
            state.voucherFetchStatus = 'success';
        }).addCase(fetchVoucher.pending, (state, action) => {
            state.voucherFetchStatus = 'loading';

        }).addCase(fetchVoucher.rejected, (state, action) => {

            state.voucherFetchStatus = 'error';

        });
    }


});
export default voucherSlice;