import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TRANSFERTS_URL } from "../../settings/APISetting";



export const fetchTransfert = createAsyncThunk('fetch-transferts', async () => {


    const response = await fetch(`${TRANSFERTS_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});



const transfertSlice = createSlice({

    name: 'transferts',
    initialState: {transferts: {}, transfertsFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTransfert.fulfilled, (state, action) => {

            state.transferts =   action.payload;
   
            state.transfertsFetchStatus = 'success';
        }).addCase(fetchTransfert.pending, (state, action) => {
            state.transfertsFetchStatus = 'loading';

        }).addCase(fetchTransfert.rejected, (state, action) => {

            state.transfertsFetchStatus = 'error';

        });
    }


});
export default transfertSlice;