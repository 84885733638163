import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchCircuit } from "../../data/slices/circuitSlice";
import { Autocomplete, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { COLORS, dateFormatter, priceFormatter, toEuro } from "../../settings/themeSettings";
import '../../index.scss';



import { Stack } from '@mui/material';

import WestIcon from '@mui/icons-material/West';
import { CreditCard, Delete,  PaymentsTwoTone } from '@mui/icons-material';
import AddPaymentDialog from "../../components/AddPaymentDialog";
import { DateField, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AjoutFraisDialog from "../../components/AjoutFraisDialog";
import { createFactureDossier, fetchFactureDossier } from "../../data/slices/facturesDossierSlice";
import { notify } from "../../settings/toastSetting";
import dayjs from "dayjs";
import { isValid } from "date-fns";



const styles = {
    container: {
        minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}






const FacturationDossier = (props) => {
    const { id, type, fid } = useParams();

    const [loading, setLoading] = useState(false);
    const [loadingAjout, setLoadingAjout] = useState(false);
    const [facture, setFacture] = useState({});
    const [circuit, setCircuit] = useState({});
    const [bills, setBills] = useState([]);
    const [payments, setPayments] = useState([]);
    const [openAddBill, setOpenAddBill] = useState(false);
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const navigateTo = useNavigate();
    const [totalFrais, setTotalFrais] = useState(0);
    const [totalPaiements, setTotalPaiements] = useState(0);

    const typeFactures = [{
        name: 'Facture d\'Avoir',
        code: 'AV'
    },
    {
        name: 'Facture Comptant',
        code: 'FC'

    },
    {
        name: 'Facture Proforma',
        code: 'FP'
    }];

    const openAddPaymentDialog = () => {
        setOpenAddPayment(true);
    }
    const openAddBillDialog = () => {
        setOpenAddBill(true);
    }

    const handleAddBillClose = (bill) => {
        setOpenAddBill(false);
        console.log(bill);
        if (bill) {
            setBills([...bills, bill]);
        }
    }

    const handleAddPaymentClose = (payment) => {
        setOpenAddPayment(false);
        console.log(payment);
        if (payment) {
            setPayments([...payments, payment]);
        }
    }
    const removeBill = (id) => {
        let _bills = bills.map((bill, idx) => {
            if (idx != id) {
                return bill;
            }
        }).filter((bill) => !!bill);
        setBills(_bills);
    }


    const removePayment = (id) => {
        let _payments = payments.map((payment, idx) => {
            if (idx != id) {
                return payment;
            }
        }).filter((payment) => !!payment);
        setPayments(_payments);
    }
    useEffect(() => {





        setLoading(true);

        fetchCircuit(id)
            .then((response) => {

                if (response.status > 201) {
                    throw response;
                }

                return response.json();
            })
            .then(async (resp) => {

                if(fid){
                    console.log("we are editing!");
                    fetchFactureDossier(fid, (err) => {
                        console.log(err);
                        setLoading(false);

                    }, (resp) => {
                        setFacture(resp);
                        setBills(resp.frais);
                        setPayments(resp.paiements);
                        setLoading(false);

                    })
                }else{
                                    setLoading(false);

                }
                console.log(resp);
                setCircuit({...resp});
            })
            .catch(err => {
                setLoading(false);

                console.log(err);
            });






    }, []);
    const createFacture = () => {

        setLoadingAjout(true);

        createFactureDossier({
            ...facture,
            dossier: id,
            frais: bills,
            paiements: payments,
            date: isValid(facture.date)? facture.date?.toISOString().split('T')[0] : facture.date

        }, (err) => {
            console.log(err);

            setLoadingAjout(false);

            err.json().then((result) => {
                console.log(result);
                if (result.error) {
                    notify(result.error, "error");
                    return;
                }

            }).catch((e) => {
            setLoadingAjout(false);

                notify("Une erreur s'est produite actualisez puis réeesayer!", "error");

            });




        }, (resp) => {
            console.log(resp);
            setLoadingAjout(false);


            notify("La facture a été ajoutée avec succés!", "success");
            navigateTo(`/dossiers/${type}/${id}/factures/${resp.id}`)
        });

    };
    useEffect(() => {


       const newTotalFrais = bills.reduce((total, current) =>{ 
           return { montant: Number(total.montant)  + Number(current.prixUnitaire * current.quantite) };
        
        }, { montant :0}).montant;


        const newTotalPaiements = payments.reduce((total, current) =>{ 
            
           return {montant : Number(total.montant) + Number(current.montant) };
        }, { montant :  0}).montant;
        setTotalFrais(newTotalFrais);
        setTotalPaiements(newTotalPaiements);



    }, [bills, payments]);


    useEffect(() => {

      

    }, [fid])
    return <>

        {loading ?
            <div className="loader-container">
                <span className="loader"></span>
            </div>

            :



            <div className='details-circuit-container' >

                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                >


                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        gap="15"
                        alignItems="flex-start"

                    >
                        <div onClick={() => navigateTo(`/dossiers/${type}/${id}`)}
                            style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                            <WestIcon style={{ fill: 'white', fontSize: 28 }} />
                        </div>
                        <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '100%', ml: 2 }}>Facturation {type} </Typography>

                    </Stack>


                    <Stack

                        direction="row"
                        justifyContent="space-between"

                        divider={
                            <Box
                                component="hr"
                                sx={{
                                    border: `1px solid ${COLORS.tertiary}`,
                                }}
                            />
                        }


                    >




                        <Stack sx={{ width: '30%', mb: 5 }}>
                            <Typography
                                variant="p"
                                sx={styles.pathtitle}
                            >
                                Détails {type}
                            </Typography>



                            <Stack sx={{ padding: '0 10px' }}>

                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Réference:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.reference}  </span>
                                </Typography>



                             { (type == 'transfert') &&  <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Nom client:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.nomClient}  </span>
                                </Typography>}

                                {(type !=( 'sejour' && 'transfert')) && <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Programme :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.programme?.libelle} </span>

                                </Typography>}
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> TO / Client :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.toClient?.libelle} </span>

                                </Typography>

                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Montant total :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{priceFormatter(circuit?.frais?.reduce((sum, frai) => ({ montant: sum.montant + frai.montant }), { montant: 0 }).montant)} </span>

                                </Typography>
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Commentaire: </span>
                                    <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                        {circuit.commentaire}
                                    </span>
                                </Typography>
                            </Stack>





                        </Stack>

                        <Stack sx={{ width: '30%', mb: 5 }}>
                            <Typography
                                variant="p"
                                sx={styles.pathtitle}
                            >
                                Détails {type}
                            </Typography>


                            <Stack sx={{ padding: '0 10px' }}>
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Chauffeur :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit?.chauffeur?.nomComplet}  </span>
                                </Typography>
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Guide :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit?.guide?.nomComplet} </span>

                                </Typography>
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                                    <span> Vehicule :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit?.vehicule?.modele} </span>

                                </Typography>
                                {
                                    (type == 'circuit' || type == 'sejour') && <>

                                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Date vol Arrivee: </span>
                                            <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                                {dateFormatter(circuit?.dateVolArrivee)  + ' - ' + (circuit?.heureVolArrivee ? (new Date(circuit?.heureVolArrivee)).toTimeString().split(' ')[0] : '')}
                                            </span>
                                        </Typography>
                                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                            <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Date vol Depart: </span>
                                            <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                                { dateFormatter(circuit?.dateVolDepart) + ' - ' + (circuit?.heureVolDepart ? (new Date(circuit?.heureVolDepart)).toTimeString().split(' ')[0] : '')}
                                            </span>
                                        </Typography>
                                    </>
                                }

                            </Stack>





                        </Stack>
                        <Stack
                            sx={{ width: '30%' }}
                            justifyContent="flex-start"
                            gap={2}
                            style={{ padding: '0 20px' }}
                        >


                            <DateField
                                label="Date"
                                color="tertiary"
                                focused
                                fullWidth
                                sx={{ mt: 2.5 }}
                                InputProps={{ style: { height: '45px' } }}
                                format="DD-MM-YYYY"
                                onChange={(e) => { setFacture({ ...facture, date: e }) }}
                              defaultValue={facture && facture.date && dayjs(new Date(facture.date)) }

                            />
                            <Autocomplete


                                onChange={(event, option) => {
                                    console.log(option.code);
                                    setFacture({ ...facture, typeFacture: option.code });
                                }}
                                options={typeFactures}
                                getOptionLabel={(option) => option.name}

                                sx={{ height: '45px', mt: '15px' }}
                                color="tertiary"
                                renderInput={(params) => <TextField {...params} label="Type de facture" />}
                                defaultValue={facture && typeFactures.find((f) => f.code == facture?.typeFacture)}


                            />

                            <Autocomplete
                                defaultValue={facture && facture.etat}

                                onInputChange={(event, newInputValue) => {
                                    setFacture({ ...facture, etat: newInputValue });
                                }}
                                options={['En cours', 'Clôturée', "Annulée"]}
                                sx={{ height: '45px', mt: '15px' }}
                                color="tertiary"
                                renderInput={(params) => <TextField {...params} label="Etat de la facture" />}

                            />

                            <TextField
                                margin="normal"
                                fullWidth
                                name="Commentaire"
                                label="Commentaire"
                                InputProps={{ style: { height: '70px' } }}
                                color="tertiary"
                                focused
                                multiline={true}
                                onChange={(e) => { setFacture({ ...facture, commentaire: e.target.value }) }}
                                defaultValue={facture && facture.commentaire}

                            />


                        </Stack>






                    </Stack>




                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            '& > *': { width: '50%' },
                            height: '100%',
                            mt: 8,
                            padding: '0px 5px',

                        }}
                        divider={
                            <Box
                                component="hr"
                                sx={{
                                    border: `1px solid ${COLORS.tertiary}`,
                                    width: '0px !important'
                                }}
                            />
                        }
                    >


                        <Box sx={
                            {
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 10
                            }
                        }>


                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 450 }} aria-label="simple table" >
                                    <TableHead >
                                        <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>


                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Designation</TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nombre</TableCell>

                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Prix unitaire en CFA</TableCell>


                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Prix unitaire   en EURO</TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>


                                        {
                                            bills.map((bill, idx) => {
                                                return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                                    <TableCell > {bill.designation} </TableCell>
                                                    <TableCell > {bill.quantite} </TableCell>
                                                    <TableCell > {priceFormatter(bill.prixUnitaire) } </TableCell>

                                                    <TableCell > {bill.prixUnitaire ? (toEuro(bill.prixUnitaire )) : ''} </TableCell>
                                                    <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }}
                                                        onClick={() => removeBill(idx)}
                                                    /> </TableCell>

                                                </TableRow>
                                            })
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>





                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<PaymentsTwoTone sx={{ fill: COLORS.secondary }} />}
                                onClick={openAddBillDialog}
                            >
                                Ajouter prestations
                            </Button>
                        </Box>
                        <Box
                            sx={
                                {
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "flex-start",
                                    gap: 10,
                                }
                            }
                        >



                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Mode de paiement</TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (CFA) </TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (EURO) </TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date </TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Commentaire </TableCell>
                                            <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            payments.map((payment, idx) => {
                                                return <TableRow key={idx} sx={{ color: COLORS.secondary, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                                    <TableCell> {payment.methode} </TableCell>
                                                    <TableCell> {priceFormatter(payment.montant) } </TableCell>
                                                    <TableCell> {toEuro(payment.montant || 0) } </TableCell>
                                                    <TableCell> {dateFormatter(payment.date)} </TableCell>
                                                    <TableCell> {payment.commentaire} </TableCell>
                                                    <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }}
                                                        onClick={() => removePayment(idx)}

                                                    /> </TableCell>


                                                </TableRow> 

                                            })
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<CreditCard sx={{ fill: COLORS.secondary }} />}
                                onClick={openAddPaymentDialog}
                            >
                                Ajouter un réglement
                            </Button>


                        </Box>





                    </Stack>

            <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={4}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }

            >


                <div className="total">
                    <div className="title">
                        Total Facture:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                      {  priceFormatter(totalFrais)}
                    </div>

                    
                </div>

                <div className="total">
                    <div className="title">
                        Total Réglement:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                        {priceFormatter(totalPaiements)} 
                    </div>

                    
                </div>

                <div className="total">
                    <div className="title">
                        Solde:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                       {priceFormatter(totalFrais - totalPaiements) } 
                    </div>

                    
                </div>


            </Stack>







                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{
                            mt: 4,
                            mb: 4,
                            pb: 5
                        }}

                    >


                        <Button
                            variant="contained"
                            color="primary"
                            style={{ color: 'white' }}

                        >
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loadingAjout}
                            onClick={createFacture}
                        >
                            {loading ? 'Patientez..' : 'Enregistrer'}
                        </Button>

                    </Stack>
                </LocalizationProvider>

            </div>
        }
        <AjoutFraisDialog open={openAddBill} onClose={handleAddBillClose} />
        <AddPaymentDialog open={openAddPayment} onClose={handleAddPaymentClose} />
    </>;

}


export default FacturationDossier;