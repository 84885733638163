import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import Chauffeur from '../../data/schemas/chauffeur';
import { useDispatch, useSelector } from 'react-redux';
import { createChauffeur, deleteChauffeur, fetchChauffeur, updateChauffeur } from '../../data/slices/chauffeurSlice';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const ChauffeurPage = (props) => {

    const { chauffeurs, chauffeursFetchStatus } = useSelector((state) => state.chauffeurs);
    const dispatch = useDispatch();

    const [chauffeur, setChauffeur] = useState(null);
    const [open, setOpen] = useState(false);

    const addChauffeur = (data, clearForm) => {
        console.log(data);
        setOpen(false);

        if (!data.id) {
            createChauffeur(data, (err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);
                clearForm();

                notify("Le chauffeur a été ajouté avec succés!");
                dispatch(fetchChauffeur());

            });
        } else {

            updateChauffeur(data, (err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);
                clearForm();

                notify("Le chauffeur a été modifié avec succés!");
                dispatch(fetchChauffeur());

            });


            console.log({ data });
        }

    }

    const onChauffeurSelected = (id) => {
        setChauffeur(chauffeurs.find((chauffeur) => chauffeur.id === id));
        setOpen(true);
    }

    const onDelete = (id) => {
        setOpen(false);
        deleteChauffeur(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setChauffeur(null);

            notify("Le chauffeur a bien été supprimé!");
            dispatch(fetchChauffeur());

        })
    }

    const handleClose = ()=>{
        setOpen(false);
    }
    useEffect(() => {
        dispatch(fetchChauffeur());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des chauffeurs
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...chauffeurs].sort((a,b) => sortByProp(a, b, 'nomComplet'))}
                head={Object.keys(new Chauffeur())}
                onOptionClicked={onChauffeurSelected} />

        </div>
        <Dialog onClose={(e) => handleClose(e) }  open={open} >
            <Transform onSubmit={addChauffeur}
                mandatories={["nomComplet", "telephone", "adresse"]}
                entity={new Chauffeur()}
                toUpdate={chauffeur}
                onDelete={onDelete}
                onCancel={() => { setOpen(false)}}
            />
        </Dialog>


    </div>

}


export default ChauffeurPage;