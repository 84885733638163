import React from "react";
import { Navigate } from "react-router";
import { useToken } from "./useToken";


const ProtectedRoute = ({children}) => {
        const [token] = useToken();

      return !!token? children:(<Navigate to="/login" /> );
}

export default ProtectedRoute;