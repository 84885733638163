

import { Button, Typography } from '@mui/material';
import './circuitPage.scss';
import { FilterAlt, Refresh } from '@mui/icons-material';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { COLORS } from '../../settings/themeSettings';
import SearchIcon from '@mui/icons-material/Search';
import UpdatedTable from '../../components/updated-table/StyledTable';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import circuitSlice, { fetchCircuits } from '../../data/slices/circuitSlice';
import StyledTable from '../../components/table-component/StyledTable';
import _ from "lodash";




const CircuitPage = (props) => {
    const { type } = useParams();

    const navigateTo = useNavigate();
const onOptionClicked = (id) => {
    navigateTo(`/dossiers/${type}/${id}`);
}

    const { circuits, fetchStatus } = useSelector((state) => state.circuits);
    const dispatch = useDispatch();
    const { filterCircuits} = circuitSlice.actions;
    useEffect(()=>{
        dispatch(fetchCircuits(type));
    }, [type]);




    return (<div className="circuit-page">
        <div className="header">
            <div className="title">
                Liste des {type}s
            </div>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => { navigateTo('/ajout/dossier/' + type) }}
            >
                Ajouter un {type}

            </Button>
        </div>



        <div className="table-header">

            <div className="tools">
                <div className="btn" onClick={(e) =>{
        dispatch(fetchCircuits(type));
    }}>
                    <Refresh style={{ fill: COLORS.secondary }} />
                    <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                </div>

        
            </div>
            <div className="searchbar">


                <Search>
                    <SearchIconWrapper>
                        <SearchIcon style={{ fill: COLORS.secondary }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Recherche"
                        inputProps={{ 'aria-label': 'search' }}

                        onKeyDown={
                            (e) => {
                                if (e.key === 'Enter') {
                                    dispatch(filterCircuits({ nail: e.target.value }));
                                }
                            }
                        }
                    />
                </Search>
            </div>

            
        </div>
        {
            fetchStatus == 'loading'? 
            <div className="loader-container">
            <span className="loader"></span>
        </div>
        :

                <StyledTable rows={_.sortBy(circuits, 'date').reverse() } head={(type == 'sejour' || 'transfert')? ['reference', 'methodePaiement', 'statut', 'date'] : ['reference', 'methodePaiement', 'numeroFacture', 'statut', 'date']} onOptionClicked={onOptionClicked} />

             //   <UpdatedTable rows={circuits} head={['programme', 'toClient', 'methodePaiement', 'numeroFacture', 'Statut']} ></UpdatedTable>

        }

    </div>);
}


export default CircuitPage;