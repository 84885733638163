import React, { useEffect, useState } from "react";
import "./ajoutClient.scss";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from "@mui/x-date-pickers";
import { CreditCard, PaymentsTwoTone } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { COLORS } from "../../settings/themeSettings";
import { useNavigate, useNavigation, useParams } from "react-router";
import clientSlice, { createClient, editClient, fetchClient } from "../../data/slices/clientStore";
import { useDispatch, useSelector } from "react-redux";
import Client from "../../data/schemas/client";
import { notify } from "../../settings/toastSetting";


const styles = {
    container: {
        //    border: '1px solid blue',
        height: '550px',
        width: '1000px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}




const AjoutClient = (props) => {


    const navigateTo = useNavigate();
    const { id } = useParams();


    const [clientType, setClientType] = useState("personne");
    const [client, setClient] = useState(new Client());
    const [loading, setLoading] = useState(false);


    const addClientHandler = () => {
        setLoading(true);

        if (id) {

            editClient(id, client, (err) => {
                console.log(err);
                notify("Vérifiez les informations entrées puis réessayez!", "error");

                setLoading(false);

            }, (resp) => {
                console.log(resp);

                setClient(new Client());
                notify("Le client a été ajouté avec succés!", "success");
                navigateTo('/billeterie/portefeuil');
                setLoading(true);
                setLoading(false);


            });

        } else {

            createClient(client, (err) => {
                console.log(err);
                notify("Vérifiez les informations entrées puis réessayez!", "error");

                setLoading(false);

            }, (resp) => {
                console.log(resp);

                setClient(new Client());
                notify("Le client a été ajouté avec succés!", "success");
                navigateTo('/billeterie/portefeuil');
                setLoading(true);
                setLoading(false);


            });

        }
        console.log(client);

    }

    useEffect(() => {

        fetchClient(id, (err) => {
            console.log(err);
        }, (client) => {
            console.log(client);
            setClient(client);
            if (client.structure) {
                setClientType("structure");
            }
        });

    }, []);


    useEffect(() => {
        setClient({ ...client, structure: !(clientType == "personne") })
    }, [clientType]);



    return <Box
        sx={
            styles.container
        }>
        <LocalizationProvider dateAdapter={AdapterDayjs}>



            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5 }}> {id ? 'Modifier' : 'Nouveau'}  client </Typography>

            <div className="tabs">
                <div className={clientType == "personne" ? "tab active" : "tab"} onClick={() => setClientType("personne")}>
                    personne
                </div>
                <div className={clientType == "structure" ? "tab active" : "tab"} onClick={() => setClientType("structure")}>
                    Structure
                </div>
            </div>

            <Stack
                direction="row"

                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                        }}
                    />
                }
                sx={{ pt: 5, borderTop: `1px solid ${COLORS.secondary}` }}


            >

                <Box xs={styles.path} width={'300px'}
                >
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}

                    >
                        Information sur le client
                    </Typography>




                    <TextField
                        margin="normal"
                        required
                        name="Nom"
                        value={client.nom || ''}
                        label={(clientType == "personne") ? "Nom" : "Structure"}
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder={(clientType == "personne") ? "Nom" : "Structure"}
                        fullWidth
                        onChange={(e) => setClient({ ...client, nom: e.target.value })}

                    />

                    {
                        (clientType == "personne") && <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="Prénom"
                            value={client.prenom || ''}

                            label="Prénom"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Prénom'
                            onChange={(e) => setClient({ ...client, prenom: e.target.value })}

                        />

                    }
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="code"
                        label="Code"
                        value={client.code || ''}

                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Code'
                        onChange={(e) => setClient({ ...client, code: e.target.value })}

                    />


                </Box>
                <Box
                    xs={styles.path}
                    width={'300px'}
                >
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}

                    >
                        Contacts du client
                    </Typography>


                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Email"
                        label="Email"
                        InputProps={{ style: { height: '45px' } }}
                        value={client.email || ''}

                        color="tertiary"
                        focused
                        placeholder='Email'
                        onChange={(e) => setClient({ ...client, email: e.target.value })}

                    />
                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Téléphone"
                        label="Téléphone"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Téléphone'
                        value={client.telephone || ''}

                        onChange={(e) => setClient({ ...client, telephone: e.target.value })}

                    />

                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Pays"
                        label="Pays"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Pays'
                        value={client.pays || ''}

                        onChange={(e) => setClient({ ...client, pays: e.target.value })}

                    />

                </Box>
                <Box xs={styles.path} width={'300px'}
                >
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}

                    >
                        Adresse
                    </Typography>
                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Ville"
                        label="Ville"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Ville'
                        value={client.ville || ''}

                        onChange={(e) => setClient({ ...client, ville: e.target.value })}

                    />

                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="compte"
                        label="compte"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Compte'
                        value={client.compte || ''}

                        onChange={(e) => setClient({ ...client, compte: e.target.value })}

                    />



                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Adresse"
                        label="Adresse"
                        InputProps={{ style: { height: '70px' } }}
                        color="tertiary"
                        focused
                        value={client.adresse || ''}

                        multiline={true}
                        onChange={(e) => setClient({ ...client, adresse: e.target.value })}

                    />



                </Box>

            </Stack>

            <Box
                sx={{
                    width: '100%',
                    height: '300px'
                }}
            >





                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                        mt: 4,
                        mb: 4,
                        pb: 5
                    }}

                >


                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={() => navigateTo('/billeterie/portefeuil')}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={addClientHandler}
                        disabled={loading}
                    >
                        {loading ? 'Patientez..' : 'Valider'}
                    </Button>

                </Stack>


            </Box>
        </LocalizationProvider>


    </Box>
}



export default AjoutClient;