
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import FacturePDF from '../../components/facture-pdf/FacturePDF';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { fetchFacture } from '../../data/slices/factureSlice';
import { COLORS } from '../../settings/themeSettings';
import WestIcon from '@mui/icons-material/West';
import { Stack, Typography } from '@mui/material';



const FactureViewer = ({}) => {

    const { id, clientId} = useParams();
    const [facture, setFacture] = useState({});
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);

        useEffect(() => {
            setLoading(true);
            fetchFacture(id) .then((response) => response.json())
            .then(async (facture) => {
               // console.log(resp);
               setLoading(false);
                setFacture(facture);
            })
            .catch(err => {
               setLoading(false);

                console.log(err);
            })
            ;
        }, []);

    return <div>
        <Stack
            direction="row"
            justifyContent="flex-start"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}/details/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, ml: 5, width: '100%' }}>Facture PDF </Typography>

        </Stack>

{
    loading ?
    <div className="loader-container">
                    <span className="loader"></span>
                </div>

                :

              <PDFViewer style={{width: '100%', height: '100vh'}} >
             <FacturePDF facture={facture} /> 

        </PDFViewer>   
}
        

       
{/* 
                <div>
                <PDFDownloadLink document={<Recu />} fileName="somename.pdf">
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                </PDFDownloadLink>
            </div>     */}
    </div>

}



export default FactureViewer;