
class ToClient {
    constructor(libelle, referent, telephoneReferent,courrielReferent , fonctionReferent, code, courriel, telephone, pays, ville, adresse, compte) {

        this.libelle = libelle;
        this.referent = referent;
        this.telephoneReferent = telephoneReferent;
        this.code = code;
        this.courriel = courriel;
        this.courrielReferent = courrielReferent;
        this.telephone = telephone;
        this.pays = pays;
        this.ville = ville;
        this.adresse = adresse;
        this.fonctionReferent = fonctionReferent;
        this.compte = compte;
        
    }
}


export default ToClient;