import { View, Text } from "@react-pdf/renderer";
import { COLORS } from "../../settings/themeSettings";





const DashboardTablePDF = ({ caption, data, title }) => {



    return <View style={{ width: '100%' }}  wrap={false}>
        <Text style={{
            fontSize: 14,
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: COLORS.secondary,
            padding: 5,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {caption}
        </Text>
        {title && <View
        
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            paddingVertical: 10,
            paddingHorizontal: 2
             }}
        >
            <Text
                style={{ width: '30%' }}
            ></Text>
            {title.map((tl) => <Text
                style={{ width: '10%', fontSize: 8 }}
            >{tl}</Text>)}
        </View>}
        <View style={{padding: 5}}>
            {
                data.map((item, idx) => <View key={idx} style={{
                     width: '100%',
                     display: 'flex',
                     flexDirection: 'row',
                     gap: 5
                      }}>
                    <Text
                        style={{ 
                            width: '30%', fontSize: 8, backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5 }}

                    >{item.title}</Text>
                    {Array.isArray(item.value) ? item.value.map((v, key) => <Text
                        style={{ width: '10%', fontSize: 8 , backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5}}

                        key={key} >{v}</Text>) : <Text style={{ width: '100%', fontSize: 8 , backgroundColor: COLORS.gray, paddingHorizontal: 5, paddingVertical: 10, marginBottom: 5}}
                        >{item.value}</Text>}
                </View>

                )
            }
        </View>

    </View>;
}



export default DashboardTablePDF;