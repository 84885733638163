class Agence {
    constructor( nom, adresse) {

        this.nom = nom;
        this.adresse = adresse;

    }
}


export default Agence;