import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import Vehicule from '../../data/schemas/vehicule';
import { createVehicule, deleteVehicule, fetchVehicules, updateVehicule } from '../../data/slices/vehiculeSlice';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const VehiculePage = (props) => {

    const { vehicules, vehiculeFetchStatus } = useSelector((state) => state.vehicules);
    const dispatch = useDispatch();

    const [vehicule, setVehicule] = useState(null); 
    const [open, setOpen] = useState(false);

    const handleClose = ()=>{
        setOpen(false);
    }
    const addVehicule = (data, cb)=> {
        console.log(data);
        setOpen(false);

        if(data.id){


        updateVehicule(data, (err) => {
            console.log(err);
        }, (resp) => {
            cb();
            console.log(resp);
        notify("Le vehicule a été modifié avec succés!");
        dispatch(fetchVehicules());


        });



            return;
        }

        createVehicule(data, (err) => {
            console.log(err);
        }, (resp) => {
            
            console.log(resp);
        notify("Le vehicule a été ajouté avec succés!");
        dispatch(fetchVehicules());

        cb();

        });
    }

    const onVehiculeChanged = (id) => {
        setVehicule(vehicules.find((v)=> v.id === id ));
        setOpen(true);
    }

    const onDelete = (id) =>{
        setOpen(false);

        deleteVehicule(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setVehicule(null);

        notify("Le vehicule a bien été supprimé!");
        dispatch(fetchVehicules());

        })
    }

    useEffect(() => {
        dispatch(fetchVehicules());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
        <div className="header">
                <div className="title">
                    Liste des véhicules
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
                    <StyledTable  
                    rows={[...vehicules].sort((a,b) => sortByProp(a, b, 'modele'))} 
                    head={Object.keys(new Vehicule())} 
                    onOptionClicked={onVehiculeChanged}
                    />

        </div>
        <Dialog onClose={(e) => handleClose(e) }  open={open} >

        <Transform 
        onSubmit={addVehicule} 
        mandatories={["modele", "marque", "matricule"]} 
        entity={new Vehicule()} 
        
        toUpdate={vehicule} 
        onDelete={onDelete}
        onCancel={()=> handleClose()}
        />
                </Dialog>

    </div>

}


export default VehiculePage;