class Hotel {
    constructor(nom, code, telephone, mobile, courriel, adresse, observation) {

        this.nom = nom;
        this.code = code;
        this.telephone = telephone;
        this.mobile = mobile;
        this.courriel = courriel;
        this.adresse = adresse;
        this.observation = observation;



    }
}


export default Hotel;