import { Breadcrumbs, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

import { Link } from "react-router-dom";
import { COLORS } from "../settings/themeSettings";




const DossierPageBase = (props) => {

    let location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const navigateTo = useNavigate();
    


    const handleClick = (e,path) => {
        e.preventDefault();
        console.log(location.pathname.indexOf(path));
        if(location.pathname.indexOf(path) !== 10){
            console.log(location.pathname.split(path)[0]);
            let currentPath = location.pathname.split(path)[0]
            if(isNaN(path)){
                navigateTo(currentPath);

            }else{
                navigateTo(`${currentPath}${path}`);

            }

        }else{
            navigateTo(`/dossiers/${path}`);
        }
        

    }

    useEffect(() => {

        console.log(location);
        let url = 'dossiers';

        let links = location.pathname.split('/').map((path, idx) => {
            url = url + '/' + path;
            if (path !== 'dossiers') {
       
                return <Link underline="hover" key={idx} color="white"  
                style={{color: "#FFF", fontSize: '1em', fontWeight: 'bold', textDecoration:'none'}}
                onClick={(e) => handleClick(e,path)}
                >
                    {path}
                </Link>
            }
        });

        setBreadcrumbs(links);

    }, [location]);

    return <div className="dossier-base">
        <Stack spacing={2} direction="row" alignItems="center" 
        sx={{ height: 50, mt: 2, mb: 2, bgcolor: COLORS.secondary, color: '#FFF', borderRadius: '5px' }}>

            <Breadcrumbs separator=">" aria-label="breadcrumb" color="tertiary">
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
        <Outlet />
    </div>
}


export default DossierPageBase;