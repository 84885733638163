import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import Chauffeur from '../../data/schemas/chauffeur';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChauffeur } from '../../data/slices/chauffeurSlice';
import { createGuide, deleteGuide, fetchGuides, updateGuide } from '../../data/slices/guideSlice';
import Guide from '../../data/schemas/guide';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const GuidePage = (props) => {

    const { guides } = useSelector((state) => state.guides);
    const dispatch = useDispatch();
    const [guide, setGuide] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addGuide = (data, cb) => {
        console.log(data);
        handleClose();
        if (data.id) {



            updateGuide(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
                notify("Le guide a été modifié avec succés!");
                dispatch(fetchGuides());


            });



            return;
        }


        createGuide(data, (err) => {
            console.log(err);
        }, (resp) => {
            notify("Le guide a  été ajouté avec succés!");
            dispatch(fetchGuides());
        });
    }
    const onDelete = (id) => {
        handleClose();

        deleteGuide(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setGuide(null);

            notify("Le guide a bien été supprimé!");
            dispatch(fetchGuides());

        });
    }

    const onGuideSelected = (id) => {
        setGuide(guides.find((g) => g.id === id));
        setOpen(true);
    }

    useEffect(() => {
        dispatch(fetchGuides());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des guides
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...guides].sort((a,b) => sortByProp(a, b, 'nomComplet'))}
                head={Object.keys(new Guide())}
                onOptionClicked={onGuideSelected}


            />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

            <Transform
                onSubmit={addGuide}
                mandatories={["nomComplet", "telephone"]}
                entity={new Guide()}

                toUpdate={guide}
                onDelete={onDelete}
                onCancel={() => handleClose()}

            />
        </Dialog>

    </div>

}


export default GuidePage;