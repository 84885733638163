class Envelop {
    constructor(frais, montant, depouillement) {

        this.frais = frais;
        this.montant = montant;
        this.depouillement = depouillement || 0;

    }
}


export default Envelop;