import React, { useEffect, useReducer, useState } from "react";
import "./detailsFacture.scss";
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { COLORS, dateFormatter, priceFormatter, theme } from "../../settings/themeSettings";
import { MonetizationOnTwoTone, Money, PaymentOutlined, Print, Refresh, Update } from "@mui/icons-material";
import WestIcon from '@mui/icons-material/West';
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import factureSlice, { fetchFacture } from "../../data/slices/factureSlice";
import { BFactureUrl } from "../../settings/APISetting";
import { notify } from "../../settings/toastSetting";

const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}










const DetailsFacture = (props) => {


    const billets = [];
    const payments = [];
    const bills = [];


    const navigateTo = useNavigate();
    const { id, clientId} = useParams();
    const [facture, setFacture] = useState({});
    const [loading, setLoading] = useState(false);


    useEffect( () => {


        setLoading(true);

        fetchFacture(id)
        .then((response) => response.json())
        .then(async (facture) => {
            setLoading(false);
           // console.log(resp);
          // notify("Success","success");

           setFacture(facture);
        })
        .catch(err => {
            setLoading(false);

            console.log(err);
        })
        ;



    }, []);










    return ( 

        loading ?
        <div className="loader-container">
        <span className="loader"></span>
    </div>


        :


    
    <Box
        sx={
            styles.container
        }>

        <Stack
            direction="row"
            justifyContent="space-between"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '1100px' }}>Détails facture </Typography>

        </Stack>


        <Stack

            direction="row"
            justifyContent="space-between"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }


        >




            <Stack sx={{ width: '50%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture
                </Typography>


                <Stack sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{facture.numeroFacture}  </span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                        <span> N° Dossier:</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{facture.numeroDossier} </span>

                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Commentaire: </span>
                        <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                            {facture.commentaire}
                        </span>
                    </Typography>

                </Stack>





            </Stack>

            <Stack
                sx={{ width: '50%' }}
                direction="column"
                justifyContent="center"
                gap={2}
                style={{ padding: '0 20px' }}
            >
                <Button onClick={() => navigateTo(`/billeterie/facture/${clientId}/${id}/pdf`)} color="secondary" variant="outlined" startIcon={<Print />}>
                    Imprimer facture
                </Button>
                <Button
                
                disabled={facture && facture.paiements && facture.paiements.length < 1}
                
                onClick={() => navigateTo(`/billeterie/recu/${clientId}/${id}/pdf`)} color="secondary" variant="outlined" startIcon={<Print />}>
                    Imprimer reçu
                </Button>
                <Button 
                
                disabled={facture && facture.frais && facture.frais.length < 1}
                
                onClick={() => navigateTo(`/billeterie/ticket/${clientId}/cancel/${id}`)} color="secondary" variant="outlined" startIcon={<Refresh />}>
                    Annuler Billet
                </Button>
                <Button 
                                disabled={facture && facture.frais && facture.frais.length < 1}

                onClick={() => navigateTo(`/billeterie/ticket/${clientId}/update/${id}`)} color="secondary" variant="outlined" startIcon={<Update />}>
                    Update Billet
                </Button>
                <Button 

                onClick={() => navigateTo(`/billeterie/facture/${clientId}/update/${id}`)} color="primary" variant="outlined" startIcon={<Update />}>
                    Modifier facture
                </Button>
            </Stack>






        </Stack>

        <Stack
            direction="row"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }
            sx={{ minHeight: '100px' }}


        >


            <Box xs={styles.path} width={'100%'}
            >
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Liste des billets
                </Typography>


                <TableContainer component={Paper} sx={{ mt: '15px' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nom sur le billet</TableCell>
                              <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Trajet </TableCell>
  
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Aller</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Retour</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant TTC </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant FA </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Etat </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                              ( facture && facture.billets) && facture.billets.map((billet, idx) => {
                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                        <TableCell > {billet.numero} </TableCell>
                                        <TableCell > {billet.nom} </TableCell>
                                                   <TableCell > {billet.trajet} </TableCell>

                                        <TableCell > {billet.numeroVol} </TableCell>
                                        <TableCell> {dateFormatter(billet.dateVol)  } </TableCell>
                                        <TableCell > {billet.compagnie} </TableCell>

                                        <TableCell > {billet.numeroVolRetour} </TableCell>
                                        <TableCell> {dateFormatter(billet.dateVolRetour ) } </TableCell>
                                        <TableCell > {billet.compagnieRetour} </TableCell>
                                        <TableCell > {priceFormatter(billet.tarif) } </TableCell>
                                        <TableCell > {priceFormatter(billet.montantAchat) } </TableCell>
                                        
                                        <TableCell > {billet.etat} </TableCell>
                                    </TableRow>

                                })

                            }

                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </Stack>

        <Box
            sx={{
                width: '100%',
                minHeight: '300px'
            }}
        >



            <Stack
                direction="row"
                spacing={2}
                sx={{
                    '& > *': { width: '50%' },
                    height: '100%',
                    mt: 8,
                    padding: '0px 5px',

                }}
                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }
            >

                <Box sx={
                    {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                    }
                }>


                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 450 }} aria-label="simple table" >
                            <TableHead >
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Frais</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en CFA</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en EURO</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                   (facture?.frais) && facture.frais.map((bill) => {
                                        return <TableRow sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                            <TableCell > {bill.libelle} </TableCell>
                                            <TableCell > {priceFormatter(bill.montant) } </TableCell>
                                            <TableCell > {Math.round( bill.montant/656)} € </TableCell>
                                        </TableRow>
                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>





                </Box>
                <Box
                    sx={
                        {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            gap: 10,
                        }
                    }
                >



                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Mode de paiement</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                  (facture?.paiements) && facture.paiements.map((payment, idx) => {
                                        return <TableRow key={idx} sx={{ color: COLORS.secondary, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                            <TableCell> {payment.methode} </TableCell>
                                            <TableCell> {priceFormatter(payment.montant) } </TableCell>
                                            <TableCell> {dateFormatter(payment.date) } </TableCell>
                                        </TableRow>

                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>



                </Box>





            </Stack>



        </Box>


    </Box>
    )
    
}



export default DetailsFacture;