


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GUIDE_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchGuides= createAsyncThunk('fetch-guides', async (page = 1) => {


    const response = await fetch(`${GUIDE_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});
export const createGuide = (guide, err, cb) => {
    fetch(GUIDE_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(guide)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}



export const updateGuide = (guide, err, cb) => {
    fetch(`${GUIDE_URL}/${guide.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(guide)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteGuide = (id, err, cb) => {
    fetch(`${GUIDE_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}




const guideSlice = createSlice({

    name: 'guides',
    initialState: {guides: [], guidesFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGuides.fulfilled, (state, action) => {

            state.guides = [...action.payload].sort((a,b) => sortByProp(a,b, 'nomComplet'));
            state.guidesFetchStatus = 'success';
        }).addCase(fetchGuides.pending, (state, action) => {
            state.guidesFetchStatus = 'loading';

        }).addCase(fetchGuides.rejected, (state, action) => {

            state.guidesFetchStatus = 'error';

        });
    }


});
export default guideSlice;