import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';
import { createUser, deleteUser, fetchUsers, updateUser } from '../../data/slices/userSlice';
import User, { UserCreation } from '../../data/schemas/user';




const UserPage = (props) => {

    const { users, fetchUsersStatus } = useSelector((state) => state.users);
    const dispatch = useDispatch();

    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(false);

    const addUser = (data, clearForm) => {
        console.log(data);
        setOpen(false);

        if (!data.id) {
            createUser(data, (err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);
                clearForm();

                notify("L'utilisateur a été ajouté avec succés!");
                dispatch(fetchUsers());

            });
        } else {

            updateUser(data, (err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);
                clearForm();

                notify("L'utilisateur  a été modifié avec succés!");
                dispatch(fetchUsers());

            });


            console.log({ data });
        }

    }

    const onUserSelected = (id) => {
        setUser(users.find((user) => user.id === id));
        setOpen(true);
    }

    const onDelete = (id) => {
        setOpen(false);
        deleteUser(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setUser(null);

            notify("L'utilisateur a bien été supprimé!");
            dispatch(fetchUsers());

        });
    }

    const handleClose = ()=>{
        setOpen(false);
    }
    useEffect(() => {
        dispatch(fetchUsers());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des utilisateurs
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...users].sort((a,b) => sortByProp(a, b, 'nomComplet'))}
                head={Object.keys(new User())}
                onOptionClicked={onUserSelected} />

        </div>
        <Dialog onClose={(e) => handleClose(e) }  open={open} >
            <Transform onSubmit={addUser}
                mandatories={["nomComplet", "password", "code", "username"]}
                entity={new UserCreation()}
                toUpdate={user}
                onDelete={onDelete}
                onCancel={() => { setOpen(false); setUser(null)}}
            />
        </Dialog>


    </div>

}


export default UserPage;