
import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../assets/logo-aft4.png";
import { COLORS } from '../settings/themeSettings';
import signature from "../assets/signature.png";




const FactureFooter = (props) => {

    return  <View style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: 80,
        padding: 5
    }}>

        <View style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: 2
        }}>


            <Image
                style={{
                    height: 30,
                    width: 100
                }}
                source={logo} />
                  <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>SARL au capital de 5.000.000 FCFA. Licence 019258 par le MTTA</Text>
                  <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>NINEA: 002442424 - RC: SN THS 2014 M 2461</Text>
                <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>Africa for tourism</Text>
                 <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>Saly Tapée, route de ngaparou</Text>
                 <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>Face Pharmacie Salve Regina</Text>
                 <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>Tel: (+221) 77 649-93-22 - (+221) 33 957-81-81</Text>
                  <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>contact@africa4tourism.com</Text>
                  <Text style={{
                    color: COLORS.secondary,
                    fontSize: 8,
                    paddingLeft: 6

                }}>www.africa4tourism.com</Text>
               
        </View>



        <Image
            style={{
                height: 90,
                width: 160
            }}
            source={signature} />
    </View>
}

export default FactureFooter;