
import { createTheme } from '@mui/material';
import alegreya from '../fonts/Alegreya/alegreya_regular.ttf'
import { read, utils, writeFile } from 'xlsx';

import FrenchNumbersToWords from "french-numbers-to-words";


export const COLORS = {
  primary: "#FFBA00",
  secondary: "#074E71",
  tertiary: "#857F92",
  gray: "#DEE2E6"
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Alegreya',
  },
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    tertiary: {
      main: COLORS.tertiary
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Alegreya';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Alegreya'), url(${alegreya}) format('tff');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
    },
  }
});
export const priceFormatter = (price) => {
  return (new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).formatToParts(
    isNaN(price) ? 0 : price))
    .map((part) => {
      if (part.type == 'currency') {
        part.value = "FCFA";
      }
      if (!(part.type == 'group' || part.type == "literal" || part.type == "")) {
        return part.value;
      }
    }).join(" ");
}
export const priceFormatterV2 = (price) => {
  return (new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).formatToParts(
    isNaN(price) ? 0 : price))
    .map((part) => {
      if (part.type == 'currency') {
        part.value = "";
      }
      if (!(part.type == 'group' || part.type == "literal" || part.type == "")) {
        return part.value;
      }
    }).join(" ");

}

export const nameFormatter = (name) => {
  const underscoreRegex = new RegExp("_");
  const numeroRegex = new RegExp("numero");
  return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
}


export const sortByProp = (a, b, prop) => {
  if (!a[prop] || !b[prop]) return 0;
  if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
    return -1;
  }
  if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
    return 1;
  }
  return 0;
}

const isValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (!isNaN(date.getTime())) {

      return true
    } else {
      return false;
    }
  } else {

    return false;
  }
}


export const dateFormatter = (str) => {
  if (!str) return;
  const d = new Date(str);


  return isValidDate(d) ? new Intl.DateTimeFormat('fr-FR', { locale: 'fr-FR', day: 'numeric', month: 'short', year: 'numeric' }).format(d) : '';
}


export const jsonToXlsx = (json, title) => {
  const worksheet = utils.json_to_sheet(json);
  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet, title);
  writeFile(workbook, `${title}.xlsx`, { compression: true });

}


export const calculateDays = (date, dateFin, cb) => {

  if (!date || !dateFin) {
    return 0;
  }

  // To calculate the time difference of two dates 
  const Difference_In_Time = (new Date(dateFin)).getTime() - (new Date(date)).getTime();

  // To calculate the no. of days between two dates 
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const result = (Difference_In_Days > 0) ? Difference_In_Days : 0;
  cb && cb();
  return result;


}

export const toEuro = (price) => (new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR'

})
  .format(isNaN(price) ? 0 : (price / (655.957)))
  .replaceAll(",", " ")
  .replaceAll(".", ",")
  .split("€")
  .reverse()
  .join("") + " €"



);



// const nameFormatter = (name) => {
//   const underscoreRegex = new RegExp("_");
//   const numeroRegex = new RegExp("numero");
//   return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
// }

export const calculateFrais = (frais) => {

  return frais.reduce((sum, current) => {


    return {
      montant: sum.montant + (current.quantite * current.prixUnitaire)
    }

  }, {
    montant: 0
  }).montant;

}


export const toFrench = (arg) => {
  let result = new FrenchNumbersToWords(arg, 'fr').result
  console.log({ result });


  return result.parts ? result.parts.filter((p) => p.number > 0).map((p) => `${p.text} ${p.unit}`).join(' ') + ' de francs CFA' : '';
}



export const arrayFilter = (nail, array) => [...array].filter((item) => {
  for (let prop in item) {
    if (nail.test(item[prop])) {
      return true;
    }

  }
  return false;
});


