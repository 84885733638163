
import { useNavigate, useParams } from 'react-router';
import './AjoutCircuitPage.scss';

import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { COLORS, priceFormatter, toEuro } from '../../settings/themeSettings';
import { DateField, LocalizationProvider, TimeField, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import EditableTable from '../../components/editableTable/EditableTable';
import Circuit from '../../data/schemas/circuit';
import Person from '../../data/schemas/person';
import Excursion from '../../data/schemas/excursion';
import Transfert from '../../data/schemas/transfert';
import Location from '../../data/schemas/location';
import Frais from '../../data/schemas/frais'
import Group from '../../data/schemas/group';
import Envelop from '../../data/schemas/envelop';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChauffeur } from '../../data/slices/chauffeurSlice';
import { fetchGuides } from '../../data/slices/guideSlice';
import { fetchVehicules } from '../../data/slices/vehiculeSlice';
import { fetchHotels } from '../../data/slices/hotelSlice';
import { fetchAgences } from '../../data/slices/agenceSlice';
import { fetchTypeExcursion } from '../../data/slices/typeExcursionSlice';
import DossierCircuit from '../../data/schemas/dossierCircuit';
import { createCircuit, fetchCircuit } from '../../data/slices/circuitSlice';
import { notify } from '../../settings/toastSetting';
import { fetchTypeFrais } from '../../data/slices/typeFraisSlice';
import { fetchProgrammes } from '../../data/slices/programmeSlice';
import { fetchToClient } from '../../data/slices/toClientSlice';
import dayjs from 'dayjs';
import Sejour from '../../data/schemas/sejour';



const styles = {
    container: {
        transition: '1s',
        minWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}



const ListFrais = ({ frais = [], onFraisUpdate }) => {

    return <div>
        <EditableTable data={frais} reference={new Frais()} onUpdate={onFraisUpdate} />
    </div>
};


const Enveloppe = ({ enveloppes = [], onEnvelopUpdate }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTypeFrais());
    }, []);


    return <div>
        <EditableTable data={enveloppes} reference={new Envelop()} onUpdate={onEnvelopUpdate} 
       // choices={{ frais: { options: typeFrais, optionLabel: 'libelle', optionValue: 'libelle' } }}
         />
    </div>
};



const Locations = ({ locations = [], onLocationUpdate }) => {
    const { chauffeurs, chauffeursFetchStatus } = useSelector((state) => state.chauffeurs);
    const { guides, guidesFetchStatus } = useSelector((state) => state.guides);
    const { vehicules, vehiculeFetchStatus } = useSelector((state) => state.vehicules);
    const { agences, agencesFetchStatus } = useSelector((state) => state.agences);


    return <div>
        <EditableTable data={locations}
            onUpdate={onLocationUpdate}
            reference={new Location()}
            operations={{ montant: { labels: ['nombreJour', 'prixUnitaire'], type: '*' }, nombreJour: { labels: ['dateDebut', 'dateFin'], type: 'daydiff+' } }}
            choices={{
                chauffeur: { options: chauffeurs, optionLabel: 'nomComplet', optionValue: 'id' },
                guide: { options: guides, optionLabel: 'nomComplet', optionValue: 'id' },
                vehicule: { options: vehicules, optionLabel: 'modele', optionValue: 'id' },
                agence: { options: agences, optionLabel: 'nom', optionValue: 'id' },
            }} />
    </div>
};



const Transferts = ({ transferts = [], onTransfertUpdate }) => {
    const { chauffeurs, chauffeursFetchStatus } = useSelector((state) => state.chauffeurs);
    const { guides, guidesFetchStatus } = useSelector((state) => state.guides);

    const { vehicules, vehiculeFetchStatus } = useSelector((state) => state.vehicules);

    return <div>
        <EditableTable onUpdate={onTransfertUpdate} data={transferts}
            reference={new Transfert()}
            choices={{
                chauffeur: { options: chauffeurs, optionLabel: 'nomComplet', optionValue: 'id' },
                guide: { options: guides, optionLabel: 'nomComplet', optionValue: 'id' },
                vehicule: { options: vehicules, optionLabel: 'modele', optionValue: 'id' }
            }
            }
            operations={{ montant: { labels: ['pax', 'prixUnitaire'], type: '*' } }}

        />
    </div>
};



const Excursions = ({ excursions = [], onExcursionUpdate }) => {



    const { chauffeurs, chauffeursFetchStatus } = useSelector((state) => state.chauffeurs);
    const { guides, guidesFetchStatus } = useSelector((state) => state.guides);
    const { typeExcursions, typeExcursionsFetchStatus } = useSelector((state) => state.typeExcursions);



    return <div>
        <EditableTable reference={new Excursion()}
            data={excursions}
            onUpdate={onExcursionUpdate}
            choices={{
                chauffeur: { options: chauffeurs, optionLabel: 'nomComplet', optionValue: 'id' },
                guide: { options: guides, optionLabel: 'nomComplet', optionValue: 'id' },
                excursion: { options: typeExcursions, optionLabel: 'libelle', optionValue: 'id' }
            }}
            operations={{ montant: { labels: ['pax', 'prixUnitaire'], type: '*' } }}


        />

    </div>
};


const Rooming = ({ type = false, rooming = [], onRoomingUpdate, onRoomingTypeChanged }) => {

    const [grouped, setGrouped] = useState(type);

    return <div>

        <div className='rooming'>
            <span>Rooming group ?</span>
            <input type="checkbox"
                value={grouped}
                onChange={(e) => {
                    onRoomingUpdate([]);

                    setGrouped(!grouped);
                    onRoomingTypeChanged(!grouped);

                }}


            />
        </div>

        {
            grouped ?
                <EditableTable key={1} reference={new Group()} data={rooming} onUpdate={onRoomingUpdate} />

                :
                <EditableTable key={2} reference={new Person()} data={rooming} choices={{ civilite: ['Mr', 'Mme'] }} onUpdate={onRoomingUpdate} />


        }

    </div>
};



const Circuits = ({ circuits = [], onCircuitUpdate, type = 'circuit' }) => {

    const { hotels } = useSelector((state) => state.hotels);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchHotels());

    }, []);



    return <div>
        <EditableTable
            reference={new Sejour()}
            data={circuits}
            choices={{ hotel: { options: hotels, optionLabel: 'nom', optionValue: 'id' } }}
            operations={{ montant: { labels: ['pax', 'prixUnitaire', 'nuitee'], type: '*' }, nuitee: { labels: ['dateDebut', 'dateFin'], type: 'daydiff' } }}

            onUpdate={onCircuitUpdate} />

    </div>
};



const AjoutCircuitPage = (props) => {

    const { type, id } = useParams();


    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);

    const [circuit, setCircuit] = useState(new DossierCircuit());


    const [tab, setTab] = useState(null);
    const [tabs, setTabs] = useState([]);

    const [circuits, setCircuits] = useState([]);
    const [rooming, setRooming] = useState([]);
    const [excursions, setExcursions] = useState([]);
    const [transferts, setTransferts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [envelops, setEnvelops] = useState([]);
    const [montant, setMontant] = useState(0);



    const [frais, setFrais] = useState([
        { frais: "HEBERGEMENT", montant: 0 },
        { frais: "LOCATION", montant: 0 },
        { frais: "EXCURSION", montant: 0 },
        { frais: "TRANSFERT", montant: 0 },
        { frais: "DEPOUILLEMENT", montant: 0 }
    ]);

    const onCircuitUpdate = _ => setCircuits(_);
    const onRoomingUpdate = _ => setRooming(_);
    const onExcursionUpdate = _ => setExcursions(_);
    const onTransfertUpdate = _ => setTransferts(_);
    const onLocationUpdate = _ => setLocations(_);
    const onEnvelopUpdate = _ => setEnvelops(_);
    const onFraisUpdate = _ => setFrais(_);



    const { chauffeurs, chauffeursFetchStatus } = useSelector((state) => state.chauffeurs);
    const { guides, guidesFetchStatus } = useSelector((state) => state.guides);
    const { vehicules, vehiculeFetchStatus } = useSelector((state) => state.vehicules);
    const { toClients } = useSelector((state) => state.toClients);
    const { programmes } = useSelector((state) => state.programmes);


    const dispatch = useDispatch();



    useEffect(() => {
        switch (type) {
            case 'circuit': {
                setTabs(['Hebergements', 'Rooming', 'Excursions', 'Transferts', 'Locations', 'Enveloppe', 'Frais']);

            } break;

            case 'sejour': {
                setTabs(['Hebergements', 'Rooming', 'Excursions', 'Transferts', 'Locations', 'Enveloppe', 'Frais']);

            } break;

            case 'excursion': {
                setTabs(['Excursions', 'Rooming', 'Enveloppe', 'Frais']);

            } break;

            case 'transfert': {
                setTabs(['Transferts', 'Frais']);

            } break;

        }



        dispatch(fetchChauffeur());
        dispatch(fetchGuides());
        dispatch(fetchVehicules());
        dispatch(fetchAgences());
        dispatch(fetchTypeExcursion());
        dispatch(fetchTypeFrais());
        dispatch(fetchProgrammes());
        dispatch(fetchToClient());


        setCircuit({
            ...circuit,
            date: dayjs(new Date()),
            dateVolArrivee: dayjs(new Date()),
            dateVolDepart: dayjs(new Date()),
            heureVolArrivee: dayjs(new Date()),
            heureVolDepart: dayjs(new Date())


        });

        if (id) {
            fetchCircuit(id).then((resp) => {
                return resp.json();
            }).then(async (dossier) => {


                console.log(dossier);
                setCircuit({...dossier, grouped: dossier.isGroup});
          
                
                await dossier.rooming.map((r) => {
                    delete r.id;
                    return r;
                });




                setExcursions(dossier.excursions.map((e) => {
                  
                    return new Excursion(e.date, e.heure, e.type.id, e.observation, e.chauffeur?.id, e.guide?.id, e.pax, e.prixUnitaire, e.montant);
                }));
                setTransferts(
                    dossier.transferts.map((t) => {
                  
                        return new Transfert(t.date, t.heure, t.lieuDepart, t.lieuArrivee, t.vehicule?.id,t.chauffeur?.id, t.guide?.id, t.pax, t.prixUnitaire, t.montant);
                    })
                );

                setLocations(
                    dossier.locations.map((t) => {
                  
                        return new Location( t.agence?.id,t.dateDebut, t.dateFin, t.nombreJour,t.prixUnitaire,t.vehicule?.id,t.chauffeur?.id,  t.guide?.id,   t.montant);
                    })
                );

                setRooming(dossier.rooming);
                setCircuits(dossier.hotels.map((h) => {

                    return new Sejour(h.dateDebut, h.dateFin, h.nuitee, h.hotel?.id, h.prixUnitaire, h.typeChambre, h.regime, h.pax, h.montant, h.categorieChambre);
                }));

                setEnvelops(dossier.envelops);
            }).catch((err) => console.log(err));
        }


    }, []);

    useEffect(() => {
        setTab(tabs[0]);
    }, [tabs]);

    const addCircuit = () => {


        //    setCircuit({...circuit,hotels: circuits, excursions: excursions, transferts: transferts, locations: locations, envelops: envelops,frais :frais, rooming: rooming })

        setLoading(true);

        console.log({
            ...circuit,
            hotels: circuits,
            excursions: excursions,
            transferts: transferts,
            locations: locations,
            envelops: envelops,
            frais: frais,
            rooming: rooming,
            dateVolArrivee: !!circuit.dateVolArrivee ? circuit.dateVolArrivee : '',
            dateVolDepart: !!circuit.dateVolDepart ? circuit.dateVolDepart : '',
            heureVolArrivee: !!circuit.heureVolArrivee ? circuit.heureVolArrivee : '',
            heureVolDepart: !!circuit.heureVolDepart ? circuit.heureVolDepart : '',
            type: type
        });  

        createCircuit({
            ...circuit,
            hotels: circuits,
            excursions: excursions,
            transferts: transferts.map((t) => ({...t,depart : t.lieuDepart, arrivee: t.lieuArrivee})),
            locations: locations,
            envelops: envelops,
            frais: frais,
            rooming: rooming,
            dateVolArrivee: !!circuit.dateVolArrivee ? circuit.dateVolArrivee : '',
            dateVolDepart: !!circuit.dateVolDepart ? circuit.dateVolDepart : '',
            heureVolArrivee: !!circuit.heureVolArrivee ? circuit.heureVolArrivee : '',
            heureVolDepart: !!circuit.heureVolDepart ? circuit.heureVolDepart : '',
            type: type
        },
            (err) => {
                setLoading(false);


                err.json().then((error) => {
                    console.log(error);
                    if (error.error) {
                        notify(error.error, 'error', 'TOP_RIGHT');

                        return;
                    }
                    notify("Une erreur s'est produite actualisez puis réessayer!", 'error', 'TOP_RIGHT');

                }).catch((e) => {
                    notify("Une erreur s'est produite actualisez puis réessayer!", 'error', 'TOP_RIGHT');

                });

            },
            (resp) => {
                console.log(resp);
                setLoading(false);

                notify("Le dossier a été ajouté avec succés!", 'success', 'TOP_RIGHT');
                navigateTo(`/dossiers/${type}/` + resp.id);
            });



    }


    useEffect(() => {

        console.log(type);

        let circuitFrais = {
            frais: "HEBERGEMENT",
            montant: circuits.reduce((sum, current) => {
                let next = { montant: sum.montant + (current.prixUnitaire * current.pax * current.nuitee) };
                return next;
            }, { montant: 0 }).montant
        };
        let excursionsFrais = {
            frais: "EXCURSION",
            montant: excursions.reduce((sum, current) => {
                let next = { montant: sum.montant + (current.prixUnitaire * current.pax) };
                return next;
            }, { montant: 0 }).montant
        };

        let locationFrais = {
            frais: "LOCATION",
            montant: locations.reduce((sum, current) => {
                let next = { montant: sum.montant + (current.prixUnitaire * current.nombreJour) };
                return next;
            }, { montant: 0 }).montant
        };

        let transfertFrais = {
            frais: "TRANSFERT",
            montant: transferts.reduce((sum, current) => {
                let next = { montant: sum.montant + (current.prixUnitaire * current.pax) };
                return next;
            }, { montant: 0 }).montant
        };





        let depouillementFrais = {
            frais: "DEPOUILLEMENT",
            montant:  envelops.reduce((sum, current) => {
                let next = { montant: sum.montant + Number(current.depouillement)  };
                return next;
            }, { montant: 0 }).montant
        };








        setFrais(frais.map((frai) => {
            if (frai.frais === "HEBERGEMENT") {
                frai = circuitFrais;
            }
            if (frai.frais === "TRANSFERT") {
                frai = transfertFrais;
            }
            if (frai.frais === "LOCATION") {
                frai = locationFrais;
            }
            if (frai.frais === "EXCURSION") {
                frai = excursionsFrais;
            }
            if (frai.frais === "DEPOUILLEMENT") {
                frai = depouillementFrais;
            }


            return frai;
        }));








    }, [locations, transferts, excursions, circuits, envelops]);



    useEffect(() => {


        let _ = (() => frais.reduce((sum, current) => ({ montant: isNaN(sum.montant + current.montant) ? 0 : Number(sum.montant) + Number(current.montant) }), { montant: 0 }).montant)();

        setMontant(_);

    }, [frais]);


    return <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
{(!!id && !circuit.id) ?

       <div className="loader-container">
            <span className="loader"></span>
        </div>
:
        <Box
            sx={
                styles.container
            }>
            <div className="ajout-circuit">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"

                >
                    <div onClick={() => navigateTo(`/dossiers/${type}`)}
                        style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                        <WestIcon style={{ fill: 'white', fontSize: 28 }} />
                    </div>
                    <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, ml: 5, width: '100%' }}>{type != 'excursion' ? `Nouveau ${type}` : `Nouvelle ${type}`} </Typography>

                </Stack>



                <Stack

                    direction="row"
                    justifyContent="space-between"

                    divider={
                        <Box
                            component="hr"
                            sx={{
                                border: `1px solid ${COLORS.tertiary}`,
                            }}
                        />
                    }

                    sx={{
                        height: '375px'
                    }}

                >




                    <Stack

                        sx={{ width: `${25}%` }}



                    >
                        <Typography
                            variant="p"
                            sx={styles.pathtitle}
                        >
                            Détails {type}
                        </Typography>


                        {(type === 'transfert') && <TextField
                            helperText="Ce champ est obligatoire"
                            margin="normal"
                            required
                            fullWidth
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Nom client'
                            onChange={(e) => setCircuit({ ...circuit, nomClient: e.target.value })}
                            defaultValue={circuit?.nomClient}

                        />}


                        {(type == 'sejour' || type == 'circuit') && <Autocomplete

                            // onInputChange={(event, newInputValue) => {
                            //     setCircuit({ ...circuit, programme: newInputValue });
                            // }}
                            options={programmes}
                            getOptionLabel={(option) => option.libelle}
                            onChange={(event, option) => {
                                setCircuit({ ...circuit, programme: option?.id });
                            }}
                            sx={{ height: '45px', mt: '15px', mb: '25px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Programmes" helperText="Ce champ est obligatoire" />}
                            defaultValue={circuit?.programme}
                        //  clearIcon={true}

                        />}


                        <Autocomplete
                            // defaultValue={props.ticket && props.ticket.compagnieRetour}
                            // inputValue={compagnieRetour}
                            // onInputChange={(event, newInputValue) => {
                            //     setCircuit({ ...circuit, toClient: newInputValue });
                            // }}
                            helperText="Ce champ est obligatoire"

                            required
                            onChange={(event, option) => {
                                setCircuit({ ...circuit, toClient: option?.code });
                            }}

                            options={toClients}
                            getOptionLabel={(option) => option.libelle}

                            sx={{ height: '45px', mt: '15px', mb: '25px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="T.O/Clients" helperText="Ce champ est obligatoire"/>}
                            // clearIcon={true}

                           defaultValue={circuit?.toClient}

                        />
                        <div style={{ height: '15px' }}></div>
                        <DateField
                            label="Date dossier"

                            color="tertiary"
                            focused
                            fullWidth
                            sx={{ mt: 2.5 }}
                            InputProps={{ style: { height: '45px' } }}
                            format="DD-MM-YYYY"
                            onChange={(e) => setCircuit({ ...circuit, date: e })}
                            defaultValue={circuit.date && dayjs(new Date( circuit.date))}
                            helperText="Ce champ est obligatoire"
                            required

                        />

                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            helperText="Ce champ est obligatoire"

                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Nombre de pax'
                            type='number'
                            onChange={(e) => setCircuit({ ...circuit, pax: e.target.value })}
                            defaultValue={circuit.pax}
                        />




                    </Stack>

                    <Stack
                        sx={{ width: `${25}%` }}
                    >

                        <Typography
                            variant="p"
                            sx={styles.pathtitle}
                        >
                            Détails service
                        </Typography>

                        <Autocomplete
                            // inputValue={compagnieRetour}
                            onInputChange={(event, newInputValue) => {
                                setCircuit({ ...circuit, methodePaiement: newInputValue });
                            }}
                            options={['Carte de crédit', "Espèce", 'Chéque', "Autre"]}
                            
                            sx={{ height: '45px', mt: '15px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Conditions de paiements" />}
                            defaultValue={circuit.methodePaiement}
                        //  clearIcon={true}

                        />

                        <Autocomplete
                            //   onInputChange={(event, newInputValue) => {
                            //       setCompagnieRetour(newInputValue);
                            //   }}
                            options={chauffeurs}
                            onChange={(event, option) => {
                                setCircuit({ ...circuit, chauffeur: option?.id });
                            }}
                            sx={{ height: '45px', mt: '15px' }}
                            color="tertiary"

                            getOptionLabel={(option) => option?.nomComplet}
                            renderInput={(params) => <TextField {...params} label="Chauffeur" />}
                            value={circuit?.chauffeur}

                        />
                        <Autocomplete
                            // defaultValue={props.ticket && props.ticket.compagnieRetour}
                            // inputValue={compagnieRetour}
                            //   onInputChange={(event, newInputValue) => {
                            //       setCompagnieRetour(newInputValue);
                            //   }}
                            onChange={(event, option) => {
                                setCircuit({ ...circuit, vehicule: option?.id });
                            }}
                            options={vehicules}
                            sx={{ height: '45px', mt: '15px' }}
                            color="tertiary"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option?.modele + ' - ' + (option?.matricule ?? '')}
                            renderInput={(params) => <TextField {...params} label="Véhicule" />}
                            value={circuit?.vehicule}

                        />



                        <Autocomplete
                            // defaultValue={props.ticket && props.ticket.compagnieRetour}
                            onChange={(event, option) => {
                                setCircuit({ ...circuit, guide: option?.id });
                            }}
                            options={guides}
                            getOptionLabel={(option) => option?.nomComplet}

                            sx={{ height: '45px', mt: '15px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Guide" />}
                            value={circuit?.guide}
                        // clearIcon={true}

                        />



                    </Stack>


                    <Stack
                        sx={{ width: `${45}%` }}

                    >
                        <Typography
                            variant="p"
                            sx={styles.pathtitle}
                        >
                            Informations complémentaires
                        </Typography>

                        {(type == 'circuit' || type == 'sejour') && <>

                            <Stack
                                direction='row'
                                gap={2}
                            >
                                <DateField
                                    label="Date de vol Arrivée"
                                    color="secondary"
                                    focused
                                    fullWidth
                                    sx={{ mt: 2.5 }}
                                    InputProps={{ style: { height: '45px' } }}
                                    format="DD-MM-YYYY"
                                    onChange={(e) => setCircuit({ ...circuit, dateVolArrivee: e })}
                                    defaultValue={circuit.dateVolArrivee && dayjs(new Date(circuit.dateVolArrivee))  }

                                />

                                <TimeField
                                    label="Heure de vol"
                                    color="secondary"
                                    focused
                                    fullWidth
                                    sx={{ mt: 2.5 }}
                                    InputProps={{ style: { height: '45px' } }}
                                    onChange={(e) => setCircuit({ ...circuit, heureVolArrivee: e })}
                                    defaultValue={circuit.heureVolArrivee && dayjs(new Date(circuit.heureVolArrivee)) }
                                    format="HH:mm"


                                />

                                <TextField

                                    margin="normal"
                                    required
                                    fullWidth

                                    InputProps={{ style: { height: '45px' } }}
                                    color="tertiary"
                                    focused
                                    placeholder='N° vol'
                                    onChange={(e) => setCircuit({ ...circuit, numeroVolArrivee: e.target.value })}
                                    defaultValue={circuit?.numeroVolArrivee}

                                />


                            </Stack>

                            <Stack
                                direction='row'
                                gap={2}
                            >
                                <DateField
                                    label="Date de vol Départ"
                                    color="secondary"
                                    focused
                                    fullWidth
                                    sx={{ mt: 2.5 }}
                                    InputProps={{ style: { height: '45px' } }}
                                    format="DD-MM-YYYY"

                                    onChange={(e) => setCircuit({ ...circuit, dateVolDepart: e })}
                                   defaultValue={ circuit.dateVolDepart && dayjs(new Date(circuit.dateVolDepart)) }

                                />

                                <TimeField
                                    label="Heure de vol"
                                    color="secondary"
                                    focused
                                    fullWidth
                                    sx={{ mt: 2.5 }}
                                    InputProps={{ style: { height: '45px' } }}
                                    onChange={(e) => setCircuit({ ...circuit, heureVolDepart: e })}
                                  defaultValue={circuit.heureVolDepart && dayjs(new Date( circuit.heureVolDepart))}
                                    format="HH:mm"

                                />

                                <TextField

                                    margin="normal"
                                    required
                                    fullWidth

                                    InputProps={{ style: { height: '45px' } }}
                                    color="tertiary"
                                    focused
                                    placeholder='N° vol'
                                    onChange={(e) => setCircuit({ ...circuit, numeroVolDepart: e.target.value })}
                                    defaultValue={circuit?.numeroVolDepart}

                                />


                            </Stack>
                        </>
                        }




                        <TextField
                            margin="normal"
                            fullWidth
                            name="Commentaire"
                            label="Commentaire"
                            InputProps={{ style: { height: '70px' } }}
                            color="tertiary"
                            focused
                            multiline={true}
                            onChange={(e) => setCircuit({ ...circuit, commentaire: e.target.value })}
                            defaultValue={circuit?.commentaire}
                        />

                    </Stack>






                </Stack>

                <div className="tabs">


                    {
                        tabs.map((_tab, idx) => <div className={(tab == _tab) ? "tab active" : "tab"} onClick={() => setTab(_tab)} key={idx}>
                            {_tab}
                        </div>)
                    }


                </div>
                {

                    (() => {
                        let vue;

                        switch (true) {

                            case tab == 'Hebergements': vue = <Circuits circuits={circuits} onCircuitUpdate={onCircuitUpdate} type={type} />; break;
                            case tab == 'Rooming': vue = <Rooming rooming={rooming} onRoomingUpdate={onRoomingUpdate} onRoomingTypeChanged={(type) => setCircuit({ ...circuit, grouped: type })} />; break;
                            case tab == 'Excursions': vue = <Excursions excursions={excursions} onExcursionUpdate={onExcursionUpdate} />; break;
                            case tab == 'Transferts': vue = <Transferts transferts={transferts} onTransfertUpdate={onTransfertUpdate} />; break;
                            case tab == 'Locations': vue = <Locations locations={locations} onLocationUpdate={onLocationUpdate} />; break;
                            case tab == 'Enveloppe': vue = <Enveloppe enveloppes={envelops} onEnvelopUpdate={onEnvelopUpdate} />; break;
                            case tab == 'Frais': vue = <ListFrais frais={frais} onFraisUpdate={onFraisUpdate} />; break;
                        }


                        return vue;
                    })()


                }



                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={4}
                    sx={{
                        mt: 4,
                        mb: 4,
                        pb: 5
                    }}

                    divider={
                        <Box
                            component="hr"
                            sx={{
                                border: `1px solid ${COLORS.tertiary}`,
                                width: '0px !important'
                            }}
                        />
                    }

                >


                    <div className="total">
                        <div className="title">
                            Env. guide:
                            <div className="underline"></div>
                        </div>
                        <div className="price">
                            {
                                (() => {
                                    let p = envelops.reduce((sum, current) => ({ montant: isNaN(sum.montant + current.montant) ? 0 : Number(sum.montant) + Number(current.montant) }), { montant: 0 }).montant;
                                    return !isNaN(p) ? priceFormatter(p) : priceFormatter(0);

                                })()
                            }
                        </div>


                    </div>

                    <div className="total">
                        <div className="title">
                            Total débours:
                            <div className="underline"></div>
                        </div>
                        <div className="price">
                            {
                                (() => {
                                    let p = envelops.reduce((sum, current) => ({ depouillement: isNaN(sum.depouillement + current.depouillement) ? 0 : Number(sum.depouillement) + Number(current.depouillement) }), { depouillement: 0 }).depouillement;
                                    return !isNaN(p) ? priceFormatter(p) : priceFormatter(0);
                                })()
                            }
                        </div>


                    </div>


                    <div className="total">
                        <div className="title">
                            Montant en CFA:
                            <div className="underline"></div>
                        </div>
                        <div className="price">
                            {priceFormatter(montant)}
                        </div>


                    </div>

                    <div className="total">
                        <div className="title">
                            Montant en Euro:
                            <div className="underline"></div>
                        </div>
                        <div className="price">
                            {toEuro(montant)}
                        </div>


                    </div>
          
        


                </Stack>







                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                        mt: 4,
                        mb: 4,
                        pb: 5
                    }}

                >


                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={() => navigateTo(`/dossiers/${type}`)}

                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={addCircuit}
                    >
                        {loading ? 'Patientez..' : 'Enregistrer'}
                    </Button>

                </Stack>
            </div>
        </Box>}

        
    </LocalizationProvider>

        ;
}



export default AjoutCircuitPage;