import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REGISTER_USER_URL, USERS_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchUsers = createAsyncThunk('fetch-users', async () => {


    const response = await fetch(`${USERS_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});


export const createUser = (user, err, cb) => {
    fetch(REGISTER_USER_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp.json();
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}

export const updateUser= (user, err, cb) => {
    fetch(`${REGISTER_USER_URL}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp.json();
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteUser = (id, err, cb) => {
    fetch(`${USERS_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp.json();
        }
        cb(resp);

    })
        .catch(err);
}


const userSlice = createSlice({

    name: 'users',
    initialState: {users: [], fetchUsersStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, action) => {

            state.users = [...action.payload].sort((a,b) => sortByProp(a,b, 'nomComplet'));
            state.fetchUsersStatus = 'success';
        }).addCase(fetchUsers.pending, (state, action) => {
            state.fetchUsersStatus = 'loading';

        }).addCase(fetchUsers.rejected, (state, action) => {

            state.fetchUsersStatus = 'error';

        });
    }


});
export default userSlice;