class Person {
    constructor(civilite, nom, prenom) {

        this.civilite = civilite;
        this.nom = nom;
        this.prenom = prenom;

    }
}


export default Person;