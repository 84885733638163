import styled from "@emotion/styled";
import { useState } from "react";
import { COLORS, dateFormatter, nameFormatter, priceFormatter } from "../../settings/themeSettings";
import "./styledTable.scss";
import {
    TablePagination,
    tablePaginationClasses as classes,
  } from '@mui/base/TablePagination';
import { useNavigate } from "react-router";

import _ from "lodash";



const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
  };




const Root = styled('div')(
    ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 6px;
      color : ${COLORS.secondary};
      text-transform : capitalize;

      &.toleft {
        text-align: right;

      }
    }
  

    th {
      background-color: ${COLORS.secondary};
      color: ${theme.palette.common.white};

    }
    `,
  );
  
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
      color: ${COLORS.secondary};

    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      color: ${COLORS.secondary};

  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
        color: ${COLORS.secondary};

      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${COLORS.secondary};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
      color: ${COLORS.secondary};

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: transparent;
      color: ${COLORS.secondary};
      & > * {
        color: ${COLORS.secondary};

      }

      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
  );
  



const UpdatedTable = ({head = [], rows = [], totals = []}) => {

    const [page, setPage] = useState(0);
    const navigateTo = useNavigate();


      
    // Avoid a layout jump when reaching the last page with empty rows.
   

  

 return    <Root sx={{ width: 500, maxWidth: '100%' }}>
    <table aria-label="custom pagination table">
      <thead>
        <tr>
            {
                head.map((title, idx) => <th key={idx}> {nameFormatter(title)} </th> )
            }
        </tr>
      </thead>
      <tbody>
        {rows.map((row,idx) => (
          <tr key={idx} >
            {
                head.map((title, titleIdx) =><td 
                key={titleIdx}
                className={/montant|prix|marge|depouillement|tarif/.test(title) && 'toleft'}
                
                
                style={{ minWidth: 120, width: 150 }} align="left">
                        {/montant|prix|tarif|depouillement|marge$|CFA$/.test(title)? priceFormatter(row[title]):( (/date/.test(title))? dateFormatter(row[title]) : row[title] )}
                 </td>)
            }
            
     
      
       
          </tr>
        ))}


{  totals.length > 0 &&     <tr style={{color: 'white', background: COLORS.secondary}}>

        {
          head.map((h) => <td style={{color: 'white', background: COLORS.secondary, textAlign: 'right'}}>  
                {
                  totals.includes(h) ? priceFormatter(_.sumBy(rows, h))  : ''
                }
             </td>)
        }
        </tr>}


  
      </tbody>
 
    </table>
  </Root>
}


export default UpdatedTable;