
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Recu from '../../components/recu-pdf/Recu';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Stack, Typography } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { COLORS } from '../../settings/themeSettings';
import { fetchCircuit } from '../../data/slices/circuitSlice';
import DossierPDF from '../../components/dossierPDF/DossierPDF';



const DossierViewer = (props) => {
    const [dossier, setDossier] = useState({});
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id, type } = useParams();



    useEffect(()=> {



        setLoading(true);

        fetchCircuit(id)
            .then((response) => {

                if (response.status > 201) {
                    throw response;
                }

                return response.json();
            })
            .then(async (resp) => {
                setLoading(false);
                console.log(resp);
                setDossier(resp);

            });


    }, []);


   return  <div>
        <Stack
            direction="row"
            justifyContent="flex-start"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() =>    navigateTo(`/dossiers/${type}/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, ml: 5, width: '100%' }}>{type.toUpperCase()} PDF </Typography>

        </Stack>

        {
            loading ?
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <PDFViewer style={{ width: '100%', height: '100vh' }} >
                    <DossierPDF dossier={dossier} />
                </PDFViewer>
        }

      
    </div>

}



export default DossierViewer;