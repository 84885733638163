


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HOTEL_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchHotels = createAsyncThunk('fetch-hotels', async (page = 1) => {


    const response = await fetch(`${HOTEL_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});

export const createHotel = (hotel, err, cb) => {
    fetch(HOTEL_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(hotel)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}


export const updateHotel = (hotel, err, cb) => {
    fetch(`${HOTEL_URL}/${hotel.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(hotel)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteHotel = (id, err, cb) => {
    fetch(`${HOTEL_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}

const hotelSlice = createSlice({

    name: 'hotels',
    initialState: {hotels: [], hotelsFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHotels.fulfilled, (state, action) => {

            state.hotels = [...action.payload].sort((a,b) => sortByProp(a,b, 'nom'));
            state.hotelsFetchStatus = 'success';
        }).addCase(fetchHotels.pending, (state, action) => {
            state.hotelsFetchStatus = 'loading';

        }).addCase(fetchHotels.rejected, (state, action) => {

            state.hotelsFetchStatus = 'error';

        });
    }


});
export default hotelSlice;