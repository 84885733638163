

import { Button, InputBase, Menu, MenuItem, Typography, alpha } from "@mui/material";
import "./BFacturation.scss";
import { AccountBoxSharp, AirplaneTicket, Business, Delete, Filter, Filter1Sharp, FilterAlt, MonetizationOnSharp, Refresh, Update } from "@mui/icons-material";
import { COLORS } from "../../settings/themeSettings";
import styled from "@emotion/styled";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import {
    TablePagination,
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { useNavigate, useParams } from "react-router";
import StyledTable from "../../components/table-component/StyledTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchFactures } from "../../data/slices/factureSlice";
import clientSlice, { creditAccount, fetchClient } from "../../data/slices/clientStore";
import CreditDialog from "../../components/CreditDialog";
import { notify } from "../../settings/toastSetting";
import { fetchClientDevis } from "../../data/slices/devisSlice";


const head = ['numeroFacture', 'numeroDossier', 'commentaire', 'date', 'type_facture'];


export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(COLORS.tertiary, 0.15),
    '&:hover': {
        backgroundColor: alpha(COLORS.tertiary, 0.25),
    },

    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: COLORS.secondary,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%'
    },
}));







const BFacturation = (props) => {

    const navigateTo = useNavigate();

    const { factures, fetchStatus } = useSelector((state) => state.factures);
    const { devisList, fetchDevisListStatus } = useSelector((state) => state.devis);
    const dispatch = useDispatch();
    const { clientId } = useParams();
    const [currentClient, setCurrentClient] = useState({});

    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);

    const openDialog = () => {
        setOpen(true);
    }

    const handleClose = (montant) => {
        console.log(montant);
        setOpen(false);

        if (montant) {
            creditAccount({
                ...montant,
                client: clientId
            }, (err) => {
                console.log(err);

                notify("Une erreur s'est produite veuillez réessayer!", 'error', 'TOP_CENTER');
            },
                (_client) => {

                    console.log(_client);
                    setCurrentClient(_client);

                    notify("Le compte a été crédité avec succés!", 'success', 'TOP_RIGHT');
                }


            );
        }
    }

    useEffect(() => {
        dispatch(fetchFactures(clientId));

        fetchClient(clientId, (err) => {
            console.log(err);
        }, (client) => {
            console.log(client);
            setCurrentClient(client);
        });

        dispatch(fetchClientDevis(clientId));


        
    }, []);


    const createFacture = () => {
        navigateTo('/billeterie/factures/nouveau/' + clientId);
    }

    const onOptionClicked = (id) => {
        navigateTo(`/billeterie/factures/${clientId}/details/${id}`);
    }


    return (<div className="billeterie-facturation">
        <div className="header">
            <div className="title">
                Factures
            </div>

            <Button
                variant="contained"
                color="secondary"
                onClick={createFacture}
            >
                Ajouter une facture

            </Button>
        </div>


        {
            fetchStatus == 'loading' ?

                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <>



                    <div className="client-profil">
                        <div className="profil-infos">
                            <div className="icon">
                                {currentClient && currentClient.structure ?
                                    <Business style={{ fill: 'white', fontSize: '3rem' }} />
                                    :
                                    <AccountBoxSharp style={{ fill: 'white', fontSize: '3rem' }} />

                                }
                            </div>

                            {currentClient && <div className="infos">
                                <div className="name">
                                    {(currentClient.prenom ? currentClient.prenom + ' ' + currentClient.nom : currentClient.nom)}
                                </div>
                                <div className="nb-fac">
                                    nombre de factures : <span>{currentClient.factures && currentClient.factures.length} </span>
                                </div>
                                <div className="nb-fac">
                                    compte : <span>{(currentClient.balance || 0) + ' FCFA'} </span>
                                </div>

                            </div>}
                        </div>


                        <div className="options">
                            <Button

                                onClick={openDialog}

                                color="secondary" variant="outlined" startIcon={<MonetizationOnSharp />}>
                                Créditer le compte client
                            </Button>
                            <Button
                                onClick={() => navigateTo(`/billeterie/devis/nouveau/${currentClient.id}`)}

                                color="secondary" variant="outlined" startIcon={<AirplaneTicket />}>
                                Faire un devis
                            </Button>
                            <Button
                                onClick={() => navigateTo(`/billeterie/portefeuil/${currentClient.id}`)}

                                color="primary" variant="outlined" startIcon={<Update />}>
                                Modifier
                            </Button>


                        </div>
                    </div>

                    <div className="tabs">
                        <div className={tab == 0 ? "tab active" : "tab"} onClick={() => setTab(0)}>
                            Factures
                        </div>
                        <div className={tab == 1 ? "tab active" : "tab"} onClick={() => setTab(1)}>
                            Devis
                        </div>
                    </div>

                {  tab == 0 &&   <div className="table-container">
                        <div className="table-header">
                            <div className="tools">
                                <div className="btn">
                                    <Refresh style={{ fill: COLORS.secondary }} />
                                    <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                                </div>

                        
                            </div>
                            <div className="searchbar">


                                <Search >
                                    <SearchIconWrapper>
                                        <SearchIcon style={{ fill: COLORS.secondary }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Recherche"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>
                            </div>
                        </div>

                        <div className="table">
                            <StyledTable head={head} rows={factures} onOptionClicked={onOptionClicked} />
                        </div>
                    </div>}

                    {
                         tab == 1 &&   <div className="table-container">
                            <div className="table-header">
                                <div className="tools">
                                    <div className="btn">
                                        <Refresh style={{ fill: COLORS.secondary }} />
                                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                                    </div>
    
                                  
                                </div>
                                <div className="searchbar">
    
    
                                    <Search >
                                        <SearchIconWrapper>
                                            <SearchIcon style={{ fill: COLORS.secondary }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Recherche"
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </Search>
                                </div>
                            </div>
    
                            <div className="table">
                                <StyledTable head={['numeroDevis', 'createdAt']} rows={[...devisList].reverse()} onOptionClicked={(id)=>{
                                    console.log(id);
                                    navigateTo(`/billeterie/devis/${clientId}/details/${id}`);


                                }} />
                            </div>
                        </div>
                    }

                </>

        }
        <CreditDialog open={open} onClose={handleClose} />


    </div>);


}

export default BFacturation;