


import React, { useEffect, useRef, useState } from 'react';
import './transForm.scss';
import { Button, Stack } from '@mui/material';
import { COLORS, nameFormatter } from '../../settings/themeSettings';








const Transform = ({ onSubmit, entity, mandatories = [], toUpdate = null, onDelete = () => { }, onCancel = () => { } }) => {

    const [fields, setFields] = useState(Object.entries(entity));
    const [data, setData] = useState(entity);
    const [error, setError] = useState('');
    const formRef = useRef();

    const changeValue = (property, value) => {
        data[property] = value;
        setData(data);

    }

    const formValidate = () => {
        let hasError = false;
        setError('');
        mandatories.map((item) => {
            if (!data[item]) hasError = true;
        });

        if (hasError) {
            setError("Veuillez remplir tous les champs obligatoire!");
            return;
        }

        onSubmit(data, () => {

            setData(entity);

            //  formRef.current.reset();
        });


    }



    useEffect(() => {

        if (!!toUpdate) {
            let update = Object.assign(data, toUpdate);
            console.log(update);
            setData(update);
            setFields(Object.entries(update));
        } else {
            // formRef.current.reset();
            setData(entity);

        }

    }, [toUpdate])



    return <div className='form-container'>


        <div className='form'>

            <form

                ref={formRef}
                onSubmit={(e) => {
                    e.preventDefault();
                    formValidate();


                }}

                onReset={(e) => {
                    e.preventDefault();
                    setData(entity);

                    onCancel();
                }}

            >


                {

                    fields.map((field, idx) =>
                        (field[0] != 'id') && (field[0] == "profil" ?
                            <div className="form-item" key={idx}>
                                <div className="label" style={{ textTransform: 'capitalize' }}> {nameFormatter(field[0]).toLowerCase()} <span> {mandatories.includes(field[0]) ? '*' : ''} </span> </div>
                                <select name={field[0]}
                                    placeholder={nameFormatter(field[0])}
                                    onChange={(e) => changeValue(field[0], e.target.value)} >
                                            <option selected={field[0] == "ADMIN-PRODUCTION-BILLETTERIE"} value="ADMIN-PRODUCTION-BILLETTERIE">ADMIN</option>
                                            <option selected={field[0] == "ADMIN-PRODUCTION"} value="ADMIN-PRODUCTION">ADMIN-PRODUCTION</option>
                                            <option  selected={field[0] == "ADMIN-BILLETTERIE"} value="ADMIN-BILLETTERIE">ADMIN-BILLETERIE</option>
                                            <option  selected={field[0] == "PRODUCTION"} value="PRODUCTION">PRODUCTION</option>
                                            <option  selected={field[0] == "BILLETTERIE"} value="BILLETTERIE">BILLETTERIE</option>
                                        </select>



                            </div>


                            : <div className="form-item" key={idx}>
                                <div className="label" style={{ textTransform: 'capitalize' }}> {nameFormatter(field[0]).toLowerCase()} <span> {mandatories.includes(field[0]) ? '*' : ''} </span> </div>
                                <input type="text" name={field[0]}
                                    placeholder={nameFormatter(field[0])}
                                    defaultValue={data[field[0]]}
                                    onChange={(e) => changeValue(field[0], e.target.value)} />

                            </div>
                        )
                    )

                }
                <div className="error-msg">

                    {!!error && error}

                </div>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                >

                    <Button
                        color='error'
                        //onClick={(e) =>{ }}
                        type='reset'

                        sx={{ border: '1px solid red', mt: 2, minWidth: '100px' }}
                    >
                        Annuler
                    </Button>

                    {!!toUpdate && <Button
                        color='error'
                        onClick={(e) => onDelete(data.id)}
                        sx={{ border: '1px solid red', mt: 2, minWidth: '100px' }}
                    >
                        supprimer
                    </Button>}


                    <Button
                        color='secondary'
                        // onClick={(e) => formValidate()}
                        type='submit'
                        sx={{ border: '1px solid' + COLORS.secondary, mt: 2, minWidth: '100px' }}
                    >
                        {!!toUpdate ? 'Modifier' : 'Enregistrer'}
                    </Button>
                </Stack>
            </form>
        </div>
    </div>;
}


export default Transform;