


export const BaseURL = 'https://travel.africa4tourism.net:8080/api';

export const BclientsURL = `${BaseURL}/portefeuilles`;
export const BClientFacturesUrl = `${BaseURL}/client/factures/`;
export const BFactureUrl = `${BaseURL}/factures/`;

export const PORTEFEUILLE_URL = `${BaseURL}/portefeuilles`;
export const CIRCUIT_URL = `${BaseURL}/circuits`;
export const SWITCH_CIRCUIT_URL = `${BaseURL}/switch/cloture`;
export const CHAUFFEUR_URL = `${BaseURL}/chauffeurs`;
export const GUIDE_URL = `${BaseURL}/guides`;
export const VEHICULE_URL = `${BaseURL}/vehicules`;
export const HOTEL_URL = `${BaseURL}/hotels`;
export const AGENCE_URL = `${BaseURL}/agences`;
export const PROGRAMMES_URL = `${BaseURL}/programmes`;
export const COMPAGNIE_VOL_URL = `${BaseURL}/compagnie_vols`;
export const TYPE_EXCURSION_URL = `${BaseURL}/type_excursions`;
export const TYPE_FRAIS_URL = `${BaseURL}/type_frais`;
export const TO_CLIENT_URL = `${BaseURL}/to_clients`;
export const FACTURATION_URL = `${BaseURL}/facturation`;
export const CANCEL_TICKETS_URL = `${BaseURL}/cancel/facture`;
export const UPDATE_TICKETS_URL = `${BaseURL}/update/facture`;
export const PLANE_TICKETS_URL = `${BaseURL}/journal/vente`;
export const PAYMENT_HISTORY = `${BaseURL}/journal/paiements`;
export const CREDIT_URL = `${BaseURL}/credit/account`;
export const MAKE_CIRCUIT_URL = `${BaseURL}/make/circuit`;
export const MAKE_FACTURE_URL = `${BaseURL}/make/facture/dossier`;
export const FACTURE_DOSSIER_URL = `${BaseURL}/facture_circuits`;
export const AUTH_URL = `${BaseURL}/auth`;
export const ME_URL = `${BaseURL}/me`;
export const USERS_URL = `${BaseURL}/users`;
export const REGISTER_USER_URL = `${BaseURL}/register`;
export const FACTURE_EDIT_URL = `${BaseURL}/facturation/edit`;
export const VOUCHER_URL = `${BaseURL}/vouchers`;
export const VOUCHERS_CIRCUIT_URL = `${BaseURL}/vouchers/circuit`;
export const DASHBOARD_URL = `${BaseURL}/dashboard`;
export const TRANSFERTS_URL = `${BaseURL}/transferts`;
export const MAKE_DEVIS_URL = `${BaseURL}/make/devis`;
export const DEVIS_URL = `${BaseURL}/devis`;


export const GET_FACTURES_DOSSIER_URL =(dossierId) => `${BaseURL}/dossier/${dossierId}/factures`;


