class Frais {
    constructor(frais, montant) {

        this.frais = frais;
        this.montant = montant;

    }
}


export default Frais;