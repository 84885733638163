class Guide {
    constructor(nomComplet, telephone, adresse, information) {

        this.nomComplet = nomComplet;
        this.telephone = telephone;
        this.adresse = adresse;
        this.information = information;

    }
}


export default Guide;