import './App.scss';
import * as React from 'react';
import AppRoutes from './Routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './settings/themeSettings';
import { CssBaseline } from '@mui/material';

import { ToastContainer } from 'react-toastify';




function App() {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />

    <div>
      <AppRoutes />
    </div>

    <ToastContainer />
    </ThemeProvider>

  );
}

export default App;
