import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import BaseParameter from '../../data/schemas/baseParameter';
import { createTypeExcursion, deleteTypeExcursion, fetchTypeExcursion, updateTypeExcursion } from '../../data/slices/typeExcursionSlice';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const TypeExcursionPage = (props) => {

    const { typeExcursions } = useSelector((state) => state.typeExcursions);
    const dispatch = useDispatch();
    const [typeExcursion, setTypeExcursion] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addTypeExcursion = (data, cb) => {
        console.log(data);
        handleClose();

        if (data.id) {

            updateTypeExcursion(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
                notify("le type a été modifié avec succés!");
                dispatch(fetchTypeExcursion());


            });


            return;
        }
        createTypeExcursion(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();

            notify("Le type excursion a  été ajouté avec succés!");
            dispatch(fetchTypeExcursion());
        });
    }

    const OnTypeExcusionChanged = (id) => {
        setOpen(true);
        setTypeExcursion(typeExcursions.find((te) => te.id === id));
    }
    const onDelete = (id) => {
        handleClose();
        deleteTypeExcursion(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setTypeExcursion(null);

            notify("Le type a bien été supprimé!");
            dispatch(fetchTypeExcursion());

        });
    }


    useEffect(() => {
        dispatch(fetchTypeExcursion());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des excursions
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...typeExcursions].sort((a,b) => sortByProp(a, b, 'libelle'))}
                head={Object.keys(new BaseParameter())}

                onOptionClicked={OnTypeExcusionChanged}

            />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

            <Transform
                onSubmit={addTypeExcursion}
                mandatories={["libelle"]}
                entity={new BaseParameter()}


                toUpdate={typeExcursion}
                onDelete={onDelete}
                onCancel={() => handleClose()}


            />
        </Dialog>

    </div>

}


export default TypeExcursionPage;