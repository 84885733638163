class Group {
    constructor(libelle, pax) {

        this.libelle = libelle;
        this.pax = pax;

    }
}


export default Group;