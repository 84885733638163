import React, { useEffect, useReducer, useState } from "react";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { AirplaneTicket, CreditCard, Delete, PaymentsTwoTone, Update } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { COLORS, priceFormatter, theme, toEuro } from "../../settings/themeSettings";
import AddTicketDialog from "../../components/AddTicketDialog";
import AddBillDialog from "../../components/AddBillDialog";
import AddPaymentDialog from "../../components/AddPaymentDialog";
import WestIcon from '@mui/icons-material/West';
import { useNavigate, useParams } from "react-router";
import Facture from "../../data/schemas/facture";
import { calculateTotalFrais, createFacture, fetchFacture, updateTickets } from "../../data/slices/factureSlice";
import { notify } from "../../settings/toastSetting";


const styles = {
    container: {
        // border: '1px solid blue',
    //    minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}










const UpdateTicket = (props) => {

    const [open, setOpen] = useState(false);
    const [openAddBill, setOpenAddBill] = useState(false);
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const [billets, setBillets] = useState([]);
    const [payments, setPayments] = useState([]);
    const [bills, setBills] = useState([]);
    const navigateTo = useNavigate();
    const [tab, setTab] = useState('billets');
    const { id, clientId} = useParams();
    const [facture, setFacture] = useState({});
    const [factureReferente, setFactureReferente] = useState(new Facture());
    const [selectedTicket, setSelectedTicket] = useState({});
    const [totalFrais, setTotalFrais] = useState(0);
    const [totalPaiements, setTotalPaiements] = useState(0);
    const [fraisBillet, setFraisBillet] = useState(0);

    const [loading, setLoading] = useState(false);








    const updateTicket = (billet) => {
        console.log(billet);
        setSelectedTicket(billet);
       setOpen(true);

    }

    const removeBill = (id) => {
        let _bills = bills.map((bill, idx) => {
            if (idx != id) {
                return bill;
            }
        }).filter((bill) => !!bill);
        setBills(_bills);
    }


    const removePayment = (id) => {
        let _payments = payments.map((payment, idx) => {
            if (idx != id) {
                return payment;
            }
        }).filter((payment) => !!payment);
        setPayments(_payments);
    }



    const handleClose = (ticketInfos) => {
        setOpen(false);
        if (ticketInfos) {
            console.log({ticketInfos});
            const newTickets = billets.map((billet) => {
                if(billet.id == ticketInfos.id){
                    billet = {...ticketInfos, updated : true};
                }

                return billet;
            });
            setBillets(newTickets);
        }
    }
    const handleAddBillClose = (bill) => {
        setOpenAddBill(false);
        if (bill) {
            setBills([...bills, bill]);
        }
    }

    const handleAddPaymentClose = (payment) => {
        setOpenAddPayment(false);
        console.log(payment);
        if (payment) {
            setPayments([...payments, payment]);
        }
    }



    const openAddBillDialog = () => {
        setOpenAddBill(true);
    }


    const openAddPaymentDialog = () => {
        setOpenAddPayment(true);
    }

    const updateBillets = () => {
        const newTickets = billets.filter((billet) => billet.updated);
        if(newTickets.length < 1) {
            notify("La liste des billets ne doit pas être vide!", 'error', 'TOP_RIGHT');
            return;
        }
        const newFacture = {
            client: clientId,
            commentaire: facture.commentaire,
            billets: newTickets,
            frais:[...bills, {libelle :'vente de billets' , montant : fraisBillet}],
            paiements: payments,
            factureReferente: factureReferente.id

        }
        console.log(newFacture);
        setLoading(true);
        updateTickets(newFacture,err => {
            console.log(err);

            setLoading(false);
        }, facture => {
            console.log(facture);
            setLoading(false);
            if(facture.error){
                notify(facture.error, 'error', 'TOP_RIGHT');
                return;

            }

            notify('Les billets ont été mis à jour!', 'success', 'TOP_RIGHT');

            navigateTo(`/billeterie/factures/${clientId}/details/${facture.id}`);
        });
    }   


    useEffect(() => {
        fetchFacture(id).then((response) => response.json())
        .then(async (factureReferente) => {
           // console.log(resp);
           setFacture({...factureReferente, numeroDossier: facture.numeroDossier, factureReferente: facture.id, client: clientId, frais : [], paiements: []});
           setFactureReferente(factureReferente);
        setBillets(factureReferente.billets);

        })
        .catch(err => {
            console.log(err);
        })
        ;
    }, []);

    useEffect(() => {

        const newFraisBillet = billets.reduce((total, billet) => {
            if(billet.updated){
                return {tarif : Number(total.tarif) + Number(billet.tarif)};
            }
            return total;
        }, {tarif : 0}).tarif;
        console.log(newFraisBillet);

        const newTotalFrais = bills.reduce((total, current) =>{ 
           return { montant: Number(total.montant)  + Number(current.montant) };
        
        }, { montant :0}).montant;


        const newTotalPaiements = payments.reduce((total, current) =>{ 
            
           return {montant : Number(total.montant) + Number(current.montant) };
        }, { montant :  0}).montant;
        setFraisBillet(newFraisBillet);
        setTotalFrais(newTotalFrais + newFraisBillet);
        setTotalPaiements(newTotalPaiements);


    }, [bills, payments, billets]);


    return <Box
        sx={
            styles.container
        }>



        <Stack
            direction="row"
            justifyContent="space-between"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}/details/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '1100px' }}>Modification de billet </Typography>

        </Stack>
        <Stack

            direction="row"
            justifyContent="space-between"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }


        >




            <Stack sx={{ width: '30%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture
                </Typography>


                <Stack sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >  *** **** ** ** </span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                        <span> N° Dossier:</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} > {facture.numeroDossier} </span>

                    </Typography>


                </Stack>



            </Stack>
            <Stack sx={{ width: '30%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture Référente
                </Typography>


                <Stack sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> N° Facture:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} > {factureReferente.numeroFacture} </span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span> Total Frais :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} > { calculateTotalFrais(factureReferente.frais)} FCFA</span>
                    </Typography>
                    <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                        <span> Total Réglement :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} > { calculateTotalFrais(factureReferente.paiements)} FCFA</span>

                    </Typography>


                </Stack>



            </Stack>

            <Stack
                sx={{ width: '30%' }}
            >
                <TextField
                    margin="normal"
                    fullWidth
                    name="Commentaire"
                    label="Commentaire"
                    InputProps={{ style: { height: '145px' } }}
                    color="tertiary"
                    focused
                    multiline={true}
                    onChange={(e) =>{ setFacture({...facture, commentaire: e.target.value})}}
                />

            </Stack>

        </Stack>

        <div className="tabs">
            <div className={(tab == "billets") ? "tab active" : "tab"} onClick={() => setTab("billets")}>
                Listes des Billets
            </div>
            <div className={(tab == "frais") ? "tab active" : "tab"} onClick={() => setTab("frais")}>
                Frais et Réglement
            </div>
        </div>
        {(tab == "billets") && <Stack
            direction="row"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }
            sx={{ minHeight: '250px' }}


        >


            <Box xs={{ ...styles.path }} width={'100%'}
            >


                <div className="spacer"></div>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Liste des billets
                </Typography>


                <TableContainer component={Paper} sx={{ mt: '15px' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nom sur le billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Aller</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Retour</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Trajet </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant TTC </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant FA </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Update /> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                               billets && billets.map((billet, idx) => {
                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                        <TableCell > {billet.numero} </TableCell>
                                        <TableCell > {billet.nom} </TableCell>
                                        <TableCell > {billet.numeroVol} </TableCell>
                                        <TableCell> {billet.dateVol } </TableCell>
                                        <TableCell > {billet.compagnie} </TableCell>

                                        <TableCell > {billet.numeroVolRetour} </TableCell>
                                        <TableCell> {billet.dateVolRetour } </TableCell>
                                        <TableCell > {billet.compagnieRetour} </TableCell>



                                        <TableCell > {billet.trajet} </TableCell>
                                        <TableCell > {priceFormatter(billet.tarif) } </TableCell>
                                        <TableCell > {priceFormatter(billet.montantAchat) } </TableCell>
                                        <TableCell > 
                                            {
                                               ( billet.etat == 'OPEN' )? <Update style={{ fill: COLORS.secondary, cursor: 'pointer' }} onClick={() => updateTicket(billet)} /> : billet.etat
                                            }
                                         </TableCell>

                                    </TableRow>

                                })

                            }

                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </Stack>
        }

<Box
            sx={{
                width: '100%',
                minHeight: '300px'
            }}
        >

            {(tab == "frais") && <Stack
                direction="row"
                spacing={2}
                sx={{
                    '& > *': { width: '50%' },
                    height: '100%',
                    mt: 8,
                    padding: '0px 5px',

                }}
                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }
            >


                <Box sx={
                    {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                    }
                }>


                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 450 }} aria-label="simple table" >
                            <TableHead >
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Frais</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en CFA</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en EURO</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    (fraisBillet > 0) && <TableRow sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                        <TableCell >Vente de billets </TableCell>
                                        <TableCell > {fraisBillet} </TableCell>
                                        <TableCell > {fraisBillet?(toEuro( fraisBillet)) : ''} </TableCell>
                                    </TableRow>



                                }

                                {
                               bills.map((bill, idx) => {
                                        return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                            <TableCell > {bill.libelle} </TableCell>
                                            <TableCell > {bill.montant} </TableCell>
                                            <TableCell > {bill.montant?(toEuro( bill.montant )) : ''} </TableCell>
                                            <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removeBill(idx)} /> </TableCell>

                                        </TableRow>
                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>





                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<PaymentsTwoTone sx={{ fill: COLORS.secondary }} />}
                        onClick={openAddBillDialog}
                    >
                        Ajouter des frais
                    </Button>
                </Box>
                <Box
                    sx={
                        {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            gap: 10,
                        }
                    }
                >



                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Mode de paiement</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Commentaire </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {  payments.map((payment, idx) => {
                                        return <TableRow key={idx} sx={{ color: COLORS.secondary, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                            <TableCell> {payment.methode} </TableCell>
                                            <TableCell> {payment.montant} </TableCell>
                                            <TableCell> {payment.date} </TableCell>
                                            <TableCell> {payment.commentaire} </TableCell>
                                            <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removePayment(idx)} /> </TableCell>


                                        </TableRow>

                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<CreditCard sx={{ fill: COLORS.secondary }} />}
                        onClick={openAddPaymentDialog}
                    >
                        Ajouter un réglement
                    </Button>


                </Box>





            </Stack>



            }

            <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={4}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }

            >


                <div className="total">
                    <div className="title">
                        Total Frais:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                        {totalFrais} FCFA
                    </div>

                    
                </div>

                <div className="total">
                    <div className="title">
                        Total Réglement:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                        {totalPaiements} FCFA
                    </div>

                    
                </div>

                <div className="total">
                    <div className="title">
                        Solde:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                       {totalFrais - totalPaiements} FCFA
                    </div>

                    
                </div>


            </Stack>




            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

            >


                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}

                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={updateBillets}
                    disabled={loading}
                >
                  { loading? 'Patientez..' : 'Valider'}
                </Button>

            </Stack>


        </Box>







        <AddTicketDialog ticket={selectedTicket} open={open} onClose={handleClose} />
        <AddBillDialog open={openAddBill} onClose={handleAddBillClose} />
        <AddPaymentDialog open={openAddPayment} onClose={handleAddPaymentClose} />
    </Box>
}



export default UpdateTicket;