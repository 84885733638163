


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VEHICULE_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchVehicules = createAsyncThunk('fetch-vehicules', async (page = 1) => {


    const response = await fetch(`${VEHICULE_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});

export const createVehicule = (vehicule, err, cb) => {
    fetch(VEHICULE_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(vehicule)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}


export const updateVehicule = (vehicule, err, cb) => {
    fetch(`${VEHICULE_URL}/${vehicule.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(vehicule)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteVehicule = (id, err, cb) => {
    fetch(`${VEHICULE_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}






const vehiculeSlice = createSlice({

    name: 'vehicules',
    initialState: {vehicules: [], vehiculeFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVehicules.fulfilled, (state, action) => {

            state.vehicules = [...action.payload].sort((a,b) => sortByProp(a,b, 'modele'));
            state.vehiculeFetchStatus = 'success';
        }).addCase(fetchVehicules.pending, (state, action) => {
            state.vehiculeFetchStatus = 'loading';

        }).addCase(fetchVehicules.rejected, (state, action) => {

            state.vehiculeFetchStatus = 'error';

        });
    }


});
export default vehiculeSlice;