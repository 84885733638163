import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CIRCUIT_URL, MAKE_CIRCUIT_URL, SWITCH_CIRCUIT_URL, VOUCHERS_CIRCUIT_URL } from "../../settings/APISetting";
import { arrayFilter } from "../../settings/themeSettings";



export const fetchCircuits = createAsyncThunk('fetch-circuits', async (params,page = 1) => {


    const response = await fetch(`${CIRCUIT_URL}?type=${params}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});


export const createCircuit = (circuit, err, cb) => {
    fetch(MAKE_CIRCUIT_URL, {
        method: !!circuit.id? 'PUT' :'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(circuit)
    }).then((resp) => {
        console.log(resp);
        if (resp.status > 201) {
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json), err)
        .catch(err);
}

export const fetchCircuit = async (id) => {
    return await fetch(`${CIRCUIT_URL}/${id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


}

export const switchCircuit = async (id) => {
    return await fetch(`${SWITCH_CIRCUIT_URL}/${id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


}


export const fetchVouchers = async (id) => {
    return await fetch(`${VOUCHERS_CIRCUIT_URL}/${id}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


}





const circuitSlice = createSlice({

    name: 'circuits',
    initialState: { circuits: [], fetchStatus: '' },

    reducers: {
        filterCircuits: (state, action) => {
            state.circuits = arrayFilter(new RegExp(action.payload.nail), state.circuits);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCircuits.fulfilled, (state, action) => {

            state.circuits = action.payload.reverse().map((i) =>{
                if(i?.cloture){
                    i.statut = "Clôturé"
                }
                return i;
            });
            state.fetchStatus = 'success';
        }).addCase(fetchCircuits.pending, (state, action) => {
            state.fetchStatus = 'loading';

        }).addCase(fetchCircuits.rejected, (state, action) => {

            state.fetchStatus = 'error';

        });
    }


});
export default circuitSlice;