
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import FacturePDF from '../../components/facture-pdf/FacturePDF';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { COLORS } from '../../settings/themeSettings';
import WestIcon from '@mui/icons-material/West';
import { Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevis } from '../../data/slices/devisSlice';
import DevisPDF from '../../components/facture-pdf/DevisPDF';



const DevisViewer = ({}) => {

    const { id, clientId} = useParams();
    const navigateTo = useNavigate();

    const {devis, fetchDevisStatus} = useSelector((state) => state.devis);
    const dispatch = useDispatch();

    
        useEffect(() => {
            if(devis == null) dispatch(fetchDevis(id));
        }, []);

    return <div>
        <Stack
            direction="row"
            justifyContent="flex-start"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/devis/${clientId}/details/${id}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, ml: 5, width: '100%' }}>Devis PDF </Typography>

        </Stack>

{
    fetchDevisStatus == 'loading' ?
    <div className="loader-container">
                    <span className="loader"></span>
                </div>

                :

              <PDFViewer style={{width: '100%', height: '100vh'}} >
             <DevisPDF devis={devis} /> 

        </PDFViewer>   
}
        

       

    </div>

}



export default DevisViewer;