class Location {
    constructor(agence, date_debut, date_fin, nombre_jour, prix_unitaire,vehicule, chauffeur, guide, montant) {

        this.dateDebut = date_debut;
        this.dateFin = date_fin;
        this.nombreJour = nombre_jour;

        this.guide = guide;
        this.chauffeur = chauffeur;

        this.vehicule = vehicule;

        this.agence = agence
        this.prixUnitaire = prix_unitaire;

        this.montant = montant;
    }
}


export default Location;