import { Autocomplete, Box, Button, Dialog, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DateField, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { COLORS } from "../settings/themeSettings";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompagnieVol } from "../data/slices/compagnieVolSlice";



const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1000px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}



function AddTicketDialog(props) {

    const {  compagnieVolsName  : options } = useSelector((state) => state.compagnieVols);

    const { onClose, open } = props;
    const typesBillets = ["aller_simple", "aller_retour"];
    const classes = ["Business", "Premium", "Classe économie"];

    const [classe, setClasse] = useState(classes[0]);
    const [classeRetour, setClasseRetour] = useState(classes[0]);



    const [compagnie, setCompagnie] = useState(options[0]);
    const [compagnieRetour, setCompagnieRetour] = useState(null);
    const [numero, setNumero] = useState('');
    const [trajet, setTrajet] = useState('');
    const [numeroVol, setNumeroVol] = useState('');
    const [numeroVolRetour, setNumeroVolRetour] = useState(null);
    const [dateVol, setDateVol] = useState(null);
    const [dateVolRetour, setDateVolRetour] = useState(null);
    const [tarif, setTarif] = useState('');
    const [nom, setNom] = useState('');
    const [montantAchat, setMontantAchat] = useState(null);
    const [montantEncaisse, setMontantEncaisse] = useState(null);
    const [commentaire, setCommentaire] = useState(null);

    const [type, setType] = useState(typesBillets[0]);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        onClose();
    };
    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchCompagnieVol());


    }, []);
    const addTicket = () => {


        let ticketInfos = {
            id: props.ticket?.id || null,
            type,
            numero,
            trajet,
            numeroVol,
            dateVol: dateVol && ((typeof dateVol == 'string') ? dateVol : dateVol.toISOString().split('T')[0]),
            compagnie,
            numeroVolRetour,
            dateVolRetour: dateVolRetour && ((typeof dateVolRetour == 'string') ? dateVolRetour : dateVolRetour.toISOString().split('T')[0]),
            classe,
            classeRetour,
            compagnieRetour,
            nom,
            tarif,
            montantAchat,
            etat: 'OPEN',
            montantEncaisse : montantEncaisse || tarif,
            commentaire

        }
        //  console.log(ticketInfos);
        onClose(ticketInfos);

    }


    useEffect(() => {
        console.log(props);

        if (props.ticket) {
            setCompagnie(props.ticket.compagnie);

            setClasse(props.ticket.classe);
            setClasseRetour(props.ticket.classeRetour);
            setCompagnieRetour(props.ticket.compagnieRetour);
            setTrajet(props.ticket.trajet);
            setNumero(props.ticket.numero);
            setNom(props.ticket.nom);
            setMontantAchat(props.ticket.montantAchat);
            setTarif(props.ticket.tarif);
            setNumeroVol(props.ticket.numeroVol);
            setNumeroVolRetour(props.ticket.numeroVolRetour);
            props.ticket.type && setType(props.ticket.type);

            setDateVol(props.ticket.dateVol);
            setDateVolRetour(props.ticket.dateVolRetour);
            setCommentaire(props.ticket.commentaire);
            setMontantEncaisse(props.ticket.montantEncaisse);


        } else {
            setCompagnie(null);
            setClasse(null);
            setClasseRetour(null);
            setCompagnieRetour(null);
            setTrajet(null);
            setNumero(null);
            setDateVol(null);
            setDateVolRetour(null);
            setMontantAchat(null);
            setTarif(null);
            setCommentaire(null);
            setMontantEncaisse(null);


        }

    }, [props.ticket]);



    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
        >

            <Dialog onClose={handleClose} open={open} maxWidth={'lg'} minWidth={'700px'} >
                <DialogTitle
                    sx={{
                        backgroundColor: COLORS.secondary,
                        color: 'white',
                        mb: 5
                    }}
                >Ajouter un billet</DialogTitle>
                <Stack
                    direction="row"

                    divider={
                        <Box
                            component="hr"
                            sx={{
                                border: `1px solid ${COLORS.tertiary}`,
                                ml: 2,
                                mr: 2
                            }}
                        />
                    }
                    sx={{
                        height: (type == "aller_retour") ? '700px' : '500px',
                        pl: 2,
                        pr: 2,

                    }}


                >

                    <Box
                        xs={styles.path}
                        width={'300px'}
                    >
                        <Typography
                            variant="p"
                            sx={styles.pathtitle}

                        >
                            Compagnies de voyage
                        </Typography>



                        <Autocomplete
                            defaultValue={props.ticket && props.ticket.type}
                            value={type}
                            onChange={(event, newValue) => {
                                setType(newValue);
                            }}
                            inputValue={type}
                            onInputChange={(event, newInputValue) => {
                                setType(newInputValue);
                            }}
                            options={typesBillets}
                            sx={{ width: 300, mt: '15px' }}

                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Type de billet" />}

                            clearIcon={false}

                        />

                        <Autocomplete
                            //value={compagnie}
                            defaultValue={props.ticket && props.ticket.compagnie}

                            inputValue={compagnie}
                            onInputChange={(event, newInputValue) => {
                                setCompagnie(newInputValue);
                            }}
                            options={options}
                            sx={{ width: 300, mt: '15px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Compagnie Vol Aller" />}
                            clearIcon={false}

                        />

                        <Autocomplete
                            defaultValue={props.ticket && props.ticket.classe}
                            // onChange={(event, newValue) => {
                            //     setValue(newValue);
                            // }}
                            inputValue={classe}
                            onInputChange={(event, newInputValue) => {
                                setClasse(newInputValue);
                            }}
                            options={classes}
                            sx={{ width: 300, mt: '15px' }}
                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Classe Vol Aller" />}
                            clearIcon={false}

                        />



                        {


                            (type == "aller_retour") &&

                            <>


                                <Autocomplete
                                    defaultValue={props.ticket && props.ticket.compagnieRetour}
                                    inputValue={compagnieRetour}
                                    onInputChange={(event, newInputValue) => {
                                        setCompagnieRetour(newInputValue);
                                    }}
                                    options={options}
                                    sx={{ width: 300, mt: '15px' }}
                                    color="tertiary"
                                    renderInput={(params) => <TextField {...params} label="Compagnie Vol Retour" />}
                                    clearIcon={false}

                                />
                                <Autocomplete
                                    //value={props.classeRetour}

                                    inputValue={classeRetour}
                                    onInputChange={(event, newInputValue) => {
                                        setClasseRetour(newInputValue);
                                    }}
                                    options={classes}
                                    sx={{ width: 300, mt: '15px' }}
                                    color="tertiary"
                                    renderInput={(params) => <TextField {...params} label="Classe Vol Retour" />}
                                    clearIcon={false}
                                    defaultValue={props.ticket && props.ticket.classeRetour}

                                />


                            </>


                        }

                        <TextField

                            defaultValue={props.ticket && props.ticket.tarif}

                            margin="normal"
                            required
                            fullWidth
                            name="tarif"
                            label="Tarif TTC"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Tarif TTC'
                            onChange={(e) => setTarif(e.target.value)}
                        />


                        <TextField

                            defaultValue={props.ticket && props.ticket.montantAchat}
                            margin="normal"
                            required
                            fullWidth
                            label="Tarif FA"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Tarif FA'
                            onChange={(e) => setMontantAchat(e.target.value)}
                        />

                        <TextField

                            defaultValue={props.ticket && props.ticket.montantEncaisse}
                            margin="normal"
                            required
                            fullWidth
                            label="Montant encaissé"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder="Montant encaissé"
                            onChange={(e) => setMontantEncaisse(e.target.value)}
                        />
                    </Box>
                    <Box
                        xs={styles.path}
                        width={'300px'}
                    >
                        <Typography
                            variant="p"
                            sx={styles.pathtitle}

                        >
                            Information sur le voyage
                        </Typography>


                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="N° de Billet"
                            label="N° de Billet"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='N° de Billet'
                            onChange={(e) => setNumero(e.target.value)}
                            defaultValue={props.ticket && props.ticket.numero}
                        />

                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="Nom sur le billet"
                            label="Nom sur le billet"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Nom sur le billet'
                            onChange={(e) => setNom(e.target.value)}
                            defaultValue={props.ticket && props.ticket.nom}
                        />

                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="Trajet"
                            label="Trajet"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Trajet'
                            onChange={(e) => setTrajet(e.target.value)}
                            defaultValue={props.ticket && props.ticket.trajet}
                        />


                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="N° de vol"
                            label="N° de vol Aller"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='N° de vol'
                            onChange={(e) => setNumeroVol(e.target.value)}
                            defaultValue={props.ticket && props.ticket.numeroVol}
                        />

                        <DateField
                            label="Date de vol Aller"
                            color="tertiary"
                            focused
                            fullWidth
                            sx={{ mt: 2.5 }}
                            InputProps={{ style: { height: '45px' } }}
                            onChange={(e) => { setDateVol(e) }}
                            format="DD-MM-YYYY"
                            defaultValue={props.ticket && dayjs(new Date(props.ticket.dateVol))}


                        />



                        {
                            (type == "aller_retour") && (
                                <>

                                    <TextField

                                        margin="normal"
                                        required
                                        fullWidth
                                        name="N° de vol Retour"
                                        label="N° de vol Retour"
                                        InputProps={{ style: { height: '45px' } }}
                                        color="tertiary"
                                        focused
                                        placeholder='N° de vol Retour'
                                        onChange={(e) => setNumeroVolRetour(e.target.value)}
                                        defaultValue={props.ticket && props.ticket.numeroVolRetour}
                                    />

                                    <DateField
                                        label="Date de vol Retour"
                                        color="tertiary"
                                        focused
                                        fullWidth
                                        sx={{ mt: 2.5 }}
                                        InputProps={{ style: { height: '45px' } }}
                                        onChange={(e) => setDateVolRetour(e)}
                                        format="DD-MM-YYYY"
                                        defaultValue={props.ticket && dayjs(new Date(props.ticket.dateVolRetour))}


                                    />
                                </>


                            )

                        }










                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="commentaire"
                            label="Commentaire"
                            InputProps={{ style: { height: '45px', marginTop: '10px' } }}
                            color="tertiary"
                            focused
                            placeholder="Commentaire"
                            onChange={(e) => setCommentaire(e.target.value)}
                            defaultValue={props.ticket && props.ticket.commentaire}
                        />

                    </Box>

                </ Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                        mt: 4,
                        pb: 5,
                        pr: 2
                    }}

                >


                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={addTicket}
                    >
                        {props.ticket ? 'Mettre à jour' : 'Valider'}
                    </Button>

                </Stack>

            </Dialog>
        </LocalizationProvider>

    );
}


export default AddTicketDialog;