import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PLANE_TICKETS_URL } from "../../settings/APISetting";
import { arrayFilter, dateFormatter, priceFormatter, priceFormatterV2 } from "../../settings/themeSettings";



export const fetchBillets = createAsyncThunk('fetch-billets', async (params = {}) => {


    const { startDate, endDate } = params;

    if (!!startDate && !!endDate) {

        var response = await fetch(`${PLANE_TICKETS_URL}?startDate=${startDate}&endDate=${endDate}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });


    } else {

        var response = await fetch(`${PLANE_TICKETS_URL}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }



    return response.json()
});



const billetsSlice = createSlice({


    name: 'billets',
    initialState: { billets: [], fetchBilletsStatus: '' },
    reducers: {
        filterBillets : (state, action) =>{
            console.log(action);
            state.billets = arrayFilter(new RegExp(action.payload.nail) , state.billets);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBillets.fulfilled, (state, action) => {

            let placeholder = action.payload;
            console.log({ placeholder });
            if ((Array.isArray(placeholder))) {
                placeholder = placeholder.map((b) => {

                    b.marge = (b.tarif - b.montantAchat);
                    b['marge %'] = ((b.tarif - b.montantAchat) / b.tarif) * 100;
                    b['marge %'] = Math.round(b['marge %']) + '%';
                    b.dateVol = dateFormatter(b.dateVol);
                    return b;
                });
                console.log({ placeholder });


                state.billets = placeholder;
            }





            state.fetchBilletsStatus = 'success';
        }).addCase(fetchBillets.pending, (state, action) => {
            state.fetchBilletsStatus = 'loading';

        }).addCase(fetchBillets.rejected, (state, action) => {
            console.log(action);

            state.fetchBilletsStatus = 'error';

        });
    }

});

export default billetsSlice;