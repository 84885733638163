


import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { AccountBalance, AirlineSeatFlat, AirlineStops, AirplaneTicketRounded, BookTwoTone, Calculate, CalendarMonth, CarRental, CreditCard, Dashboard, Directions, Domain, DriveEtaRounded, HolidayVillage, Hotel, HotelOutlined, MapRounded, MonetizationOn, Money, Nature, NaturePeople, NextPlanOutlined, PaymentTwoTone, Settings, SettingsAccessibility, Shop, Shop2Outlined, StarBorder, StoreTwoTone, TransferWithinAStation, VerifiedUserRounded } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { COLORS } from '../../settings/themeSettings';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

function Sidebar() {
    const navigateTo = useNavigate();
    const { me } = useSelector((state) => state.me);




    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', minHeight: '100vh' }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: COLORS.secondary }} >
                        <Dashboard style={{ fill: 'white' }} />
                    </Avatar>
                </ListItemAvatar>
                <ListItemButton onClick={() => navigateTo('/dashboard')}>
                    <ListItemText

                        primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Dashboard</Typography>}

                    />
                </ListItemButton>

            </ListItem>


            {
                /PRODUCTION/.test(me.profil + "") && <>



                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: COLORS.secondary }} >
                                <CalendarMonth style={{ fill: 'white' }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemButton onClick={() => navigateTo('/calendars')}>
                            <ListItemText

                                primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Calendrier Transferts</Typography>}

                            />
                        </ListItemButton>

                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: COLORS.secondary }} >
                                <Calculate style={{ fill: 'white' }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemButton onClick={() => navigateTo('/calculator')}>
                            <ListItemText

                                primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Cotation</Typography>}

                            />
                        </ListItemButton>

                    </ListItem>


                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: COLORS.secondary }} >
                                <Domain style={{ fill: 'white' }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText

                            primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Production</Typography>}

                        />
                    </ListItem>
                    <ListItem>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/dossiers/circuit')}>
                                <ListItemIcon>
                                    <AirlineStops style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Circuit</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/dossiers/sejour')} >
                                <ListItemIcon>
                                    <Hotel style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Séjour</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/dossiers/excursion')}>
                                <ListItemIcon>
                                    <NaturePeople style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Excursion</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/dossiers/transfert')}>
                                <ListItemIcon>
                                    <TransferWithinAStation style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Transferts
                                    </Typography>}

                                />
                            </ListItemButton>



                        </List>
                    </ListItem>


                </>
            }




            {
                /BILLETTERIE/.test(me.profil + "") && <>


                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: COLORS.secondary }}>
                                <AccountBalance style={{ fill: 'white' }} />

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Billetterie</Typography>}

                        />

                    </ListItem>
                    <ListItem>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/billeterie/portefeuil')}>
                                <ListItemIcon>
                                    <PaymentTwoTone style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Facturation</Typography>}

                                />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/billeterie/paiements')}>
                                <ListItemIcon>
                                    <MonetizationOn style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Jounal de  paiements</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/billeterie/journal')}>
                                <ListItemIcon>
                                    <BookTwoTone style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Jounal de vente</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/billeterie/caisse')}>
                                <ListItemIcon>
                                    <Money style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Caisse</Typography>}

                                />
                            </ListItemButton>
                        </List>
                    </ListItem>

                </>

            }




            {
                /ADMIN/.test(me.profil + "") && <>

                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: COLORS.secondary }}>
                                <Settings style={{ fill: 'white' }} />

                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }}>Paramètres</Typography>}

                        />

                    </ListItem>



                    <ListItem>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/chauffeurs')}>
                                <ListItemIcon>
                                    <CarRental style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Chauffeur</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/compagnieVols')}>
                                <ListItemIcon>
                                    <AirplaneTicketRounded style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Compagnie de vol</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/users')}>
                                <ListItemIcon>
                                    <VerifiedUserRounded style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Utilisateurs</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/vehicules')}>
                                <ListItemIcon>
                                    <DriveEtaRounded style={{ fill: COLORS.secondary }} />

                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Véhicule</Typography>}

                                />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/guides')}>
                                <ListItemIcon>
                                    <Directions style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Guide</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/agences')}>
                                <ListItemIcon>
                                    <Shop2Outlined style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Agence</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/hotels')}>
                                <ListItemIcon>
                                    <HotelOutlined style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Hotels</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/programmes')}>
                                <ListItemIcon>
                                    <Shop2Outlined style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Programmes</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/type-frais')}>
                                <ListItemIcon>
                                    <Settings style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Type Frais</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/type-excursions')}>
                                <ListItemIcon>
                                    <Settings style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>Type Excursion</Typography>}

                                />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigateTo('/toclients')}>
                                <ListItemIcon>
                                    <Settings style={{ fill: COLORS.secondary }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography variant="body1" style={{ color: COLORS.secondary, fontSize: '1em' }}>TO/client</Typography>}

                                />
                            </ListItemButton>
                        </List>
                    </ListItem>
                </>
            }











        </List>
    );
}


export default Sidebar;