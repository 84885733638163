



class CompagnieVol {
    constructor(nom, id) {
    this.id = id;
        this.nom = nom;
    

    }
}


export default CompagnieVol;