import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Stack, Typography } from '@mui/material';
import './navigation.scss';
import { AccountBox, AccountCircle, AccountCircleOutlined, Logout, Menu } from '@mui/icons-material';
import { pink } from '@mui/material/colors';
import { useEffect, useState } from 'react';

import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useNavigate } from 'react-router';
import { COLORS } from '../../settings/themeSettings';
import { apilogout } from '../../auth/useToken';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMe } from '../../data/slices/currentUserSlice';



const styles = {
    navbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '0px 15px',
        bgcolor: COLORS.secondary,

    },
    title: { fontWeight: 'bold', fontFamily: 'Roboto', color: 'white' },
    avatar: {
        bgcolor: 'white',
        cursor: 'pointer'
    }
}


const NavigationBar = ({ onHambergerClick }) => {
    const { me, fetchMeStatus } = useSelector((state) => state.me);
    const dispatch = useDispatch();


    const [anchorEl, setAnchorEl] = useState(null);
    const navigateTo = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        //navigateTo('/login')
        apilogout(navigateTo);
    }

    useEffect(() => {
        dispatch( fetchMe()) ;
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return <div className="navigation-bar">
        <Box sx={styles.navbar}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 5

                }}

            >    <Menu style={{
                fill: 'white', fontSize: '2em',
                cursor: 'pointer',



            }}  
            onClick={onHambergerClick}
            
            />

                <Typography variant='h4' sx={styles.title}>Travel Manager</Typography>

            
            </div>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {me?.nomComplet &&  <Typography variant='h6' sx={{mr: 5, fontSize: '.8em', color: '#FFF'}}>Bonjour, {me.nomComplet} !</Typography>}

              <Avatar sx={styles.avatar} aria-describedby={id} onClick={handleClick}>
                <AccountCircle style={{ fill: COLORS.secondary }} />
            </Avatar>
            </Stack>
          



            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                <nav aria-label="main mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={logout}>
                                <ListItemIcon>
                                    <Logout style={{ fill: COLORS.secondary }} />
                                </ListItemIcon >
                                <ListItemText primary={
                                    <Typography style={{ color: COLORS.secondary }}>Déconnexion</Typography>
                                } />
                            </ListItemButton>
                        </ListItem>

                    </List>
                </nav>




            </Popover>
        </Box>

    </div>

}


export default NavigationBar;