
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, calculateDays, dateFormatter, priceFormatter, priceFormatterV2 } from '../../settings/themeSettings';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingVertical: 30
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 14,
        color: 'black',
        paddingLeft: '35px'
    },
    title: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleRight: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        // height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 2,
        gap: '10px'
    },
    titleSpan: {
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: 2
    }

});


const VoucherPDF = ({ voucher }) => {




    return <Document>

        <Page size="A5" style={styles.page}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%',
                minHeight: 80,
                padding: 5
            }}>
                <Image
                    style={{
                        height: 45,
                        width: 100
                    }}
                    source={logo} />
            </View>





            <View style={{
                width: '70%',
                paddingHorizontal: 15
            }}>


                <Text style={{
                    fontSize: 10,
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'white',
                    alignSelf: 'flex-start',
                    marginVertical: 2,
                    backgroundColor: COLORS.secondary,
                    paddingHorizontal: 5,
                    paddingVertical: 2


                }}> Voucher N° {voucher.numero ?? ''}</Text>
                {/* <View style={styles.titleRight}><Text style={styles.titleSpan}> Référence :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {voucher?.sejour?.circuit?.reference} </Text></View>
                <View style={styles.titleRight}><Text style={styles.titleSpan}> Nombre de pax :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{voucher?.sejour?.circuit?.pax} </Text></View>
 */}

            </View>

            <View style={{
                paddingHorizontal: 15,
                paddingVertical: 20
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <Text style={{ width: '15%', fontSize: 8 }}>Du</Text>
                    <Text style={{ width: '15%', fontSize: 8 }}>Au</Text>
                    <Text style={{ width: '20%', fontSize: 8 }}>Prestataire</Text>
                    <Text style={{ width: '10%', fontSize: 8 }}>Régime</Text>
                    <Text style={{ width: '10%', fontSize: 8 }}>Pax</Text>
                    <Text style={{ width: '10%', fontSize: 8 }}>Nuitées</Text>
                    <Text style={{ width: '20%', fontSize: 8 }}>Montant (FCFA)</Text>

                </View>

                <View style={{
                    height: 1,
                    width: '100%',
                    backgroundColor: 'black',
                    marginVertical: '10px',
                    marginLeft: 'auto',
                    marginRight: 'auto'

                }}></View>
                {voucher?.sejours?.length > 0 && voucher?.sejours.map((sejour) => <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <Text style={{ width: '15%', fontSize: 8 }}> {sejour?.dateDebut ? dateFormatter(sejour?.dateDebut) : ""} </Text>
                    <Text style={{ width: '15%', fontSize: 8 }}>{sejour?.dateFin ? dateFormatter(sejour?.dateFin) : ""}</Text>
                    <Text style={{ width: '20%', fontSize: 8 }}>{voucher?.prestataire?.nom}</Text>
                    <Text style={{ width: '10%', fontSize: 8 }}>{sejour?.regime} </Text>
                    <Text style={{ width: '10%', fontSize: 8 }}> {sejour?.pax}</Text>
                    <Text style={{ width: '10%', fontSize: 8 }}>{sejour?.nuitee} </Text>
                    <Text style={{ width: '20%', fontSize: 8 }}>{sejour?.montant ? priceFormatter(sejour.montant) : ""} </Text>

                </View>
                )

                } </View>
            <Text style={{
                fontSize: 10,
                fontWeight: 'bold',
                marginHorizontal: 15,
                paddingHorizontal: 5,
                paddingVertical: 5,
                backgroundColor: COLORS.primary,
                color: COLORS.secondary,
                width: '60%'

            }}>
                Rooming

            </Text>




            {
             voucher?.sejours?.length > 0 &&    voucher?.sejours[0]?.circuit?.isGroup ?


                    <View style={{
                        paddingHorizontal: '25px',
                        width: '100%'
                    }}>
                        <Text style={{
                            marginVertical: '10px',
                            fontSize: 14,
                            textDecoration: 'underline'
                        }}></Text>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>

                            <Text style={{ width: '50%', fontSize: 10 }}>Libelle</Text>
                            <Text style={{ width: '50%', fontSize: 10 }}>Pax</Text>

                        </View>
                        <View style={{
                            height: 1,
                            width: '100%',
                            backgroundColor: 'black',
                            marginVertical: '10px',
                            marginLeft: 'auto',
                            marginRight: 'auto'

                        }}></View>

                        {
                          voucher?.sejours?.length > 0 &&     voucher.sejours[0]?.circuit?.groups?.map((room) => <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>

                                <Text style={{ width: '50%', fontSize: 10 }}>{room.libelle}</Text>
                                <Text style={{ width: '50%', fontSize: 10 }}>{room.pax} </Text>


                            </View>)
                        }



                    </View>

                    :
                    <View style={{
                        paddingHorizontal: '25px',
                        width: '100%'
                    }}>
                        <Text style={{
                            marginVertical: '10px',
                            fontSize: 14,
                            textDecoration: 'underline'
                        }}></Text>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>

                            <Text style={{ width: '20%', fontSize: 8 }}>Civilite</Text>
                            <Text style={{ width: '40%', fontSize: 8 }}>Nom</Text>
                            <Text style={{ width: '40%', fontSize: 8 }}>Prenom</Text>

                        </View>
                        <View style={{
                            height: 1,
                            width: '100%',
                            backgroundColor: 'black',
                            marginVertical: '10px',
                            marginLeft: 'auto',
                            marginRight: 'auto'

                        }}></View>

                        {
                           voucher?.sejours?.length > 0 &&    voucher.sejours[0]?.circuit?.rooming?.map((room) => <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>

                                <Text style={{ width: '20%', fontSize: 8 }}> {room.civilite} </Text>
                                <Text style={{ width: '40%', fontSize: 8 }}>{room.nom}</Text>
                                <Text style={{ width: '40%', fontSize: 8 }}>{room.prenom} </Text>


                            </View>)
                        }



                    </View>

            }






        </Page>

    </Document>

}

export default VoucherPDF;