import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ME_URL } from "../../settings/APISetting";



export const fetchMe = createAsyncThunk("fetch-me", async () => {

    const response =  await fetch(ME_URL,{
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });
    if(response.status > 204){
        throw response.json();
    }

    return response.json();
});


const currentUserSlice = createSlice({
    name: 'me',
    initialState: {me: {}, fetchMeStatus: ''},
    reducers: {
 
    },

    extraReducers: (builder) => {
        builder.addCase(fetchMe.fulfilled, (state, action) => {
             state.me = action.payload;
           console.log(action.payload);
           state.fetchMeStatus = 'success';
        }).addCase(fetchMe.pending, (state, action) => {
            // state.clients[]
            state.fetchMeStatus = 'loading';

         }).addCase(fetchMe.rejected, (state, action) => {
            // state.clients[]
            console.log(action);

            state.fetchMeStatus = 'error';

         });
    }
});

export default currentUserSlice;

