class Facture {
    constructor(client, numeroDossier, billets, frais, paiements, montantRemboursement = null, factureReferente = null, commentaire = null) {

        this.client = client;
        this.numeroDossier = numeroDossier;
        this.billets = billets || [];
        this.frais = frais || [];
        this.paiements = paiements || [];
        this.commentaire = commentaire;
        this.montantRemboursement = montantRemboursement;
        this.factureReferente = factureReferente;
     
    }
}


export default Facture;