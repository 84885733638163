import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import "./portefeuilClient.scss";
import { AccountBalance, AccountBoxOutlined, AccountBoxSharp, Business, BusinessCenter, Delete, Edit, FilterAlt, MonetizationOn, PaymentTwoTone, PaymentsTwoTone, PersonPinCircle, Refresh, SwipeLeftAlt, SwipeRightAlt, TramSharp } from "@mui/icons-material";
import { COLORS, theme } from "../../settings/themeSettings";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import clientSlice, { editClient, fetchClients } from "../../data/slices/clientStore";
import { Search, SearchIconWrapper, StyledInputBase } from "../BFacturation/BFacturation";
import SearchIcon from '@mui/icons-material/Search';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
const PortefeuilClient = ({ }) => {

    const navigateTo = useNavigate();
    const { clients, fetchStatus } = useSelector((state) => state.clients);
    const dispatch = useDispatch();
    const [confirmation, setConfirmation] = useState(false);
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(1);
    const { filterClients } = clientSlice.actions;

    const handleClose = (confirmed = false) => {
        setConfirmation(false);

        if (confirmed) {
            editClient(selected.id, { ...selected, isarchived: true }, (err) => {
                console.log(err);

            }, (resp) => {
                console.log(resp);

                setSelected(null);
                dispatch(fetchClients());


            });
        } else {
            setSelected(null);
        }
    }



    const consulterFacture = (clientId) => {
        navigateTo('/billeterie/factures/' + clientId);
    }

    useEffect(() => {

        dispatch(fetchClients(page));


    }, [page]);




    return <div className="portefeuil-client">
        <div className="header">
            <div className="title">
                Portefeuille client
            </div>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => navigateTo('/billeterie/portefeuil/ajout')}
            >
                Ajouter un client

            </Button>
        </div>

        <div className="table-header">

            <div className="tools">
                <div className="btn" onClick={
                    (e) => {
                        dispatch(fetchClients());

                    }
                }>
                    <Refresh style={{ fill: COLORS.secondary }} />
                    <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                </div>


            </div>
            <div className="searchbar">


                <Search>
                    <SearchIconWrapper>
                        <SearchIcon style={{ fill: COLORS.secondary }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Recherche"
                        inputProps={{ 'aria-label': 'search' }}
                        onKeyDown={
                            (e) => {
                                if (e.key === 'Enter') {
                                    dispatch(filterClients({ nail: e.target.value }));


                                }
                            }
                        }
                    />
                </Search>
            </div>
        </div>

        {fetchStatus == 'loading' ?

            <div className="loader-container">
                <span className="loader"></span>
            </div>

            :

            <>




                <div className="client-list">

                    {
                        clients.map((client, idx) => {
                            console.log(client);
                            return <div className="clientCard" key={idx}>
                                <Avatar
                                    style={{ height: 80, width: 80, background: COLORS.secondary }}
                                >
                                    {
                                        client.structure ?
                                            <Business style={{ fill: 'white', fontSize: '3rem' }} />
                                            :
                                            <AccountBoxSharp style={{ fill: 'white', fontSize: '3rem' }} />

                                    }
                                </Avatar>
                                <div className="infos">

                                    <div className="name"> {client.structure ? client.nom : client.prenom + ' ' + client.nom} </div>
                                    <div className="infos-list">
                                        <div className="infos-item">
                                            <AccountBalance style={{ fill: COLORS.secondary }} />

                                            <Typography color='secondary' style={{ fontSize: '.8em' }}>{client.balance}  FCFA</Typography>

                                        </div>
                                        <Typography color='secondary' style={{ fontWeight: 'bold' }}>
                                            |
                                        </Typography>
                                        <div className="infos-item">
                                            <div className="number"> {client.factures.length} Factures</div>
                                        </div>
                                    </div>


                                </div>

                                <div className="options">
                                    <Button
                                        onClick={() => navigateTo(`/billeterie/portefeuil/${client.id}`)}
                                        variant="outlined" color="primary" startIcon={
                                            <Edit />
                                        }>Modifier</Button>
                                    <Button
                                        onClick={() => {
                                            setConfirmation(true);
                                            setSelected(client);
                                        }}

                                        variant="outlined" color="error" startIcon={
                                            <Delete style={{ fill: '#D33131' }} />
                                        }>Supprimer</Button>
                                    <Button
                                        onClick={() => consulterFacture(client.id)}
                                        color="secondary"
                                        variant="outlined"
                                        style={{ width: '250px' }}
                                        endIcon={
                                            <PaymentTwoTone style={{ fill: COLORS.secondary }} />
                                        }>factures</Button>

                                </div>

                            </div>
                        })
                    }


                    <div className="pagination">
                        <div className="navigation-left" 
                           onClick={() =>{ 
                            if(page >= 1){
setPage(page - 1)
                            }
                            
                        
                        }}

                        >
                            <WestIcon />
                        </div>
                        <div className="navigation-right"
                        onClick={() =>{ 
                            if(clients.length >= 10 && fetchStatus != 'last_item'){
                                setPage(page + 1)
                            }
                            
                        
                        }}
                        >
                            <EastIcon />
                        </div>
                    </div>


                </div>
            </>

        }



        <Dialog
            open={confirmation}
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} >
                Subscribe
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    êtes vous sûr de vouloir supprimer ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => handleClose(false)}>
                    Annuler
                </Button>
                <Button
                    color="secondary"

                    onClick={() => handleClose(true)}>Archiver</Button>
            </DialogActions>
        </Dialog>

    </div>


}


export default PortefeuilClient;