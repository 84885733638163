import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import { createTypeFrais, deleteTypeFrais, fetchTypeFrais, updateTypeFrais } from '../../data/slices/typeFraisSlice';
import BaseParameter from '../../data/schemas/baseParameter';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const TypeFraisPage = (props) => {

    const { typeFrais } = useSelector((state) => state.typeFrais);
    const dispatch = useDispatch();
    const [typeFrai, setTypeFrai] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = ()=>{
        setOpen(false);
    }

    const addTypeFrais = (data, cb)=> {
        console.log(data);
        handleClose();
        if(data.id){

            updateTypeFrais(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
            notify("le type a été modifié avec succés!");
            dispatch(fetchTypeFrais());
    
    
            });
    

            return;
        }

        createTypeFrais(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();

            notify("Le type de frais a  été ajouté avec succés!");
            dispatch(fetchTypeFrais());
        });
    }

    const onTypeFraiSelected = (id) =>{ 
        setTypeFrai(typeFrais.find((t) => t.id == id));
        setOpen(true);
    };


    const onDelete = (id) => {
        handleClose();
        deleteTypeFrais(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setTypeFrai(null);

        notify("Le type a bien été supprimé!");
        dispatch(fetchTypeFrais());

        });
    }

    useEffect(() => {
        dispatch(fetchTypeFrais());


    }, []);


    return <div className='param-container'>
        <div className="table-container">
        <div className="header">
                <div className="title">
                    Liste des frais
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
                    <StyledTable  
                    rows={[...typeFrais].sort((a,b) => sortByProp(a, b, 'libelle'))} 
                    head={Object.keys(new BaseParameter())}
                    onOptionClicked={onTypeFraiSelected}
                    
                    />

        </div>
        <Dialog onClose={(e) => handleClose(e) }  open={open} >

        <Transform 
        onSubmit={addTypeFrais} 
        mandatories={["libelle"]} 
        entity={new BaseParameter()}
        
       toUpdate={typeFrai} 
        onDelete={onDelete}
        onCancel={()=> handleClose()}
        
        
        
        />
                        </Dialog>

    </div>

}


export default TypeFraisPage;