import { useDispatch, useSelector } from 'react-redux';
import './journal.scss';
import { useEffect, useState } from 'react';
import billetsSlice, { fetchBillets } from '../../data/slices/billetsSlice';
import UpdatedTable from '../../components/updated-table/StyledTable';
import SearchIcon from '@mui/icons-material/Search';
import { AccountBalance, AccountBoxOutlined, AccountBoxSharp, Business, BusinessCenter, Delete, Edit, FileDownloadOutlined, FilterAlt, MonetizationOn, PaymentTwoTone, PaymentsTwoTone, PersonPinCircle, Refresh, SwipeLeftAlt, SwipeRightAlt, TramSharp } from "@mui/icons-material";
import { COLORS, arrayFilter, jsonToXlsx } from '../../settings/themeSettings';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { Typography } from '@mui/material';
import JournalPDF from '../../components/JournalPDF/JournalPDF';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import _ from "lodash";
import JournalVentePDF from '../../components/JournalPDF/JournalVentePDF';


const Journal = (props) => {

    const { billets, fetchBilletsStatus } = useSelector((state) => state.billets);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const {filterBillets}  = billetsSlice.actions;
    useEffect(() => {
        dispatch(fetchBillets());

    }, []);



    useEffect(() => {

        if (!!startDate && !!endDate) {
            dispatch(fetchBillets({ startDate, endDate }));
        }


    }, [startDate, endDate]);




    return (fetchBilletsStatus == 'loading') ?
        <div className="loader-container">
            <span className="loader"></span>
        </div>
        : <>



            <div className="header">
                <div className="title">
                    Journal de vente
                </div>

            </div>

            <div className="table-header">

                <div className="tools">
                    <div className="btn" onClick={() => {
                        setEndDate(null);
                        setStartDate(null);
                        dispatch(fetchBillets());

                    }}>
                        <Refresh style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                    </div>
                    <div className="btn">
                        <span>De</span>
                        <input type="date" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                        <span>à</span>

                        <input type="date" value={endDate} onChange={(e) => {
                            console.log(endDate);
                            setEndDate(e.target.value);
                        }} />
                    </div>
                    {/* <div className="btn">
                        <FilterAlt style={{ fill: COLORS.secondary }} />
                        <select>
                            <option value="">Tout</option>
                            <option value="">N° Facture</option>
                            <option value="">N° Billet</option>
                        </select>
                    </div> */}
                    <div className="btn" onClick={
                        () => {

                            if (billets && billets.length > 0) {
                                jsonToXlsx(billets, "Journal de vente");
                            }
                        }
                    }>
                        <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Excel</Typography>
                    </div>

                    <div className="btn" 
                        onClick={() => {
                            document.getElementById('journalv')?.click();
                        }}
                    >
                    <FileDownloadOutlined style={{ fill: COLORS.secondary }} />
                        <BlobProvider document={<JournalVentePDF data={  [...billets].reverse()} heads={['numero', 'dateVol','etat', 'classe', 'nom', 'trajet', 'montantAchat', 'tarif', 'marge']} title="Journal de Vente" />}>
                        {({ blob, url }) => (
                        <a href={url}
                        id='journalv'
                        style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontSize: '1em',
                            color: COLORS.secondary,
                            fontWeight: 'bold'
                        }}
                        target="_blank"
                        >PDF</a>
                        )}
                        </BlobProvider>
                    </div>
                </div>
           <div className="searchbar">


                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon style={{ fill: COLORS.secondary }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Recherche"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') {
                                      console.log(e.target.value);
                                     dispatch(filterBillets({nail : e.target.value}));
                                      
                                        
                                    }
                                  }
                            }
                        />
                    </Search>
                </div> 
            </div>

     <UpdatedTable 
     rows={
        [...billets].reverse()
    } 
     head={['numero', 'dateVol', 'dateVolRetour', 'etat', 'classe', 'classeRetour', 'nom', 'trajet', 'tarif','montantAchat',  'marge', 'marge %']}
     totals={['tarif','montantAchat',  'marge']}

     
     
     ></UpdatedTable>
        </>;







}


export default Journal;