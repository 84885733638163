class DossierCircuit {
    constructor(
        date,
        programme,
        toClient,
        numeroFacture,
        methodePaiement,
        dateVolArrivee,
        heureVolArrivee,
        numeroVolArrivee,
        dateVolDepart,
        heureVolDepart,
        numeroVolDepart,
        pax,
        commentaire,
        chauffeur,
        guide,
        vehicule,
        isGroup = false,
        rooming = [],
        hotels = [],
        excursions = [],
        transferts = [],
        locations = [],
        envelops = [],
        frais = []
    ) {

 
        this.pax = pax;
        this.toClient = toClient;
        this.methodePaiement = methodePaiement || "Autre"; 
        this.dateVolArrivee = dateVolArrivee;
        this.heureVolArrivee = heureVolArrivee;
        this.numeroVolArrivee = numeroVolArrivee;
        this.dateVolDepart = dateVolDepart;
        this.heureVolDepart = heureVolDepart;
        this.numeroVolDepart = numeroVolDepart;
        this.commentaire = commentaire;
        this.chauffeur = chauffeur;
        this.guide = guide;
        this.vehicule = vehicule;
        this.isGroup = isGroup;
        this.rooming = rooming;
        this.hotels = hotels;
        this.excursions = excursions;
        this.transferts = transferts;
        this.locations = locations;
        this.envelops = envelops;
        this.frais = frais;
        this.numeroFacture = numeroFacture;
        this.date = date;
    }
}


export default DossierCircuit;