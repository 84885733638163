import { Calendar, dateFnsLocalizer, dayjsLocalizer, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import fr from 'date-fns/locale/fr'
import 'moment/locale/fr'
import moment from 'moment'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransfert } from '../../data/slices/transfertSlice';
import { useNavigate } from 'react-router';

const localizer = momentLocalizer(moment)

// const locales = {
//     'fr': fr,
//   }
  
//   const localizer = dateFnsLocalizer({
//     format,
//     parse,
//     startOfWeek,
//     getDay,
//     locales
//   });


  const messages = {
    allDay: 'journée',
    previous: 'précédent',
    next: 'suivant',
    today: 'aujourd\'hui',
    month: 'mois',
    week: 'semaine',
    day: 'jour',
    agenda: 'Agenda',
    date: 'date',
    time: 'heure',
    event: 'événement', // Or anything you want
    showMore: total => `+ ${total} événement(s) supplémentaire(s)`
  }

  
  
const TransfertCalendar = ({ }) => {


    const { transferts, transfertsFetchStatus } = useSelector((state) => state.transferts);
    const dispatch = useDispatch();

    const [events, setEvents] = useState([]);
    const navigateTo = useNavigate();


    useEffect(()=>{
      dispatch(fetchTransfert());  
    }, []);


    useEffect(() => {
        console.log(transferts);
        const _ev = [];
    Array.isArray(transferts)  &&  transferts.map((tr) => {
            _ev.push({ 
                start: new Date(tr.date) ,
                end: new Date(tr.date),
                title: `${tr.lieuDepart} -> ${tr.lieuArrivee} à ${tr.heure}`,
                ...tr
            })
        });
   setEvents(_ev);


    }, [transferts]);

    return <div>
        <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            messages={messages}
            onSelectEvent={(e) => {
                console.log(e);
                (e.circuit.type && e.circuit.id) && navigateTo(`/dossiers/${e.circuit.type}/${e.circuit.id}`);
                
            }}
            
        />    
        </div>;

}


export default TransfertCalendar;