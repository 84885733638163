import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import { createAgence, deleteAgence, fetchAgences, updateAgence } from '../../data/slices/agenceSlice';
import Agence from '../../data/schemas/Agence';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const AgencePage = (props) => {

    const { agences } = useSelector((state) => state.agences);
    const dispatch = useDispatch();
    const [agence, setAgence] = useState(null);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addAgence = (data,clearForm) => {
        handleClose();
        console.log(data);
        if(data.id){

            updateAgence(data,(err) => {
                console.log(err);
            }, (resp) => {
                console.log(resp);
                clearForm();
    
            notify("L'agence a été modifié avec succés!");
            dispatch(fetchAgences());
    
            });



            return;
        }

        createAgence(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            clearForm();

            notify("L'agence a été ajouté avec succés!");
            dispatch(fetchAgences());

        });
    }


    const onAgenceSelected = (id) => {
        setAgence(agences.find((a) => a.id === id));
        setOpen(true);
    }

    const onDelete = (id) =>{
        handleClose();

        deleteAgence(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setAgence(null);

        notify("L'agence a bien été supprimé!");
        dispatch(fetchAgences());

        })
    }


    useEffect(() => {
        dispatch(fetchAgences());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
        <div className="header">
                <div className="title">
                    Liste des agences
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable 
            rows={[...agences].sort((a,b) => sortByProp(a, b, 'nom'))} 
            head={Object.keys(new Agence())} 
            onOptionClicked={onAgenceSelected}
            
            
            />

        </div>
        <Dialog onClose={(e) => handleClose(e) }  open={open} >

        <Transform 
        onSubmit={addAgence} 
        mandatories={["nom", "adresse"]} 
        entity={new Agence()} 

            toUpdate={agence} 
        onDelete={onDelete}
        onCancel={()=> handleClose()}
        
        
        />
                </Dialog>

    </div>

}


export default AgencePage;