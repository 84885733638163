


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TYPE_FRAIS_URL } from "../../settings/APISetting";
import { sortByProp } from "../../settings/themeSettings";



export const fetchTypeFrais = createAsyncThunk('fetch-type-frias', async (page = 1) => {


    const response = await fetch(`${TYPE_FRAIS_URL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});


export const createTypeFrais = (typeFrais, err, cb) => {
    fetch(TYPE_FRAIS_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(typeFrais)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}





export const updateTypeFrais = (typeFrais, err, cb) => {
    fetch(`${TYPE_FRAIS_URL}/${typeFrais.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(typeFrais)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}






export const deleteTypeFrais = (id, err, cb) => {
    fetch(`${TYPE_FRAIS_URL}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        cb(resp);

    })
        .catch(err);
}




const typeFraisSlice = createSlice({

    name: 'typeFrais',
    initialState: {typeFrais: [], typeFraisFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTypeFrais.fulfilled, (state, action) => {

            state.typeFrais =[...action.payload].sort((a,b) => sortByProp(a,b, 'libelle'));
            state.typeFraisFetchStatus = 'success';
        }).addCase(fetchTypeFrais.pending, (state, action) => {
            state.typeFraisFetchStatus = 'loading';

        }).addCase(fetchTypeFrais.rejected, (state, action) => {

            state.typeFraisFetchStatus = 'error';

        });
    }


});
export default typeFraisSlice;