
import { Button, Typography } from '@mui/material';
import './voucher.scss';
import { FilterAlt, Refresh } from '@mui/icons-material';
import { Search, SearchIconWrapper, StyledInputBase } from '../BFacturation/BFacturation';
import { COLORS } from '../../settings/themeSettings';
import SearchIcon from '@mui/icons-material/Search';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { fetchVoucher } from '../../data/slices/voucherSlice';
import StyledTable from '../../components/table-component/StyledTable';
import { useParams } from 'react-router';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import VoucherPDF from '../../components/vouvherPDF/VoucherPDF';

import {  dateFormatter } from "../../settings/themeSettings";



const Vouchers = (props) => {


    const { voucher, voucherFetchStatus } = useSelector((state) => state.vouchers);
    const dispatch = useDispatch();
    const { id, type, vid } = useParams();

    useEffect(() => {
        dispatch(fetchVoucher(vid));
    }, []);




    return (<div className="circuit-page">
        <div className="header">
            <div className="title">
                Details voucher
            </div>


            <Button
                variant="contained"
                color="secondary"
                disabled={!(voucher && voucher.prestataire?.courriel)}
                onClick={() => {
                    console.log(voucher);   
                    let voucherbody = `
Bonjour, 

Merci de recevoir le voucher N° ${voucher?.numero}.

[ Hebergement ]\n
${voucher.sejours.map((s) => `Du ${dateFormatter(s.dateDebut)} au ${dateFormatter(s.dateFin) }, régime : ${s.regime}, pax : ${s.pax}, nuitée : ${s.nuitee}. \n`)}

[ Rooming ]
${
voucher.sejours.length > 0 ? `${`
${
voucher.sejours[0].circuit.isGroup ? `${voucher.sejours[0].circuit.groups.map((r) => `Groupe :  ${r.libelle}, Pax :  ${r.pax} \n`)}` : `${voucher.sejours[0].circuit.rooming.map((r) => `${r.civilite}  ${r.nom} ${r.prenom} \n`)}`
}
`

}` : ``
}
Bonne reception. `;





                    var link = "mailto:"
                        + "?to=" + voucher.prestataire?.courriel 
                        + "&subject=" + encodeURIComponent("Voucher N°" + voucher?.numero + " de L'Africa 4 Tourism")
                        + "&body=" + encodeURIComponent(voucherbody)
                        ;

                    window.location.href = link;
                }} >
                Envoyer voucher par E-mail

            </Button>




        </div>


        {
            (voucherFetchStatus == 'loading') ?
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <PDFViewer style={{ width: '100%', height: '100vh' }} >
                    <VoucherPDF voucher={voucher} />
                </PDFViewer>
        }

    </div>);
}



export default Vouchers;
