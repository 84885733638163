import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import BaseParameter from '../../data/schemas/baseParameter';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';
import { createCompagnieVol, deleteCompagnieVol, fetchCompagnieVol, updateCompagnieVol } from '../../data/slices/compagnieVolSlice';
import CompagnieVol from '../../data/schemas/compagnieVol';




const CompagnieVolPage = (props) => {

    const { compagnieVols } = useSelector((state) => state.compagnieVols);
    const dispatch = useDispatch();

    const [compagnieVol, setCompagnieVol] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addCompagnieVol = (data, cb) => {
        handleClose();
        console.log(data);


        if (data.id) {

            updateCompagnieVol(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
                notify("le programme a été modifié avec succés!");
                dispatch(fetchCompagnieVol());


            });



            return;
        }

        createCompagnieVol(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();
            notify("Le programme a  été ajouté avec succés!");
            dispatch(fetchCompagnieVol());
        });
    }

    const onCompagnieSelected = (id) =>{ 
        
        setCompagnieVol(compagnieVols.find((p) => p.id === id));
        setOpen(true);
    
    }

    const onDelete = (id) => {
        handleClose();

        deleteCompagnieVol(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setCompagnieVol(null);

            notify("Le programme a bien été supprimé!");
            dispatch(fetchCompagnieVol());

        });
    }


    useEffect(() => {
        dispatch(fetchCompagnieVol());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des compagnies
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...compagnieVols].sort((a,b) => sortByProp(a, b, 'nom'))}
                head={Object.keys(new CompagnieVol())}
                onOptionClicked={onCompagnieSelected}


            />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

            <Transform
                onSubmit={addCompagnieVol}
                mandatories={["nom"]}
                entity={new CompagnieVol()}

                toUpdate={compagnieVol}
                onDelete={onDelete}
                onCancel={() => handleClose()}


            />
        </Dialog>

    </div>

}


export default CompagnieVolPage;