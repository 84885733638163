
import * as React from 'react';
import './login.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useToken } from '../../auth/useToken';
import { useState } from 'react';
import { AUTH_URL } from '../../settings/APISetting';
import { COLORS } from '../../settings/themeSettings';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../../assets/logo-aft4.png';
import { notify } from '../../settings/toastSetting';



const styles = {
    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 35px',
        width: '500px',
        //height: '500px',
        fontFamily: 'Alegreya',

        flexDirection: 'column',
        //  border: '1px solid white',
        '& > :not(style)': {
            m: 1
        },
    },
    title: {
        color: COLORS.secondary,
        marginBottom: '125px',

    }
};

export function LoginForm(props) {

    const navigateTo = useNavigate();
    const [token, setToken] = useToken();
    const [credential, setCredential] = useState({
        username:'',
        password: ''
    });

    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    if(token){
      navigateTo("/");
    }



    const handleLogin = () => {
      // 
      setHasError(false);
      setLoading(true);

      console.log(credential);
      fetch(AUTH_URL, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credential)
    }).then((resp) => {
 
        return resp.json();

    })
        .then(async (json) =>{
            console.log(json.code);
            if(json.code != 200){
                setHasError(true);
            }
            if(json.token){
                console.log(json); 
                setToken(json.token);
                navigateTo('/dashboard');
                
            }
            setLoading(false);

        })
        .catch(err => {
            setLoading(false);
            notify("Une erreur s'est produite actualisez puis réessayez!", "error");

            console.log(err);
        });
    }





    return (

        <Box
            sx={styles.form}
        //    component="main" 
        >
            <img  src={logo} />
            <hr />
            <Typography

                variant="h3"
                sx={{ ...styles.title }}
            >{`BIENVENUE\nSUR\nTRAVEL MANAGER`}</Typography>
            <div style={{height: '10px'}}></div>

        { hasError &&   <div className="error">
                Identifiant ou mot de passe incorrecte!
            </div>}

            <TextField
                margin="normal"
                required
                fullWidth
                name="Identifiant"
                label="Identifiant"
                InputProps={{ style: { color: COLORS.secondary, borderColor: COLORS.tertiary } }}
                color="secondary"
                focused
                placeholder='Identifiant'
                onChange={e => setCredential({...credential, username: e.target.value})}
            />
            <OutlinedInput
                margin="normal"
                required
                fullWidth
            
                color="secondary"
                placeholder='Mot de passe'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff style={{fill: COLORS.secondary}} /> : <Visibility style={{fill: COLORS.secondary}} />}
                    </IconButton>
                  </InputAdornment>
                }

                style={{ color: COLORS.secondary, borderColor: COLORS.secondary }}
                focused
                onChange={e => setCredential({...credential, password: e.target.value})}

            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: "white" }}
                color="secondary"
                onClick={handleLogin}
                disabled={loading || !(credential.username && credential.password)}
            >
             { loading? "Patientez SVP...":"se connecter"}
            </Button>
        </Box>


    );
}


const LoginPage = (props) => {

    return (
        <div className='login-container'>
            <div className='cover'>
                <LoginForm />
            </div>
        </div>
    );


}

export default LoginPage;