import styled from "@emotion/styled";
import { useState } from "react";
import { COLORS, dateFormatter, priceFormatter } from "../../settings/themeSettings";
import "./styledTable.scss";
import {
    TablePagination,
    tablePaginationClasses as classes,
  } from '@mui/base/TablePagination';
import { Info, PanoramaFishEye, Tune } from "@mui/icons-material";
import { useNavigate } from "react-router";





const blue = {
    50: '#F0F7FF',
    200: '#A5D8FF',
    400: '#3399FF',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
  };



const nameFormatter = (name) => {
const underscoreRegex = new RegExp("_");
const numeroRegex = new RegExp("numero");
if(name == "createdAt"){
  return "Date";
}
return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
}

const Root = styled('div')(
    ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 6px;
      color : ${COLORS.secondary};
      text-transform : capitalize;
    }
  
    th {
      background-color: ${COLORS.secondary};
      color: ${theme.palette.common.white};

    }
    `,
  );
  
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .${classes.spacer} {
      display: none;
      color: ${COLORS.secondary};

    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      color: ${COLORS.secondary};

  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
        color: ${COLORS.secondary};

      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${COLORS.secondary};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
      color: ${COLORS.secondary};

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: transparent;
      color: ${COLORS.secondary};
      & > * {
        color: ${COLORS.secondary};

      }

      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
    `,
  );
  



const StyledTable = ({head = [], rows = [], onOptionClicked}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigateTo = useNavigate();

  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const handleChangePage = (
      event,
      newPage,
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    

  

 return    <Root sx={{ width: 500, maxWidth: '100%' }}>
    <table aria-label="custom pagination table">
      <thead>
        <tr>
            {
                head.map((title, idx) => <th key={idx}> {nameFormatter(title)} </th> )
            }
            <th style={{ 
          
              width: '50px'
          
          }}>Options </th>
        </tr>
      </thead>
      <tbody>
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row,idx) => (
          <tr key={idx}>
            {
                head.map((title, titleIdx) =><td key={titleIdx} style={{ minWidth: 120, width: 150 }} align="right">
                        {/montant|prix|tarif|depouillement|marge$/.test(title)? priceFormatter(row[title]):( (/date|createdAt/.test(title))? dateFormatter(row[title]) : row[title] )}
                 </td>)
            }
            
     
      
            <td style={{ 
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              cursor: 'pointer'
          
          }}> <Info onClick={()=> onOptionClicked(row.id)} style={{fill: COLORS.secondary}}  /> </td>
          </tr>
        ))}

        {emptyRows > 0 && (
          <tr style={{ height: 34 * emptyRows }}>
            <td colSpan={3} />
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
           // colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={"lignes par page"}
            slotProps={{
              select: {
                'aria-label': 'rows per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </tr>
      </tfoot>
    </table>
  </Root>
}


export default StyledTable;