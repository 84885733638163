
class Client {
    constructor(nom, prenom, structure, code, email, telephone, pays, ville, adresse) {

        this.nom = nom;
        this.prenom = prenom;
        this.structure = structure || false;
        this.code = code;
        this.email = email;
        this.telephone = telephone;
        this.pays = pays;
        this.ville = ville;
        this.adresse = adresse;
        this.hasError = () =>  {
            for(let prop in this){
                if(this[prop] == undefined){
                    return prop;
                }
            }
        }
        
    }
}


export default Client;