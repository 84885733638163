class Sejour {
    constructor(date, dateFin, nuitee, hotel, prix_unitaire, type_chambre, regime,  pax, montant, categorie_chambre) {

        this.dateDebut = date;
        this.dateFin = dateFin;

        this.hotel = hotel;
        this.regime = regime;
        this.typeChambre = type_chambre || '';

        this.categorieChambre = categorie_chambre || '';
        this.nuitee = nuitee;

        this.pax = pax;
        this.prixUnitaire = prix_unitaire;
        this.montant = montant;
    }
}


export default Sejour;