import { Autocomplete, Box, Button, Dialog, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DateField, LocalizationProvider, frFR } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { COLORS, toEuro } from "../settings/themeSettings";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";





const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1000px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}





function AddPaymentDialog(props) {
    const { onClose, open } = props;
    const paymentMethods = ['compte', 'Chéque', 'carte de crédit', 'Autre'];

    const [modePaiement, setModePaiement] = useState(paymentMethods[0]);
    const [montant, setMontant] = useState(0);
    const [date, setDate] = useState(dayjs(new Date()));
    const [commentaire, setCommentaire] = useState('');
    const [autre, setAutre] = useState('');


    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        onClose();
    };


    const addPayment = () => {
        const payment = {
            methode: (modePaiement == 'Autre') ? autre : modePaiement,
            montant,
            date: date ? date.toISOString().split('T')[0] : null,
            commentaire
        }

        onClose(payment);
    }

    useEffect(() => {
        setModePaiement(null);
        setCommentaire(null);
        setDate(null);
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <Dialog onClose={handleClose} open={open} maxWidth={'lg'} minWidth={'700px'} >
                <DialogTitle
                    sx={{
                        backgroundColor: COLORS.secondary,
                        color: 'white',
                        mb: 5
                    }}
                >Ajouter un paiement</DialogTitle>


                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '400px',
                        padding: '10px 0'

                    }}

                >

                    <Box
                        width={'300px'}
                    >
                        <Typography
                            variant="p"
                            sx={styles.pathtitle}

                        >
                            Mode de paiement
                        </Typography>
                        <Autocomplete
                            value={modePaiement}
                            onChange={(event, newValue) => {
                                setModePaiement(newValue);
                            }}
                            inputValue={modePaiement}
                            onInputChange={(event, newInputValue) => {
                                setModePaiement(newInputValue);
                            }}
                            options={paymentMethods}
                            sx={{ width: 300, mt: '15px' }}

                            color="tertiary"
                            renderInput={(params) => <TextField {...params} label="Paiement" />}
                            clearIcon={false}

                        />

                        {
                            (modePaiement == "Autre") && (
                                <TextField

                                    margin="normal"
                                    required
                                    fullWidth
                                    name="autre"
                                    label="Précisez le mode de paiment"
                                    InputProps={{ style: { height: '45px' } }}
                                    color="tertiary"
                                    focused
                                    placeholder='mode de paiement'
                                    onChange={(e) => setAutre(e.target.value)}

                                />

                            )
                        }

                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="Montant"
                            label="Montant"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            focused
                            placeholder='Montant en CFA'
                            type='number'
                            onChange={(e) => setMontant(e.target.value)}
                        />

                        <TextField

                            margin="normal"
                            fullWidth
                            name="Montant en EURO"
                            label="Montant En EURO"
                            InputProps={{ style: { height: '45px' } }}
                            color="tertiary"
                            placeholder='Montant en Euro'
                            value={toEuro(montant)}
                            disabled
                        />


                        <DateField
                            label="Date du paiement"
                            color="tertiary"
                            focused
                            fullWidth
                            sx={{ mt: 2.5 }}
                            InputProps={{ style: { height: '45px' } }}
                            onChange={(e) => setDate(e)}
                            format="DD-MM-YYYY"
                        // defaultValue={dayjs(new Date())}


                        />


                        <TextField

                            margin="normal"
                            required
                            fullWidth
                            name="Commentaire"
                            label="Commentaire"
                            InputProps={{ style: { height: '100px' } }}
                            color="tertiary"
                            focused
                            multiline={true}
                            onChange={(e) => setCommentaire(e.target.value)}
                        />



                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                        mt: 4,
                        pb: 5,
                        pr: 2
                    }}

                >


                    <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={addPayment}
                    >
                        Valider
                    </Button>

                </Stack>

            </Dialog>

        </LocalizationProvider>
    );
}


export default AddPaymentDialog;