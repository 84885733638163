import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { fetchFacturesForDossier } from "../../data/slices/facturesDossierSlice";
import { Button, Typography } from "@mui/material";
import { FilterAlt, Refresh } from "@mui/icons-material";
import { useEffect } from "react";
import { COLORS } from "../../settings/themeSettings";
import { Search, SearchIconWrapper, StyledInputBase } from "../BFacturation/BFacturation";
import StyledTable from "../../components/table-component/StyledTable";
import SearchIcon from '@mui/icons-material/Search';

const FacturesDossierPage = (props) => {
    const { id, type } = useParams();

    const navigateTo = useNavigate();
    const onOptionClicked = (_id) => {
        navigateTo(`/dossiers/${type}/${id}/factures/${_id}`);
    }

    const { factures, factureFetchStatus } = useSelector((state) => state.facturesDossier);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFacturesForDossier(id));
    }, [id]);




    return (<div className="circuit-page">
        <div className="header">
            <div className="title">
                Liste des factures
            </div>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => { navigateTo(`/dossiers/${type}/${id}/facturation`) }}
            >
                Ajouter une facture

            </Button>
        </div>



        <div className="table-header">

            <div className="tools">
                <div className="btn">
                    <Refresh style={{ fill: COLORS.secondary }} />
                    <Typography style={{ color: COLORS.secondary, fontSize: '1em', fontWeight: 'bold' }} >Reset</Typography>
                </div>

                <div className="btn">
                    <FilterAlt style={{ fill: COLORS.secondary }} />
                    <select>
                        <option value="">Tout</option>
                        <option value="">N° Facture</option>
                        <option value="">Date </option>
                        <option value="">Type </option>
                    </select>
                </div>
            </div>
            <div className="searchbar">


                <Search>
                    <SearchIconWrapper>
                        <SearchIcon style={{ fill: COLORS.secondary }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Recherche"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </div>


        </div>
        {
            factureFetchStatus == 'loading' ?
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                :

                <StyledTable rows={factures} head={['date', 'numero', 'commentaire', 'etat']} onOptionClicked={onOptionClicked} />

            //   <UpdatedTable rows={circuits} head={['programme', 'toClient', 'methodePaiement', 'numeroFacture', 'Statut']} ></UpdatedTable>

        }

    </div>);
}


export default FacturesDossierPage;