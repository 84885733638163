import { Autocomplete, Box, Button, Dialog, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useState } from "react";
import { COLORS } from "../settings/themeSettings";


const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1000px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}



function AddBillDialog(props) {
    const { onClose, open } = props;

    const [frais, setFrais] = useState('');
    const [montantCFA, setMontantCFA] = useState('');
    const [montantEuro, setMontantEuro] = useState('');



    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        onClose();
    };


    const addBill = () => {
        const bill = {
            libelle : frais,
            montant : montantCFA
        }

        onClose(bill);
    }


    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'lg'} minWidth={'700px'} >
            <DialogTitle
                sx={{
                    backgroundColor: COLORS.secondary,
                    color: 'white',
                    mb: 5
                }}
            >Ajouter des frais</DialogTitle>


            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: '400px',
                    padding: '10px 0'

                }}

            >

                <Box
                    width={'300px'}
                >
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}

                    >
                        Information sur le voyage
                    </Typography>

                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        name="Frais de service"
                        label="Frais de service"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Frais de service'
                        onChange={(e) => setFrais(e.target.value)}
                    />


                    <TextField
                    type="number"

                        margin="normal"
                        required
                        fullWidth
                        name="Montant en CFA"
                        label="Montant en CFA"
                        InputProps={{ style: { height: '45px' } }}
                        color="tertiary"
                        focused
                        placeholder='Montant en CFA'
                        onChange={(e) => setMontantCFA(e.target.value)}
                    />






                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    mt: 4,
                    pb: 5,
                    pr: 2
                }}

            >


                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addBill}
                >
                    Valider
                </Button>

            </Stack>

        </Dialog>
    );
}

export default AddBillDialog;

