import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo-aft4.png";
import { COLORS, dateFormatter, priceFormatter, toEuro, toFrench } from '../../settings/themeSettings';
import FactureFooter from '../FactureFooter';
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingVertical: 30,
        justifyContent: 'space-between'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    logoText: {
        fontSize: 12,
        color: 'black',
        paddingLeft: '35px'
    },
    title: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleRight: {
        fontSize: 10,
        color: 'black',
        fontWeight: 'bold',
        width: '100%',
        // height: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2
    },
    titleSpan: {
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: 2
    },
    newtitleStyle: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
        alignSelf: 'flex-start',
        marginVertical: 2,
        paddingHorizontal: 5,
        paddingVertical: 2,
        backgroundColor: COLORS.gray,
        borderRadius: 2



    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginVertical: 2,
        backgroundColor: COLORS.gray,
        paddingHorizontal: 5,
        paddingVertical: 2
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        borderBottom: '0.5px solid black',
        marginVertical: 2
    },
    tableRowTotal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginHorizontal: 2,
        paddingHorizontal: 5,
        border: '0.5px solid black',
        marginVertical: 5,
        gap: '10px',
        backgroundColor: COLORS.gray
    }

});




const FactureDossierPDF = ({ facture , euro = false}) => {

    return <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    minHeight: 80,
                    padding: 5
                }}>
                    <Image
                        style={{
                            height: 90,
                            width: 200
                        }}
                        source={logo} />
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        minHeight: 80,
                        paddingHorizontal: '25px',
                        marginTop: 20
                    }}

                >

                    <View

                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: .3,
                            alignSelf: 'flex-end'


                        }}


                    >
                        <View style={{
                            height: 30,
                            width: '100%',
                            backgroundColor: COLORS.gray,
                            marginBottom: 5,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        > <Text style={{
                            fontSize: 18,
                            color: 'black',
                            fontWeight: 'bold'
                        }}>FACTURE</Text></View>
                        <View style={styles.title}><Text style={{ ...styles.titleSpan }}> Numéro :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {facture?.numero}  </Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> date :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}>{dateFormatter(facture?.date) }</Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> Dossier :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> {facture?.dossier?.reference}  </Text></View>


                    </View>

                    <View

                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            flex: .4,
                            alignSelf: 'flex-end'

                        }}


                    >
                        <View style={styles.title}><Text style={{ ...styles.titleSpan }}> Code :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> </Text></View>

                        <View style={styles.title}><Text style={{ ...styles.titleSpan }}> Client :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}> </Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> Adresse :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}></Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> Téléphone :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}></Text></View>
                        <View style={styles.title}><Text style={styles.titleSpan}> Email :</Text> <Text style={{ ...styles.titleSpan, fontWeight: 'bold' }}></Text></View>


                    </View>


                </View>




                {facture && facture.frais?.length > 0 && <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    <Text style={styles.newtitleStyle}>Désignations</Text>
                    <View style={styles.tableHeader}>

                        <Text style={{ width: '40%', fontSize: 10 }}>Désignations</Text>
                        <Text style={{ width: '40%', fontSize: 10 }}>Prix unitaire</Text>
                        <Text style={{ width: '20%', fontSize: 10 }}>Nombre</Text>


                    </View>


                    {
                        facture.frais?.map((e, idx) => <View key={idx} style={styles.tableRow}>

                            <Text style={{ width: '40%', fontSize: 10 }}> {e.designation} </Text>
                            <Text style={{ width: '40%', fontSize: 10 }}>{euro ? toEuro(e.prixUnitaire) : priceFormatter(e.prixUnitaire)}</Text>
                            <Text style={{ width: '20%', fontSize: 10 }}>{e.quantite} </Text>

                        </View>)
                    }

                    <View style={styles.tableRowTotal}>

                        <Text style={{ width: '20%', fontSize: 12, fontWeight: 'bold' }}> Total </Text>

                        <Text style={{ width: '60%', fontSize: 10, fontWeight: 'bold' }}>

                            {
                              euro ?
                              toEuro(facture.frais?.reduce((s, c) => ({ montant: s.montant + (c.prixUnitaire * c.quantite) }), { montant: 0 }).montant || 0) :
                            priceFormatter(facture.frais?.reduce((s, c) => ({ montant: s.montant + (c.prixUnitaire * c.quantite) }), { montant: 0 }).montant || 0)
                           
                            }

                        </Text>

                    </View>

                </View>}




                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 5
                }}>
                    {/* <Text style={{ width: '100%', fontSize: 10, fontWeight: 'bold' }}>
                        Présente facture arrêtée à {toFrench(Number(
                            facture?.frais?.reduce((s, c) => ({ montant: s.montant + (c.prixUnitaire * c.quantite) }), { montant: 0 }).montant

                        )
                        )}
                    </Text> */}
                </View>

                <View style={{
                    paddingHorizontal: '25px',
                    marginTop: 10

                }}>
                    <Text style={{ width: '100%', fontSize: 10, fontWeight: 'bold' }}>
                        Le client

                    </Text>
                </View>

            </View>


            <FactureFooter />


        </Page>
    </Document>
}

export default FactureDossierPDF;





