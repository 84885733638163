
import { Box, Grid, Paper } from '@mui/material';
import * as React from 'react';
import NavigationBar from '../../components/navigation/Navigation';
import Sidebar from '../../components/sidebar/Sidebar';
import { Outlet } from 'react-router';
import './home.scss';

import fetchIntercept from 'fetch-intercept';



fetchIntercept.register({
  request: function (url, config) {
      // Modify the url or config here
      console.log("setting interceptor");
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      return [url, config];
  },

  requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
  },

  response: function (response) {
      // Modify the reponse object
      return response;
  },

  responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
  }
});

const styles = {

    content: {

        // border: '1px solid red',
        width: '100%',
        overflow: 'scroll',
        padding: '15px 15px',
        minHeight: '100vh',
        height: '100%',
        position: 'relative'


    }
}


const HomePage = (props) => {

    const [sidebar, setSideBar] = React.useState(2);
   

    
    return <div className='App'>
        <Grid container  >
            <Grid item xs={12} minWidth={'1200px'} width={"100%"}>

                <NavigationBar  onHambergerClick ={()=> sidebar == 2 ? setSideBar(0) : setSideBar(2)} />
            </Grid>
            <Grid item xs={12} container width={ sidebar == 2? '1650px' : '1200px'} minHeight={'100vh'}  style={{ overflowX: 'scroll', flexWrap:'nowrap'}}>

                <Grid item xs={2} style={{display: sidebar == 2? 'block' : 'none'}} width={'250px'} component={Paper} elevation={3}>
                    <Sidebar />
                </Grid>
                <Grid item xs={sidebar == 2? 9 : 12} style={{position: 'relative',minHeight: '100vh'}} minWidth={'1200px'} minHeight={'100vh'} width={'100%'}>
                    <Box
                        sx={styles.content}
                    >

                        <Outlet />
                      


                    </Box>
                </Grid>


            </Grid>

        </Grid>

    </div>


}

export default HomePage;