import { useNavigate, useParams } from 'react-router';
import './detailsCircuit.scss';
import { useEffect, useState } from 'react';
import { fetchCircuit, fetchVouchers, switchCircuit } from '../../data/slices/circuitSlice';
import UpdatedTable from '../../components/updated-table/StyledTable';
import { Box, Button, Stack, Switch, Typography } from '@mui/material';

import WestIcon from '@mui/icons-material/West';
import { COLORS, calculateFrais, dateFormatter, priceFormatter } from '../../settings/themeSettings';
import { DocumentScanner, Print, Update } from '@mui/icons-material';
import Sejour from '../../data/schemas/sejour';
import Excursion from '../../data/schemas/excursion';
import Transfert from '../../data/schemas/transfert';
import Location from '../../data/schemas/location';
import StyledTable from '../../components/table-component/StyledTable';
import { BlobProvider } from '@react-pdf/renderer';
import JournalPDF from '../../components/JournalPDF/JournalPDF';

import _ from 'lodash';
import { notify } from '../../settings/toastSetting';

const styles = {
    container: {
        // border: '1px solid blue',
        minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}




const DetailsCircuit = (props) => {
    const { id, type } = useParams();

    const [loading, setLoading] = useState(false);
    const [circuit, setCircuit] = useState({});
    const [tab, setTab] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const navigateTo = useNavigate();

    useEffect(() => {





        setLoading(true);

        fetchCircuit(id)
            .then((response) => {

                if (response.status > 201) {
                    throw response;
                }

                return response.json();
            })
            .then(async (resp) => {
                setLoading(false);
                console.log(resp);
                // notify("Success","success");

                switch (resp.type) {
                    case 'circuit': {
                        setTabs(['Hebergements', 'Rooming', 'Excursions', 'Transferts', 'Locations', 'Enveloppe', 'Frais']);

                    } break;

                    case 'sejour': {
                        setTabs(['Hebergements', 'Rooming', 'Excursions', 'Transferts', 'Locations', 'Frais']);

                    } break;

                    case 'excursion': {
                        setTabs(['Excursions', 'Rooming', 'Enveloppe', 'Frais']);

                    } break;

                    case 'transfert': {
                        setTabs(['Transferts', 'Frais']);

                    } break;

                }


                resp.toClient = resp.toClient?.libelle;
                resp.programme = resp.programme?.libelle;


                resp.hotels.map((hotel) => {
                    hotel.hotel = hotel.hotel?.nom;
                    return hotel;
                });

                resp.excursions.map((ex) => {

                    ex.chauffeur = ex.chauffeur?.nomComplet;
                    ex.guide = ex.guide?.nomComplet;
                    ex.excursion = ex.type?.libelle
                    return ex;
                });



                resp.transferts.map((tr) => {

                    tr.chauffeur = tr.chauffeur?.nomComplet;
                    tr.guide = tr.guide?.nomComplet;
                    tr.vehicule = (tr.vehicule?.modele + ' - ' + (tr.vehicule?.matricule ?? ''));

                    return tr;
                });

                resp.locations.map((lc) => {

                    lc.chauffeur = lc.chauffeur?.nomComplet;
                    lc.guide = lc.guide?.nomComplet;
                    lc.vehicule = (lc.vehicule.modele + ' - ' + (lc.vehicule.matricule ?? ''));
                    lc.agence = lc.agence?.nom;
                    return lc;
                });

                console.log(resp.excursions);

                setCircuit(resp);

                fetchVouchers(id).then((response) => {

                    if (response.status > 201) {
                        throw response;
                    }

                    return response.json();
                }).then((resp) => {
                    console.log(resp);
                    let _vouchers = resp.map((v) => {
                        v.prestataire = v.prestataire.nom;

                        return v;
                    });
                    setVouchers(_vouchers);

                }).catch(err => console.log(err));
            })
            .catch(err => {
                setLoading(false);

                console.log(err);
            })
            ;
    }, []);


    useEffect(() => {
        setTab(tabs[0]);
    }, [tabs]);


    return loading ?
        <div className="loader-container">
            <span className="loader"></span>
        </div>


        :


        (!!circuit) && <div className='details-circuit-container'>




            <Stack
                direction="row"
                justifyContent="flex-start"
                gap="15"
                alignItems="flex-start"

            >
                <div onClick={() => navigateTo(`/dossiers/${type}`)}
                    style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                    <WestIcon style={{ fill: 'white', fontSize: 28 }} />
                </div>
                <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '100%', ml: 2 }}>Détails {type} </Typography>

            </Stack>


            <Stack

                direction="row"
                justifyContent="space-between"

                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                        }}
                    />
                }


            >




                <Stack sx={{ width: '30%', mb: 5 }}>
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}
                    >
                        Détails {type}
                    </Typography>


                    <Stack sx={{ padding: '0 10px' }}>
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Réference:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.reference}  </span>
                        </Typography>


                        {(type == 'transfert') && <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Nom du client:</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.nomClient}  </span>
                        </Typography>}

                        {(type == 'circuit' || type == 'sejour') && <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Programme :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.programme} </span>

                        </Typography>}
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> TO / Client :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.toClient} </span>

                        </Typography>
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Total débours. :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{
                                priceFormatter(circuit.frais?.reduce((sum, current) => {
                                    return {
                                        montant: sum.montant + current.montant
                                    }
                                }, { montant: 0 }).montant)
                            } </span>

                        </Typography>

                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Montant de la facture :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{
                                priceFormatter(
                                    
                                    circuit.factureCircuits?.reduce((sum, current) => {

                                        if (current.type == 'AV' || current.type == 'FP') {
                                            return sum;
                                        }
                                        return {
                                            montant: sum.montant + calculateFrais(current.frais || [])
                                        }
                                    }, {
                                        montant: 0
                                    }).montant)
                            } </span>

                        </Typography>

                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Marge :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{
                                priceFormatter(
                                    
                                    circuit.factureCircuits?.reduce((sum, current) => {

                                        if (current.type == 'AV' || current.type == 'FP') {
                                            return sum;
                                        }
                                        return {
                                            montant: sum.montant + calculateFrais(current.frais || [])
                                        }
                                    }, {
                                        montant: 0
                                    }).montant - circuit.frais?.reduce((sum, current) => {
                                    return {
                                        montant: sum.montant + current.montant
                                    }
                                }, { montant: 0 }).montant )
                            } </span>

                        </Typography>




                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Commentaire: </span>
                            <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                {circuit.commentaire}
                            </span>
                        </Typography>

                    </Stack>





                </Stack>

                <Stack sx={{ width: '30%', mb: 5 }}>
                    <Typography
                        variant="p"
                        sx={styles.pathtitle}
                    >
                        Détails {type}
                    </Typography>


                    <Stack sx={{ padding: '0 10px' }}>
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Chauffeur :</span>       <span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit?.chauffeur?.nomComplet}  </span>
                        </Typography>
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Guide :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit?.guide?.nomComplet} </span>

                        </Typography>
                        <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                            <span> Vehicule :</span><span style={{ fontWeight: "bold", color: COLORS.secondary }} >{circuit.vehicule ? ((circuit?.vehicule.modele || '') + ' - ' + (circuit?.vehicule.matricule || '')) : ''} </span>

                        </Typography>
                        {
                            (type == 'circuit' || type == 'sejour') && <>

                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Date vol Arrivee: </span>
                                    <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                        {dateFormatter(circuit?.dateVolArrivee)  + ' - ' + (circuit?.heureVolArrivee ? (new Date(circuit?.heureVolArrivee)).toTimeString().split(' ')[0] : '')}
                                    </span>
                                </Typography>
                                <Typography sx={{ fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <span style={{ fontWeight: "bold", color: COLORS.tertiary }} >Date vol Depart: </span>
                                    <span style={{ fontSize: 14, fontWeight: "bold", color: COLORS.secondary }} >
                                        {dateFormatter(circuit?.dateVolDepart) + ' - ' + (circuit?.heureVolDepart ? (new Date(circuit?.heureVolDepart)).toTimeString().split(' ')[0] : '')}
                                    </span>
                                </Typography>
                            </>
                        }

                    </Stack>





                </Stack>
                <Stack
                    sx={{ width: '30%' }}
                    justifyContent="flex-start"
                    gap={2}
                    style={{ padding: '0 20px' }}
                >
                    <Button onClick={() => {
                        navigateTo(`/dossiers/${type}/${id}/pdf`)
                    }} color="secondary" variant="outlined" startIcon={<Print />}>
                        Imprimer dossier
                    </Button>
              
            

       {    circuit.envelops?.length > 0 && <BlobProvider document={<JournalPDF title={`Enveloppe ${ circuit.reference}`} data={circuit.envelops} heads={["frais", "montant", "depouillement"]} />}>
                        {({ blob, url }) => (
                        <a href={url}
                        style={{
                            p: '10px',
                            width: '100%',
                            border: '1px solid '+ COLORS.secondary,
                            borderRadius: '5pt',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontSize: '1em',
                            color: COLORS.secondary
                        }}
                        target="_blank"
                        >Imprimer enveloppe</a>
                        )}
                    </BlobProvider>}


                    <Button onClick={() => {
                        navigateTo(`/dossiers/${type}/${id}/factures`);
                    }} color="secondary" variant="outlined" startIcon={<DocumentScanner />}>
                        Facturation
                    </Button>
{!circuit?.cloture &&
                    <Button
                        onClick={() => navigateTo(`/dossiers/${type}/edit/${id}`)} color="primary" variant="outlined" startIcon={<Update />}>
                        Modifier
                    </Button>
}
                    <Stack direction="row" spacing="10px" alignItems="center"> 
                    <span style={{ fontWeight: "bold", color: COLORS.secondary }} >Clôturer </span>
                        <Switch defaultChecked={circuit?.cloture} onChange={(e) => {
                            
                            switchCircuit(id).then((resp)=>{
                                notify("Le dossier a été mis à jour!");
                                setCircuit({...circuit, cloture: !circuit.cloture});
                            }).catch(()=> {
                                notify("Erreur lors de la cloture du dossier!", "ERROR");

                            });


                        }}></Switch>
                    
                      <span style={{ fontWeight: "bold", color: COLORS.tertiary, fontSize: 10 }} >
                        {
                         ( circuit?.cloture &&  circuit?.switchedBy?.nomComplet )? `Dossier clôturé par ${circuit?.switchedBy?.nomComplet} le ${dateFormatter(circuit?.switchDate)}` : ''
                        }
                        </span>  
                    </Stack>
                    <span style={{fontSize: 10, color: COLORS.secondary}}>Seule la personne ayant clôturé le dossier peut le décloturer.</span>



                </Stack>






            </Stack>





            <div className="tabs">


                {
                    tabs.map((_tab, idx) => <div key={idx} className={(tab == _tab) ? "tab active" : "tab"} onClick={() => setTab(_tab)}>
                        {_tab}
                    </div>)
                }


            </div>





            {



                (!!circuit) && (() => {
                    let vue;



                    switch (true) {

                        case tab == 'Hebergements': vue = <>
                            <UpdatedTable rows={ _.sortBy([...circuit.hotels], (i) => new Date(i.dateDebut)) } head={Object.keys(new Sejour())}  totals={['montant']}  />
                            <span style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                fontSize: '.8em',
                                fontWeight: 'bold',
                                color: "white",
                                padding: '5px 10px',
                                background: COLORS.primary


                            }}>Vouchers</span>
                            <StyledTable rows={vouchers} head={['numero', 'prestataire']} onOptionClicked={(vid) => {
                                console.log(vid);
                                navigateTo(`/dossiers/${type}/${id}/voucher/${vid}`);
                            }} />
                        </>; break;
                        case tab == 'Rooming': vue = <UpdatedTable rows={circuit.isGroup ? circuit.groups : circuit.rooming} head={circuit.isGroup ? ['libelle', 'pax'] : ['civilite', 'nom', 'prenom']} />; break;
                        case tab == 'Excursions': vue = <UpdatedTable rows={circuit.excursions} head={Object.keys(new Excursion())} totals={['montant']} />; break;
                        case tab == 'Transferts': vue = <UpdatedTable rows={circuit.transferts} head={Object.keys(new Transfert())}  totals={['montant']}  />; break;
                        case tab == 'Locations': vue = <UpdatedTable rows={circuit.locations} head={Object.keys(new Location())}  totals={['montant']}  />; break;
                        case tab == 'Enveloppe': vue = <UpdatedTable rows={circuit.envelops} head={['frais', 'montant', 'depouillement']}  totals={['montant', 'depouillement']}  />; break;
                        case tab == 'Frais': vue = <UpdatedTable rows={circuit.frais} head={['frais', 'montant']}   totals={['montant']}  />; break;
                    }


                    return vue;
                })()


            }



        </div>
}



export default DetailsCircuit;