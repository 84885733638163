import React, { useEffect, useReducer, useState } from "react";
import "./FactureForm.scss";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { AirplaneTicket, CreditCard, Delete, PaymentsTwoTone } from "@mui/icons-material";
import Button from '@mui/material/Button';
import { COLORS, priceFormatter, theme, toEuro } from "../../settings/themeSettings";
import AddPaymentDialog from "../AddPaymentDialog";
import AddBillDialog from "../AddBillDialog";
import AddTicketDialog from "../AddTicketDialog";
import WestIcon from '@mui/icons-material/West';
import { useNavigate, useParams } from "react-router";
import { createFacture } from "../../data/slices/factureSlice";
import { notify } from "../../settings/toastSetting";


const styles = {
    container: {
        // border: '1px solid blue',
        //  minHeight: '1000px',
        width: '1200px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.gray,
        padding: '15px',
        borderRadius: '5px',
        height: 'fit-content'

    },
    pathtitle: {
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '0 10px',
        width: '100%',
        color: COLORS.tertiary
    },
    path: {
        width: '300px',
        height: '100%',
        //  border: '1px solid red'
    }
}










const FactureForm = (props) => {

    const [open, setOpen] = useState(false);
    const [openAddBill, setOpenAddBill] = useState(false);
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const [billets, setBillets] = useState([]);
    const [payments, setPayments] = useState([]);
    const [bills, setBills] = useState([]);
    const [commentaire, setCommentaire] = useState('');
    const [numeroDossier, setNumeroDossier] = useState('');
    const navigateTo = useNavigate();
    const {clientId} = useParams();
    const [totalFrais, setTotalFrais] = useState(0);
    const [totalPaiements, setTotalPaiements] = useState(0);

    const [loading, setLoading] = useState(false);


    const [tab, setTab] = useState('billets');


    const [fraisBillet, dispatchFrais] = useReducer((state, action) => {
        if (action.type == "remove") {

            return state - action.value;

        } else {
            return state + action.value;
        }

    }, 0);






    const handleClose = (ticketInfos) => {
        setOpen(false);
        console.log(ticketInfos);
        if (ticketInfos) {
            setBillets([...billets, ticketInfos]);
            dispatchFrais({ value: Number(ticketInfos.tarif) });
        }
    }


    const removeBill = (id) => {
        let _bills = bills.map((bill, idx) => {
            if (idx != id) {
                return bill;
            }
        }).filter((bill) => !!bill);
        setBills(_bills);
    }


    const removePayment = (id) => {
        let _payments = payments.map((payment, idx) => {
            if (idx != id) {
                return payment;
            }
        }).filter((payment) => !!payment);
        setPayments(_payments);
    }

    const removeTicket = (id) => {
        let tickets = billets.map((billet, idx) => {
            if (idx != id) {
                return billet
            } else {

                dispatchFrais({ type: "remove", value: billet.tarif })


            };
        }).filter((ticket) => !!ticket);
        console.log(tickets);
        setBillets(tickets);
    }



    const handleAddBillClose = (bill) => {
        setOpenAddBill(false);
        console.log(bill);
        if (bill) {
            setBills([...bills, bill]);
        }
    }

    const handleAddPaymentClose = (payment) => {
        setOpenAddPayment(false);
        console.log(payment);
        if (payment) {
            setPayments([...payments, payment]);
        }
    }


    const openDialog = () => {
        setOpen(true);
    }

    const openAddBillDialog = () => {
        setOpenAddBill(true);
    }


    const openAddPaymentDialog = () => {
        setOpenAddPayment(true);
    }

    const addFacture = () => {
        setLoading(true);
        const facture = {
            commentaire : commentaire,
            numeroDossier :numeroDossier,
            billets : billets,
            frais: [...bills, {"libelle" : "vente de billets", montant: fraisBillet}],
            paiements: payments,
            client: clientId
        };

        console.log(facture);
        createFacture(facture, (err) => {
           console.log(err);
           notify('Une erreur s\'est produite, actualisez puis réessayez!', 'error', 'BOTTOM_LEFT');
            setLoading(false);
        }, (facture) => {
            setLoading(false);

            //const _facture = JSON.parse(facture);

        //     if(!facture.id){
        //    notify('Une erreur s\'est produite, actualisez puis réessayez!', 'error', 'BOTTOM_LEFT');
        //         return;
        //     }
           if(facture.error){

            notify(facture.error, 'error', 'TOP_RIGHT');

            return;


           }
           if(!facture.id){
            notify("Une erreur s'est produite!", 'error', 'TOP_RIGHT');
            return;
           }



            navigateTo(`/billeterie/factures/${clientId}/details/${facture.id}`);

            console.log(facture);
        });
    }


    useEffect(() => {

        const newTotalFrais = bills.reduce((total, current) =>{ 
           return { montant: Number(total.montant)  + Number(current.montant) };
        
        }, { montant :0}).montant + fraisBillet;


        const newTotalPaiements = payments.reduce((total, current) =>{ 
            
           return {montant : Number(total.montant) + Number(current.montant) };
        }, { montant :  0}).montant;
        setTotalFrais(newTotalFrais);
        setTotalPaiements(newTotalPaiements);


    }, [bills, payments, fraisBillet]);



    return <Box
        sx={
            styles.container
        }>



        <Stack
            direction="row"
            justifyContent="space-between"
            gap="5"
            alignItems="flex-start"

        >
            <div onClick={() => navigateTo(`/billeterie/factures/${clientId}`)}
                style={{ background: COLORS.secondary, height: '65px', width: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: `${65 / 2}px`, cursor: 'pointer' }}>
                <WestIcon style={{ fill: 'white', fontSize: 28 }} />
            </div>
            <Typography variant="h4" sx={{ backgroundColor: COLORS.secondary, color: '#FFF', p: '10px', mb: 5, width: '1100px' }}>Nouvelle Facture</Typography>

        </Stack>
        <Stack

            direction="row"
            justifyContent="space-between"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }


        >




            <Stack sx={{ width: '35%', mb: 5 }}>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Détails Facture
                </Typography>


                <TextField
                    value='***-****-**-**'
                    margin="normal"

                    fullWidth

                    InputProps={{ style: { height: '45px' } }}
                    color="tertiary"
                    disabled
                />
                <TextField
                    margin="normal"
                    required
                    name="Reference dossier"
                    label="Reference dossier"
                    InputProps={{ style: { height: '45px' } }}
                    color="tertiary"
                    focused
                    placeholder='Reference dossier'
                    onChange={(e) => setNumeroDossier(e.target.value)}

                    fullWidth
                />


            </Stack>

            <Stack
                sx={{ width: '55%' }}
            >
                <TextField
                    margin="normal"
                    fullWidth
                    name="Commentaire"
                    label="Commentaire"
                    InputProps={{ style: { height: '145px' } }}
                    color="tertiary"
                    focused
                    multiline={true}
                    onChange={(e) => setCommentaire(e.target.value)}
                />

            </Stack>






        </Stack>
        <div className="tabs">
            <div className={(tab == "billets") ? "tab active" : "tab"} onClick={() => setTab("billets")}>
                Liste des Billets
            </div>
            <div className={(tab == "frais") ? "tab active" : "tab"} onClick={() => setTab("frais")}>
                Frais et Réglement
            </div>
        </div>
        {(tab == "billets") && <Stack
            direction="row"

            divider={
                <Box
                    component="hr"
                    sx={{
                        border: `1px solid ${COLORS.tertiary}`,
                    }}
                />
            }
            sx={{ minHeight: '250px' }}


        >


            <Box xs={{ ...styles.path }} width={'100%'}
            >


                <div className="spacer"></div>
                <Typography
                    variant="p"
                    sx={styles.pathtitle}
                >
                    Liste des billets
                </Typography>


                <TableContainer component={Paper} sx={{ mt: '15px' }}>
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Nom sur le billet</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Trajet </TableCell>

                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Aller</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Aller </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>N° de vol Retour</TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date vol Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Compagnie Retour </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant TTC </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant FA </TableCell>
                                <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                billets.map((billet, idx) => {
                                    return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                        <TableCell > {billet.numero} </TableCell>
                                        <TableCell > {billet.nom} </TableCell>
                                        <TableCell > {billet.trajet} </TableCell>

                                        <TableCell > {billet.numeroVol} </TableCell>
                                        <TableCell> {billet.dateVol  } </TableCell>
                                        <TableCell > {billet.compagnie} </TableCell>

                                        <TableCell > {billet.numeroVolRetour || '----------------'} </TableCell>
                                        <TableCell> {billet.dateVolRetour  || '----------------' } </TableCell>
                                        <TableCell > {billet.compagnieRetour  || '----------------'} </TableCell>
                                        <TableCell > {priceFormatter(billet.tarif)} </TableCell>
                                        <TableCell > {priceFormatter(billet.montantAchat)} </TableCell>



                                        <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removeTicket(idx)} /> </TableCell>

                                    </TableRow>

                                })

                            }

                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: '10px', width: '100%' }}
                    startIcon={<AirplaneTicket sx={{ fill: COLORS.secondary }} />}
                    onClick={openDialog}
                >
                    Ajouter un billet
                </Button>

            </Box>

        </Stack>
        }
        <Box
            sx={{
                width: '100%',
                minHeight: '300px'
            }}
        >

            {(tab == "frais") && <Stack
                direction="row"
                spacing={2}
                sx={{
                    '& > *': { width: '50%' },
                    height: '100%',
                    mt: 8,
                    padding: '0px 5px',

                }}
                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }
            >


                <Box sx={
                    {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10
                    }
                }>


                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 450 }} aria-label="simple table" >
                            <TableHead >
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Frais</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en CFA</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Montant en EURO</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    (fraisBillet > 0) && <TableRow sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                        <TableCell >Vente de billet </TableCell>
                                        <TableCell > {priceFormatter(fraisBillet) } </TableCell>
                                        <TableCell > {fraisBillet?toEuro(fraisBillet)  : 0} € </TableCell>
                                    </TableRow>

                                }

                                {
                                    bills.map((bill, idx) => {
                                        return <TableRow key={idx} sx={{ color: COLORS.secondary, border: `1px solid ${COLORS.secondary}`, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>
                                            <TableCell > {bill.libelle} </TableCell>
                                            <TableCell > {priceFormatter(bill.montant)} </TableCell>
                                            <TableCell > {bill.montant? toEuro(bill.montant): 0} € </TableCell>
                                            <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removeBill(idx)} /> </TableCell>

                                        </TableRow>
                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>





                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<PaymentsTwoTone sx={{ fill: COLORS.secondary }} />}
                        onClick={openAddBillDialog}
                    >
                        Ajouter des frais
                    </Button>
                </Box>
                <Box
                    sx={
                        {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            gap: 10,
                        }
                    }
                >



                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ border: `1px solid ${COLORS.secondary}` }}>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Mode de paiement</TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (CFA) </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> Montant (EURO) </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Date </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}>Commentaire </TableCell>
                                    <TableCell sx={{ backgroundColor: COLORS.secondary, color: '#FFF' }}> <Delete /> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    payments.map((payment, idx) => {
                                        return <TableRow key={idx} sx={{ color: COLORS.secondary, '& > *': { border: `1px solid ${COLORS.secondary}` } }}>

                                            <TableCell> {payment.methode} </TableCell>
                                            <TableCell> {priceFormatter(payment.montant)} </TableCell>
                                            <TableCell> {toEuro(payment.montant || 0)} </TableCell>
                                            <TableCell> {payment.date} </TableCell>
                                            <TableCell> {payment.commentaire} </TableCell>
                                            <TableCell >  <Delete style={{ fill: 'red', cursor: 'pointer' }} onClick={() => removePayment(idx)} /> </TableCell>


                                        </TableRow>

                                    })
                                }


                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<CreditCard sx={{ fill: COLORS.secondary }} />}
                        onClick={openAddPaymentDialog}
                    >
                        Ajouter un réglement
                    </Button>


                </Box>





            </Stack>



            }

            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={4}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: `1px solid ${COLORS.tertiary}`,
                            width: '0px !important'
                        }}
                    />
                }

            >


                <div className="total">
                    <div className="title">
                        Total Frais:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                        {priceFormatter(totalFrais) } 
                    </div>

                    
                </div>

                <div className="total">
                    <div className="title">
                        Total Réglement:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                        {priceFormatter(totalPaiements)} 
                    </div>

                    
                </div>
                <div className="total">
                    <div className="title">
                        Solde:
                        <div className="underline"></div>
                    </div>
                    <div className="price">
                       {priceFormatter(totalFrais - totalPaiements)} 
                    </div>

                    
                </div>


            </Stack>




            <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                    mt: 4,
                    mb: 4,
                    pb: 5
                }}

            >


                <Button
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    onClick={() => navigateTo(`/billeterie/factures/${clientId}`)}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addFacture}
                    disabled={loading}
                >
                 {loading? 'Patientez..' :  'Valider'}
                </Button>

            </Stack>


        </Box>


        <AddTicketDialog open={open} onClose={handleClose} />
        <AddBillDialog open={openAddBill} onClose={handleAddBillClose} />
        <AddPaymentDialog open={openAddPayment} onClose={handleAddPaymentClose} />
    </Box>
}



export default FactureForm;