import { configureStore } from "@reduxjs/toolkit";
import clientSlice from "../slices/clientStore";
import factureSlice from "../slices/factureSlice";
import billetsSlice from "../slices/billetsSlice";
import circuitSlice from "../slices/circuitSlice";
import chauffeurSlice from "../slices/chauffeurSlice";
import guideSlice from "../slices/guideSlice";
import vehiculeSlice from "../slices/vehiculeSlice";
import hotelSlice from "../slices/hotelSlice";
import agenceSlice from "../slices/agenceSlice";
import typeExcursionSlice from "../slices/typeExcursionSlice";
import programmeSlice from "../slices/programmeSlice";
import typeFraisSlice from "../slices/typeFraisSlice";
import toClientSlice from "../slices/toClientSlice";
import facturesDossierSlice from "../slices/facturesDossierSlice";
import currentUserSlice from "../slices/currentUserSlice";
import paiementsSlice from "../slices/paiementsSlice";
import userSlice from "../slices/userSlice";
import voucherSlice from "../slices/voucherSlice";
import transfertSlice from "../slices/transfertSlice";
import devisSlice from "../slices/devisSlice";
import compagnieVolSlice from "../slices/compagnieVolSlice";




const store = configureStore({
    reducer:{
        clients: clientSlice.reducer,
        factures: factureSlice.reducer,
        billets : billetsSlice.reducer,
        circuits : circuitSlice.reducer,
        chauffeurs : chauffeurSlice.reducer,
        guides : guideSlice.reducer,
        vehicules : vehiculeSlice.reducer,
        hotels : hotelSlice.reducer,
        agences : agenceSlice.reducer,
        typeExcursions : typeExcursionSlice.reducer,
        programmes: programmeSlice.reducer,
        typeFrais: typeFraisSlice.reducer,
        toClients: toClientSlice.reducer,
        facturesDossier: facturesDossierSlice.reducer,
        me: currentUserSlice.reducer,
        paiements : paiementsSlice.reducer,
        users : userSlice.reducer,
        vouchers : voucherSlice.reducer,
        transferts : transfertSlice.reducer,
        devis : devisSlice.reducer,
        compagnieVols: compagnieVolSlice.reducer

    }
});



export default store;