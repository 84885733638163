import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { COLORS, calculateDays, priceFormatter } from "../../settings/themeSettings";
import "./editableTable.scss";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import { Delete, Info, PanoramaFishEye, Tune } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { $CombinedState } from "@reduxjs/toolkit";





const blue = {
  50: '#F0F7FF',
  200: '#A5D8FF',
  400: '#3399FF',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};




const Root = styled('div')(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      text-align: left;
      padding: 6px;
      color : ${COLORS.secondary};
      text-transform : capitalize;
      &:last-of-type {
          width: 45px
      }
    }
  
    th {
      background-color: ${COLORS.secondary};
      color: ${theme.palette.common.white};

    }
    `,
);





const EditableTable = ({ reference = {}, choices = {}, data = [], operations = {}, showButton = true, onUpdate }) => {
  const [rows, setRows] = useState(data);
  const [registered, setRegistered] = useState([]);
  const nameFormatter = (name) => {
    const underscoreRegex = new RegExp("_");
    const numeroRegex = new RegExp("numero");
    return name.replace(underscoreRegex, " ").replace(numeroRegex, "N°").replaceAll(/([A-Z])/g, ' $1').trim();
  }
  // Avoid a layout jump when reaching the last page with empty rows.

  const addReference = () => {
    let _rows = [...rows, Object.assign({}, reference)];
    setRows(_rows);
    console.log(rows);
  }

  const getChoices = (prop) => {
    return choices[prop].options ? [choices[prop].options, choices[prop].optionValue, choices[prop].optionLabel] : [choices[prop]];
  }

  const hasChoices = (prop) => {
    let hasChoices = false;
    for (let _prop in choices) {
      if (_prop === prop) {
        hasChoices = true;
      }
    }
    return hasChoices;
  }

  const registerValue = (value, idx) => {

    registered[idx] = value;
    setRegistered(registered);

  }

  const valueHasChanged = (newValue, idx) => (registered[idx] != newValue);


  const hasOperations = (prop) => {
    let hasOp = false;
    for (let _prop in operations) {
      if (_prop === prop) {
        hasOp = true;
      }
    }

    return hasOp;

  }


  function triggerInput(id) {

    const input = document.getElementById(id);
    const event = new Event("input", { bubbles: true });
    if (input) {
      input.dispatchEvent(event);
    }


  }


  const calculateProduct = (labels, row, id) => {
    let p = labels.map((label) => row[label]).reduce((product, current) => {
      return { product: product.product * current };
    }, { product: 1 }).product;

    p = isNaN(p) ? 0 : p;
    triggerInput(id);
    return p;
  }
  const deleteLine = (idx) => {
    let lines = rows.filter((row, _idx) => idx != _idx);
    console.log(idx);
    setRows(lines);
  }



  const changeProp = (value, property, itemIdx) => {
    const rowUpdate = rows.map((row, idx) => {
      if (itemIdx === idx) {
        row[property] = value;
      }
      return row;
    });
    setRows(rowUpdate);
  }



  useEffect(() => {
    console.log(rows);
    onUpdate(rows);
  }, [rows]);





  return <Root sx={{ width: 500, maxWidth: '100%' }} className="editable">
    <table aria-label="custom pagination table">
      <thead>
        <tr>
          {
            // head.map((title, idx) => <th key={idx}> {title} </th> )

            Object.entries(reference).map((ref, idx) => {
              return <th key={idx}> {nameFormatter(ref[0])} </th>;
            })

          }
          <th>
            <Delete />

          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, idx) => (
          <tr key={idx}>
            {

              Object.entries(row).map((item, index) => {

                return <td key={index} style={{ minWidth: 120, width: 150 }} align="right">
                  {
                    hasChoices(item[0]) ?
                      <select onChange={(e) => changeProp(e.target.value, item[0], idx)}>
                        <option
                          selected={!item[1]} disabled={!item[1]}> Choisir</option>
                        {

                          getChoices(item[0])[0].map((choice, choiceIdx) => <option key={choiceIdx} value={getChoices(item[0])[1] ? choice[getChoices(item[0])[1]] : choice} selected={item[1] == (getChoices(item[0])[1] ? choice[getChoices(item[0])[1]] : choice)}>{getChoices(item[0])[2] ? choice[getChoices(item[0])[2]] : choice} </option>)
                        }

                      </select> :
                      (

                        hasOperations(item[0]) ? (() => {
                          let result;

                          switch (operations[item[0]].type) {
                            case '*': {
                              result = <input
                                type='text'
                                value={(() => {

                                 const result =  calculateProduct(operations[item[0]].labels, row);
                                 const identifier = Number(idx + '' + index);
                                 if (valueHasChanged(result, identifier)) {
                                   console.log('value has changed');
                                   changeProp(result, item[0], idx);
                                   registerValue(result, identifier);
                                 }

                                 return result;
                                })()




                              }
                              //  disabled
                                onChange={(e) =>{ 
                                  console.log(e.target.value);
                                  e.target.textContent(e.target.value)
                                  changeProp(e.target.value, item[0], idx);

                                }}

                              />;

                            } break;

                            case 'daydiff': {
                              result = <input
                                type='text'

                                value={(() => {
                                  const daydiff = calculateDays(row[operations[item[0]].labels[0]], row[operations[item[0]].labels[1]]);
                                  const identifier = Number(idx + '' + index);
                                  if (valueHasChanged(daydiff, identifier)) {
                                    console.log('value has changed');
                                    changeProp(daydiff, item[0], idx)
                                    registerValue(daydiff, identifier)
                                  }
                                  return daydiff;
                                })()}
                                disabled

                              />;

                            } break;
                            case 'daydiff+': {
                              result = <input
                                type='text'

                                value={(() => {
                                  const daydiff = calculateDays(row[operations[item[0]].labels[0]], row[operations[item[0]].labels[1]]) + 1;
                                  const identifier = Number(idx + '' + index);
                                  if (valueHasChanged(daydiff, identifier)) {
                                    changeProp(daydiff, item[0], idx)
                                    registerValue(daydiff, identifier)
                                  }
                                  return daydiff;
                                })()}
                                disabled

                              />;

                            } break;

                          }

                          return result;



                        })()
                          : <input
                            type={/date/.test(item[0]) ? 'date' : 'text'}
                            value={item[1]}
                            onChange={(e) => changeProp(e.target.value, item[0], idx)}

                          />
                      )
                  }

                </td>;
              })
            }


            <td sx={{ width: '35px' }}>
              <Delete sx={{ cursor: 'pointer' }} onClick={() => deleteLine(idx)} />
            </td>

          </tr>
        ))}


      </tbody>

    </table>

    {showButton && <Button
      variant="outlined"
      color="secondary"
      sx={{ mt: '10px', width: '100%' }}

      onClick={addReference}
    >
      Ajouter une ligne
    </Button>}
  </Root>
}


export default EditableTable;