import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEVIS_URL } from "../../settings/APISetting";

export const fetchDevis = createAsyncThunk('fetch-devis', async (devisId) => {


    const response = await fetch(`${DEVIS_URL + '/' +devisId}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});



export const fetchClientDevis = createAsyncThunk('fetch-client-devis', async (clientId) => {


    const response = await fetch(`${DEVIS_URL + '/client/' +clientId}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json()
});





const devisSlice = createSlice({
    name: 'devis',
    initialState: {fetchDevisStatus: '', devisList: [], devis: null, fetchDevisListStatus : ''},
    reducers: {},
    extraReducers: (builder) => {
        builder
    .addCase(fetchDevis.pending, (state, action) => {
        state.fetchDevisStatus = 'loading';
    })
    .addCase(fetchDevis.rejected, (state, action) => {
        state.fetchDevisStatus = 'error';
        
    })
    .addCase(fetchDevis.fulfilled, (state, action) => {
        state.fetchDevisStatus = 'success';
        state.devis = action.payload;
        
    })
    .addCase(fetchClientDevis.pending, (state, action) => {
        state.fetchDevisListStatus = 'loading';
    })
    .addCase(fetchClientDevis.rejected, (state, action) => {
        state.fetchDevisListStatus = 'error';
        
    })
    .addCase(fetchClientDevis.fulfilled, (state, action) => {
        state.fetchDevisListStatus = 'success';
        state.devisList = action.payload;
        
    })


    },


})


export default devisSlice;