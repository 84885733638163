
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FACTURE_DOSSIER_URL, GET_FACTURES_DOSSIER_URL, MAKE_FACTURE_URL } from "../../settings/APISetting";



export const fetchFacturesForDossier = createAsyncThunk('fetch-factures-dossier', async (dossierId) => {


    const response = await fetch(GET_FACTURES_DOSSIER_URL(dossierId), {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});


export const fetchFactureForDossier = createAsyncThunk('fetch-facture-dossier', async (fid) => {


    const response = await fetch(`${FACTURE_DOSSIER_URL}/${fid}` , {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });


    return response.json();
});



export const createFactureDossier = (facture, err, cb) => {
    fetch(`${MAKE_FACTURE_URL}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(facture)
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}


export const fetchFactureDossier = (id, err, cb) => {
    fetch(`${FACTURE_DOSSIER_URL}/${id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then((resp) => {
        if(resp.status > 204){
            throw resp;
        }
        return resp.json();

    })
        .then(async (json) => cb(json))
        .catch(err);
}




const facturesDossierSlice = createSlice({

    name: 'facturesDossier',
    initialState: {factures: [], factureFetchStatus: '' },

    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFacturesForDossier.fulfilled, (state, action) => {

            state.factures = action.payload;
            state.factureFetchStatus = 'success';
        }).addCase(fetchFacturesForDossier.pending, (state, action) => {
            state.factureFetchStatus = 'loading';

        }).addCase(fetchFacturesForDossier.rejected, (state, action) => {

            state.factureFetchStatus = 'error';

        });
    }


});
export default facturesDossierSlice;