import { useEffect, useState } from 'react';
import Transform from '../../components/TransForm/Transform';
import StyledTable from '../../components/table-component/StyledTable';
import { useDispatch, useSelector } from 'react-redux';
import BaseParameter from '../../data/schemas/baseParameter';
import { createProgram, deleteProgram, fetchProgrammes, updateProgram } from '../../data/slices/programmeSlice';
import { notify } from '../../settings/toastSetting';
import { Button, Dialog } from '@mui/material';
import { sortByProp } from '../../settings/themeSettings';




const ProgrammePage = (props) => {

    const { programmes } = useSelector((state) => state.programmes);
    const dispatch = useDispatch();

    const [program, setProgram] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const addProgramme = (data, cb) => {
        handleClose();
        console.log(data);


        if (data.id) {

            updateProgram(data, (err) => {
                console.log(err);
            }, (resp) => {
                cb();
                console.log(resp);
                notify("le programme a été modifié avec succés!");
                dispatch(fetchProgrammes());


            });



            return;
        }

        createProgram(data, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            cb();
            notify("Le programme a  été ajouté avec succés!");
            dispatch(fetchProgrammes());
        });
    }

    const onProgramSelected = (id) => {setProgram(programmes.find((p) => p.id === id));
        setOpen(true);
    }   

    const onDelete = (id) => {
        handleClose();

        deleteProgram(id, (err) => {
            console.log(err);
        }, (resp) => {
            console.log(resp);
            setProgram(null);

            notify("Le programme a bien été supprimé!");
            dispatch(fetchProgrammes());

        });
    }


    useEffect(() => {
        dispatch(fetchProgrammes());


    }, []);

    return <div className='param-container'>
        <div className="table-container">
            <div className="header">
                <div className="title">
                    Liste des programmes
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Ajouter
                </Button>
            </div>
            <StyledTable
                rows={[...programmes].sort((a,b) => sortByProp(a, b, 'libelle'))}
                head={Object.keys(new BaseParameter())}
                onOptionClicked={onProgramSelected}


            />

        </div>
        <Dialog onClose={(e) => handleClose(e)} open={open} >

            <Transform
                onSubmit={addProgramme}
                mandatories={["libelle"]}
                entity={new BaseParameter()}

                toUpdate={program}
                onDelete={onDelete}
                onCancel={() => handleClose()}


            />
        </Dialog>

    </div>

}


export default ProgrammePage;